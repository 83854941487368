<template>
  <div>
    <v-container class="payment-method-container">
      <div>
        <v-row class="d-flex justify-space-between">
          <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6" class="pt-0">
            <v-col class="pa-0 mt-4" v-show="achMethods?.length">
              <div class="card-methods-container">
                <div class="card-methods-content">
                  <v-radio-group dense v-model="method" :mandatory="false">
               <v-radio value="existing">
                <template v-slot:label>
                  <v-select
                    :disabled="method !== 'existing'"
                    hide-details
                    :value="payment.payment_method_id"
                    :items="achMethods"
                    item-value="id"
                    item-text="card_end"
                    :error-messages="errors.collect('existing_payment_method_id')"
                    placeholder="Account On File"
                    dense
                    @change="handleUpdate('payment','payment_method_id',$event)"
                    v-validate="method === 'existing'? 'required' : ''"
                    :class="{'custom-field-error' : errors.first('existing_payment_method_id')}"
                    name="existing_payment_method_id"
                    id="existing_payment_method_id"
                    data-vv-name="existing_payment_method_id"
                    data-vv-as="Account On File"
                  >
                    <template v-slot:selection="data" >
                      {{data.item.card_type | capitalize}}  ****{{data.item.card_end.slice(-4)}}
                    </template>

                    <template v-slot:item="data" >

                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>{{data.item.name_on_card}} </strong>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{data.item.card_type | capitalize}} ****{{data.item.card_end.slice(-4)}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </template>
              </v-radio>

              <v-radio value="new">
                <template v-slot:label>
                  <v-icon class="mr-3">mdi-bank</v-icon> New Bank Account
                </template>
              </v-radio>
                  </v-radio-group>
            </div>
            </div>
            </v-col>
            <v-col class="pa-0 " v-show="method === 'new'" >
              <v-row no-gutters class=" d-flex justify-space-between pa-0">
                <v-col  class="pa-0" :cols="$vuetify.breakpoint.xs ? 6 : 12">
                  <v-text-field
                    :class="{'custom-field-error' : errors.first('first')}"
                    hide-details
                    :value="payment_method.first"
                    v-validate="'required|max:45'"
                    data-vv-name="first"
                    data-vv-as="First Name"
                    :error-messages="errors.collect('first')"
                    label="First Name*"
                    id="account-first-name"
                    name="account-first-name"
                    autocomplete="cc-account-first-name"
                    @input="handleInputUpdate('payment_method','first',$event)"
                  >
            </v-text-field>
                </v-col>
                <v-col class="pa-0" :cols="$vuetify.breakpoint.xs ? 5 : 12">
                  <v-text-field
                    :class="{'custom-field-error' : errors.first('last')}"
                    hide-details
                    :value="payment_method.last"
                    v-validate="'required|max:45'"
                    data-vv-name="last"
                    data-vv-as="Last Name"
                    :error-messages="errors.collect('last')"
                    label="Last Name*"
                    id="account-last-name"
                    name="account-last-name"
                    autocomplete="cc-account-last-name"
                    @input="handleInputUpdate('payment_method','last',$event)"
                  >
            </v-text-field>
                </v-col>
              </v-row>

               <v-row class="pa-0">
                <v-col :cols="12" class="py-0">
                  <v-select
                  :class="{'custom-field-error' : errors.first('account_type')}"
                  hide-details
                  :value="payment_method.account_type"
                  :items="account_types"
                  v-validate="'required'"
                  data-vv-name="account_type"
                  data-vv-as="Account Type"
                  :error-messages="errors.collect('account_type')"
                  label="Account Type*"
                  @change="handleUpdate('payment_method','account_type',$event)"
                  >
            </v-select>
                </v-col>
               </v-row>
               <v-row class="mt-4">
                <v-col :cols="12">
                  <hb-text-field
                      box
                      :class="{'custom-field-error' : errors.first('routing_number')}"
                      tile
                      hide-details
                      :value="payment_method.routing_number"
                      data-vv-name="routing_number"
                      v-validate="'required|numeric|max:45'"
                      data-vv-as="Routing Number"
                      :error-messages="errors.collect('routing_number')"
                      label="Routing Number*"
                      id="account-routing-number"
                      name="account-routing-number"
                      autocomplete="cc-account-routing-number"
                      @input="handleInputUpdate('payment_method','routing_number',$event)"
                      @blur="$validator.validate('routing_number')"
              >
            </hb-text-field>
                </v-col>
               </v-row>

               <v-row>
                <v-col :cols="12" >
                  <hb-text-field
                    box
                    :class="{'custom-field-error' : errors.first('account_number')}"
                    tile
                    hide-details
                    :value="payment_method.account_number"
                    data-vv-name="account_number"
                    v-validate="'required|numeric|max:45'"
                    data-vv-as="Account Number"
                    :error-messages="errors.collect('account_number')"
                    label="Account Number*"
                    id="account-number"
                    name="account-number"
                    autocomplete="cc-account-number"
                    @input="handleInputUpdate('payment_method','account_number',$event)"
                    @blur="$validator.validate('account_number')"
                   >
                  </hb-text-field>
                </v-col>
               </v-row>
            </v-col>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6"  v-show="method === 'new'">
            <v-row v-if="!dense_header" dense >
          <v-col cols="5">
            <strong>Billing Address</strong>
          </v-col>
          <v-col cols="7">
            <v-checkbox label="Default Address" v-model="allowDefaultAddress" v-if="paymentConfig.SHOW_BILLING_ADDRESS && ten_address && ten_address.length > 0" hide-details class="float-right mt-0 pt-0"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-else dense class="mt-2">
          <v-col class="py-0 mt-1" cols="6">
            <strong class="label-heading-1">Billing Address</strong>
          </v-col cols=6>
          <v-col>
            <v-checkbox label="Default Address" v-model="allowDefaultAddress" v-if="ten_address && ten_address.length > 0" hide-details class="mt-0 float-right pt-0"></v-checkbox>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col class="pb-0">
            <address-autofill
              class="mt-4"
              v-model="autofillData"
              @input="addressDataSet($event)"
            >
            </address-autofill>
          </v-col>
         </v-row>

        <v-row dense>
          <v-col cols="12" class="py-0 my-0">
            <v-text-field
              :class="{'custom-field-error' : errors.first('address')}"
              ref="address"
              hide-details
              :value="payment_method.address"
              v-validate="'required|max:100'"
              data-vv-name="address"
              data-vv-as="Billing Address"
              :error-messages="errors.collect('address')"
              label="Street*"
              id="ach-billing-address"
              name="ach-billing-address"
              autocomplete="pm-ach-billing-address"
              @input="handleInputUpdate('payment_method', 'address', $event)"
              >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="py-0 ma-0">
            <v-text-field
              :class="{'custom-field-error' : errors.first('address2')}"
              hide-details
              :value="payment_method.address2"
              v-validate="'max:45'"
              data-vv-name="address2"
              data-vv-as="Billing Address"
              :error-messages="errors.collect('address2')"
              label="Apt, Suite, Etc."
              id="ach-billing-address2"
              name="ach-billing-address2"
              autocomplete="pm-ach-billing-address2"
              @input="handleInputUpdate('payment_method', 'address2', $event)"
              >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" class="mt-3">
            <HbAutocomplete
                :class="{'custom-field-error' : errors.first('country')}"
                :items="countriesListMixin"
                hide-details
                v-validate="'required'"
                :value="payment_method.country"
                label="Country*"
                single-line
                id="country"
                data-vv-as="Country"
                name="country"
                :error-messages="errors.collect('country')"
                @change="handleUpdate('payment_method', 'country', $event)"
                backgroundColor=""
            >
            </HbAutocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="mt-2">
            <HbAutocomplete
              :class="{'custom-field-error' : errors.first('city')}"
              hide-details
              :items="paymentCityListMixin"
              :value="payment_method.city"
              v-validate="paymentCityListMixin.length ? 'required' : ''"
              label="City*"
              single-line
              data-vv-as="City"
              id="ach-city"
              name="ach-city"
              :error-messages="errors.collect('city')"
              @change="handleUpdate('payment_method', 'city', $event)"
              @keydown.enter="setCityList($event.target.value, true)"
              backgroundColor=""
              append-icon=""
            >
            </HbAutocomplete>
          </v-col> 
        </v-row>

        <v-row>
          
          <v-col cols="6" class="mt-2">
            <HbAutocomplete
               :class="{'custom-field-error' : errors.first('state')}"
               :items="paymentStateListMixin"
               hide-details
               item-text='name'
               item-value='value'
               :value="payment_method.state"
               label="State"
               single-line
               data-vv-as="State"
               name="state"
               id="ach_state"
               :error-messages="errors.collect('state')"
               @change="handleUpdate('payment_method', 'state', $event)"
               backgroundColor=""
              />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
              :class="{'custom-field-error' : errors.first('zip')}"
              hide-details
              :value="payment_method.zip"
              v-validate="'required|max:10'"
              data-vv-name="zip"
              data-vv-as="Zip"
              :error-messages="errors.collect('zip')"
              label="Zip*"
              id="ach-zip"
              name="ach-zip"
              autocomplete="pm-ach-zip"
              @input="handleInputUpdate('payment_method', 'zip', $event)"
              >
            </v-text-field>
          </v-col>
        </v-row>
       
        <v-row v-if="!dense_header">
            <v-col>
              <v-checkbox class="mt-0 pt-0" :disabled="auto_charge" hide-details :input-value="payment_method.save_to_account" @change="updateSaveToAccount($event)" label="Save Payment Method"></v-checkbox>
            </v-col>
          </v-row>

        <v-row dense v-if="!dense_header">
          <v-col class="mt-4 mb-2">
            <hb-textarea
              :class="{'custom-field-error' : errors.first('notes')}"
              hide-details
              :value="payment.notes"
              data-vv-name="notes"
              data-vv-as="Notes"
              :error="errors.has('notes')"
              label="Notes"
              id="account-notes"
              name="account-notes"
              autocomplete="cc-account-notes"
              @input="handleInputUpdate('payment','notes',$event)"
              backgroundColor=""
              >
            </hb-textarea>
          </v-col>
        </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>


  </div>
</template>

<script type="text/babel">
  import HbDatePicker from '../../assets/HummingbirdDatepicker';
  import VuetifyStatepicker from '../../includes/VuetifyStatepicker.vue';
  import { mapGetters, mapMutations, mapActions } from "vuex";
  import { EventBus } from '../../../EventBus';
  import { countryStateCityMixin } from '../../../mixins/countryStateCityMixin';
  import AddressAutofill from '../../autofill/AddressAutofill.vue';

  export default {
    mixins: [ countryStateCityMixin ],
    data() {
      return {
        method: null,
        account_types:['Checking', 'Savings'],
        allowDefaultAddress: false
      }
    },
    components:{
      HbDatePicker,
      VuetifyStatepicker,
      AddressAutofill
    },
    computed:{
      ...mapGetters({
          paymentConfig: 'paymentsStore/getPaymentSourceConfig',
          getErrors: 'paymentsStore/getErrors',
          payment_methods: 'paymentsStore/getContactPaymentMethods',
          payment_method: 'paymentsStore/getPaymentMethod',
          payment: 'paymentsStore/getPayment',
        }),
        achMethods(){
            return this.payment_methods.filter(pm => pm.type === 'ach');
        }
    },
    created(){
          this.setCountriesListEvent()
          if(this.payment_method.country){
            this.setPaymentStatesListEvent(this.payment_method.country)
            if(!this.payment_method.state){
              this.setPaymentCountryCitiesListEvent(this.payment_method.country)
            }else{
              this.setPaymentCitiesListEvent(this.payment_method.country, this.payment_method.state)
            }
            this.setCityList(this.payment_method.city)
          }
    },
    mounted() {
      if(this.achMethods.length){
          this.method = 'existing';
      } else {
          this.method = 'new';
      }
    },
    destroyed() {
    },
    props: ['auto_charge', 'dense_header','ten_address'],
    methods:{
      ...mapMutations({
        setCheckErrors: 'paymentsStore/setCheckErrors',
        setPayment: 'paymentsStore/setPayment',
        setPaymentMethod: 'paymentsStore/setPaymentMethod',
        setPaymentObject: 'paymentsStore/setPaymentObject',
        setPaymentMethodObject: 'paymentsStore/setPaymentMethodObject',
      }),
      setCityList(value, toValidate = false){
            if(!value) return
            const isSpecialCharacter = this.validateCityName(value)
            const cityValidation = this.errors.items.indexOf('city')
            if(toValidate){
                this.errors.items.splice(cityValidation);
                if(isSpecialCharacter){
                    return this.errors.add({
                                field: 'city',
                                msg: 'Please add a valid city name.'
                            }) 
              }
            }
        const isCityExists = this.paymentCityListMixin.some((city) => city.toLowerCase() === value.toLowerCase())
        if(isCityExists) return

        this.paymentCityListMixin.push(value)
        const country = this.countriesListMixinRaw.find(item => item.name === this.payment_method.country || this.ten_address[0].Address.country);
        this.addMissingCity({
            country_code: country.isoCode,
            state_code: this.payment_method.state || this.ten_address[0].Address.state,
            city: value
        })
      },
      setAddress(){
        const countryValue = this.setCountryNameEvent(this.ten_address[0].Address.country)
        this.setPaymentStatesListEvent(countryValue)
        this.setPaymentCitiesListEvent(countryValue, this.ten_address[0].Address.state)
        this.setCityList(this.ten_address[0].Address.city)
        let updatedPM = {
          address: this.ten_address[0].Address.address,
          address2: this.ten_address[0].Address.address2,
          zip: this.ten_address[0].Address.zip,
          city: this.ten_address[0].Address.city,
          state: this.ten_address[0].Address.state,
          country: countryValue

        }
        this.setPaymentMethodObject({paymentMethod: {...this.payment_method, ...updatedPM}});
      },
      resetAddress(){
        let updatedPM = {
          address: '',
          address2: '',
          zip: '',
          city: '',
          state: '',
          country: ''

        }
        this.setPaymentMethodObject({paymentMethod: {...this.payment_method, ...updatedPM}});
      },
      handleUpdate(obj, property_name, property_value){
        if(obj === 'payment'){
          this.setPayment({property: property_name, propertyValue: property_value});
        }
        if(obj === 'payment_method'){
          this.setPaymentMethod({property: property_name, propertyValue: property_value});
          switch(true){
              case property_name === 'country':
                this.setPaymentMethod({property: 'state', propertyValue: ''});
                this.setPaymentMethod({property: 'city', propertyValue: ''});
                this.setPaymentStatesListEvent(this.payment_method.country)
                this.setPaymentCountryCitiesListEvent(this.payment_method.country)
                break;
              case property_name === 'state':
                this.setPaymentMethod({property: 'city', propertyValue: ''});
                this.setPaymentCitiesListEvent(this.payment_method.country, this.payment_method.state)
                break;
              case property_name === 'city':
                this.setPaymentMethod({property: property_name, propertyValue: property_value});
                break;
          }
        }
      },
      handleInputUpdate : _.debounce(async function(obj, property_name, property_value) {
        this.handleUpdate(obj, property_name, property_value);
      }, 200),

      updateSaveToAccount(val){
        this.setPaymentMethod({property: 'save_to_account', propertyValue: !!val}); 
      },

      validateFields(){
        if(this.method === 'existing'){
          return this.$validator.validate('existing_payment_method_id')
        }
        if(this.method === 'new'){
          return this.$validator.validateAll();
        }
      },
      addressDataSet(autofillData){
        if(this.payment_method){
                if(autofillData?.country){
                  this.payment_method.country = autofillData.country
                  this.setPaymentStatesListEvent(this.payment_method.country)
                  this.setPaymentCountryCitiesListEvent(this.payment_method.country)
                }
                if(autofillData?.state  && this.paymentStateListMixin){
                     for(let state of this.paymentStateListMixin){
                      if(state.name  === autofillData.state){
                        this.payment_method.state = state.value
                      }
                     }
                    this.setPaymentMethod({property: 'city', propertyValue: ''});
                    this.setPaymentCitiesListEvent(this.payment_method.country, this.payment_method.state)
                }
                if(autofillData?.city){
                  this.payment_method.city = autofillData?.city
                }
                if(autofillData?.postalCode){
                  this.payment_method.zip = autofillData?.postalCode
                }
                if(autofillData?.address1){
                  this.payment_method.address = autofillData?.address1
                }
                if(autofillData?.apt){
                  this.payment_method.address2 = autofillData?.apt
                }
               
            }

      }
    },
    watch:{
      auto_charge(){
        this.setPaymentMethod({property: 'save_to_account', propertyValue: this.auto_charge});
      },
      allowDefaultAddress(val){
        if (val) {
          this.setAddress();
        } else {
          this.resetAddress();
        }
      }
    }
  }
</script>
<style scoped>
  .payment-method-container{
    color: #101318;
  }
  .label-heading-1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101318;
  }
  .card-methods-container{
    width:100%;
    background-color: white;
  }
  .card-methods-content{
    padding: 12px;
    border: 1px solid #C4CDD5;
    border-radius: 6px;
    margin-top: 8px;
    margin-bottom: 4px;
  }
  .hb-autocomplete-width-370{
  max-width: 100% !important;
}
  .hb-text-field-width-370{
    max-width: 100% !important;
  }
</style>