<!-- BCT: Component separated from OverviewLeaseWidget -->
<template>
  <div class="lease-card-details-wrapper">
    <v-row v-if="unit.gate_status != 'Unlocked'" class="pa-0 mt-3 mx-4 d-flex justify-center align-center">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
        Door Status
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
        <hb-status-general :status="unit.gate_status"></hb-status-general>
      </v-col>
      <v-col cols="2" class="pa-0 ma-0">

      </v-col>
    </v-row>
    <v-row class="pa-0 mt-3 mx-4 d-flex justify-center align-center">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
        Move-In Date
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
        {{leaseWithTenants.start_date | formatDateServices}}
      </v-col>
      <v-col cols="2" class="pa-0 ma-0">

      </v-col>
    </v-row>
    <v-row class="pa-0 mt-3 mx-4 d-flex justify-center align-center">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
        Paid Through
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
        <span v-if="leaseWithTenants.Metrics && leaseWithTenants.Metrics.paid_through_date">{{leaseWithTenants.Metrics.paid_through_date| formatDateServices}}</span>
        <span v-else>--</span>
      </v-col>
      <v-col cols="2" class="pa-0 ma-0">

      </v-col>
    </v-row>
    <v-row v-if="isPtodEnabled" class="pa-0 mt-3 mx-4 d-flex justify-center align-center">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
        Paid To Date
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
        <span v-if="leaseWithTenants.Metrics && leaseWithTenants.Metrics.paid_to_date">{{leaseWithTenants.Metrics.paid_to_date| formatDateServices}}</span>
        <span v-else>--</span>
      </v-col>
      <v-col cols="2" class="pa-0 ma-0">

      </v-col>
    </v-row>

    <v-row class="pa-0 mt-3 mx-4 d-flex justify-center align-center" :class="{'hb-text-destroy-status' : leaseWithTenants.balance > 0}">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
        Total Balance Due
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" :class="{'font-weight-medium' : leaseWithTenants.balance > 0}">
        {{leaseWithTenants.open_balance | formatMoney}}
      </v-col>
      <v-col cols="2" class="pa-0 ma-0">

      </v-col>
    </v-row>
    <v-row v-if="!is_closed" class="pa-0 mt-3 mx-4 d-flex justify-center align-center">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
        Upcoming Charges
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
        <span v-if="!showCharges">{{invoice.balance | formatMoney }}</span>
      </v-col>
      <v-col cols="2" class="pa-0 ma-0 d-flex justify-end">
        <hb-link @click="showCharges = !showCharges" class="float-right">{{showCharges ? 'Cancel' : 'View'}}</hb-link>
      </v-col>
    </v-row>
    <span v-if="showCharges" class="text-body-2">
        <v-row class="pa-0 mt-1 mx-4 d-flex justify-center align-center">
            <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
                Bill Date
            </v-col>
            <v-col cols="4" class="pa-0 ma-0 d-flex align-center justify-end">
                <v-icon size="20" :disabled="offset <= 0" color="#637381" @click="prevPeriod" style="padding-top:2px;">
                    mdi-menu-left
                </v-icon>
                {{invoice.due | formatDate}}
            </v-col>
            <v-col cols="2" class="pa-0 ma-0 d-flex align-center">
                <v-icon size="20" color="#637381" @click="nextPeriod" style="padding-top:2px;">
                    mdi-menu-right
                </v-icon>
            </v-col>
        </v-row>

        <span v-if="invoice && invoice.InvoiceLines && invoice.InvoiceLines.length !== 0">
            <v-row v-for="(lines, i) in invoice.InvoiceLines" :key="'invoiceline' + i" class="pa-0 mt-1 mx-4 d-flex justify-center align-center">
                <v-col cols="6" class="pa-0 ma-0">
                    {{lines.Service.name}} <span v-if="lines.Product.default_type === 'rent'">({{lines.start_date | formatDate}} - {{lines.end_date | formatDate}})</span> <span v-if="lines.qty > 1"> x {{lines.qty}}</span>
                </v-col>
                <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
                    {{lines.cost * lines.qty | formatMoney}}
                </v-col>
                <v-col cols="2" class="pa-0 ma-0 pl-2">
                    <v-icon size="16" color="#637381" v-if="lines.Product.default_type !== 'rent'" @click="lines.Product.default_type === 'insurance' ? insuranceDialog = true : editService(lines.Service, 'filter')">
                        mdi-pencil
                    </v-icon>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-1 mx-4 d-flex justify-center align-center">
                <v-col cols="6" class="pa-0 ma-0">
                    Taxes<!-- ({{taxRate}}%) -->
                </v-col>
                <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
                    {{invoice.total_tax | formatMoney}}
                </v-col>
                <v-col cols="2" class="pa-0 ma-0 pl-2">

                </v-col>
            </v-row>
            <v-row class="pa-0 mt-1 mx-4 d-flex justify-center align-center">
                <v-col cols="6" class="pa-0 ma-0">
                    Discounts
                </v-col>
                <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
                    ({{invoice.discounts | formatMoney}})
                </v-col>
                <v-col cols="2" class="pa-0 ma-0 pl-2">

                </v-col>
            </v-row>
            <v-row class="font-weight-medium hb-default-font-size pa-0 mt-1 mx-4 d-flex justify-center align-center">
                <v-col cols="6" class="pa-0 ma-0">
                    Total Charges
                </v-col>
                <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
                    {{invoice.balance | formatMoney}}
                </v-col>
                <v-col cols="2" class="pa-0 ma-0 pl-2">

                </v-col>
            </v-row>
        </span>
        <span v-show="!invoice.InvoiceLines || !invoice.InvoiceLines.length" class="px-6 py-6">
            <em>No charges found for this period.</em>
        </span>
    </span>

    <v-row v-if="isAuctionPaid" class="pa-0 mt-2 mb-1 mx-4 d-flex justify-center align-center">
      <v-col cols="6" class="pa-0 ma-0 font-weight-regular hb-link" style="cursor: pointer;" @click="auctionSale">
        Auction Sale
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
        {{ auctionAmount | formatMoney }}
      </v-col>
      <v-col cols="2" class="pa-0 ma-0 pl-2">
        <hb-btn x-small color="secondary" @click="$emit('processPayment', leaseWithTenants.LeaseAuction.payment_id)" :disabled="auctionAmount <= 0 ? true : false">View</hb-btn>
      </v-col>
    </v-row>

    <v-row v-if="isAuctionPaid" class="pa-0 mt-2 mb-1 mx-4 d-flex justify-center align-center" :class="is_closed ? 'pb-2' : ''">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
        Cleaning Deposit
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
        {{ auctionCleaningDeposit | formatMoney }}
      </v-col>
      <v-col cols="2" class="pa-0 ma-0 pl-2">
        <hb-btn x-small color="secondary" @click="$emit('refundAuction', leaseWithTenants.LeaseAuction)" :disabled="disableRefundButton">Refund</hb-btn>
      </v-col>
    </v-row>

    <v-row v-if="!is_closed" class="pa-0 mt-3 mx-4 d-flex justify-center align-center">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
        Prepaid Balance
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end">
        <span v-if="leaseWithTenants.Metrics && leaseWithTenants.Metrics.prepaid_balance">{{leaseWithTenants.Metrics.prepaid_balance| formatMoney}}</span>
        <span v-else>$0.00</span>
      </v-col>
      <v-col cols="2" class="pa-0 ma-0 pl-2">
        <!-- turn back on later after refund logic has been addressed -->
        <!--
        <hb-btn small color="secondary" @click="goRefund()" :disabled="prepayBalance <= 0 ? true : false">Refund</hb-btn>
        -->
      </v-col>
    </v-row>
    
    <v-row v-if="!nationalAccount" class="pa-0 mt-2 mb-3 mx-4 d-flex justify-center align-center">
      <v-col cols="6" class="pa-0 ma-0 font-weight-medium">
          Credit Balance
      </v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-if="totalCredits">
          {{totalCredits | formatMoney}}
      </v-col>  
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-else>
          $0.00
      </v-col>
        <v-col cols="2" class="pa-0 ma-0 pl-2">
            <!--
          <hb-btn small color="secondary" @click="goCredit()" v-if="!credits || totalCredits <= 0">Apply</hb-btn>
          <hb-btn small color="secondary" @click="goCredit()" v-else>Adjust</hb-btn>
          -->
          <hb-btn :x-small="isTenantProfileRoute && ($vuetify.breakpoint.xs || ($vuetify.breakpoint.width >1259 && $vuetify.breakpoint.width < 1320))" :small="isTenantProfileRoute && $vuetify.breakpoint.width < 1550" v-if="!is_closed && !isAuctionPaymentOrMovedOut && !nationalAccount" color="secondary" @click="goCredit()" class="float-right">Apply</hb-btn>
      </v-col>
    </v-row>
    <!-- If it is National Account then don't show Credit Balance -->
    <v-row v-else class="pa-0 mt-2 mb-3 mx-4 d-flex justify-center align-center">
    </v-row>

    <v-row v-if="showRPI"  class="pa-4 pt-3 pb-3 ma-0 d-flex justify-center align-center hb-caution-shade">
      <v-divider class="pa-0 ma-0"></v-divider>
      <v-col cols="8" class="pa-0 ma-0 font-weight-medium d-flex align-center">
          Pending Refund(s)
          <hb-tooltip v-if="disableRefund">
            <template v-slot:body>All refunds are currently in progress.</template>
          </hb-tooltip>
          <hb-tooltip v-else>
            <template v-slot:body>Some refunds may need to be re-initiated.</template>
          </hb-tooltip>
      </v-col>
      <v-col cols="2" class="pa-0 ma-0 d-flex justify-end hb-text-error align-center" v-if="totalRefunds">
          {{totalRefunds | formatMoney}}
      </v-col>  
        <v-col cols="2" class="pa-0 ma-0 pl-2">
          <hb-btn small color="secondary" @click="goRefund()" class="float-right" :disabled="disableRefund">Refund</hb-btn>
      </v-col>
    </v-row>

    <insurance-dialog
      v-model="insuranceDialog"
      v-if="insuranceDialog"
      :lease_id="leaseWithTenants.id"
      :lease="leaseWithTenants"
      :contact_id="contact.id"
      :property_id="leaseWithTenants.Unit.property_id"
      @close="insuranceDialogClose"
      :decline_insurance="decline_insurance"
    >
    </insurance-dialog>

    <edit-service-dialog
      v-model="editServiceDialog"
      v-if="editServiceDialog"
      :selected="selectedService"
      :property_id="leaseWithTenants.Unit.property_id"
      :lease_id="leaseWithTenants.id"
      @close="editServiceDialog = false"
    >
    </edit-service-dialog>

    <credit
      v-if="show_credit_model"
      v-model="show_credit_model"
      :lease_id="leaseWithTenants.id"
      :contact_id="contact.id"
      :credits="credits"
      @close="show_credit_model = false;"
    >
    </credit>
    <refund
      v-if="show_refund_model"
      v-model="show_refund_model"
      :lease_id="leaseWithTenants.id"
      :property_id="leaseWithTenants.Unit.property_id"
      :contact_id="contact.id"
      :refundPendingInvoices="this.refundPendingInvoices"
      @close="show_refund_model = false;"
      @refund-initiated="fetchRefundInvoicesDetails"
    >
  </refund>

  </div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import moment from 'moment';

    import EditServiceDialog from './EditServiceDialog.vue';
    import InsuranceDialog from './InsuranceDialog.vue';
    import Credit from '../leases/Credit.vue';
    import Refund from '../leases/Refund.vue';
    import SETTINGS from '@/constants/setting.js';
    import api from '../../assets/api';

export default {
  name: 'OverviewLeaseDetails',
  components: {
    InsuranceDialog,
    EditServiceDialog,
    Credit,
    Refund
  },

  props: ['unit', 'leaseWithTenants', 'lease', 'invoice', 'offset', 'services', 'credits', 'contact', 'nationalAccount'],
  data() {
    return {
      showCharges: false,
      insuranceDialog: false,
      decline_insurance: false,
      editServiceDialog: false,
      selectedService: {},
      show_credit_model: false,
      show_refund_model: false,
      isPtodEnabled: false,
      refundPendingInvoices:[]
    }
  },

  async created() {
    this.SETTINGS = SETTINGS;
    await this.$store.dispatch('settingsStore/fetchPaidToDateSettings');
    if(this.leaseWithTenants && this.leaseWithTenants.decline_insurance == 1){
      this.decline_insurance = true;
    }
    this.isPtodSettingEnabled();
    await this.fetchRefundInvoicesDetails();
  },
  computed: {
    ...mapGetters({
        isAdmin: 'authenticationStore/isAdmin',
        isSuperAdmin: 'authenticationStore/isSuperAdmin',
        hasPermission: "authenticationStore/rolePermission",
        ptodSettings: 'settingsStore/ptod'
    }),
    isTenantProfileRoute(){
      return this.$route.name === 'ContactOverview'
    },
    is_closed(){
        return moment(this.leaseWithTenants.end_date).startOf('day') <= moment().startOf('day');
    },

    isAuctionPaymentOrMovedOut() {
      return this.leaseWithTenants && this.leaseWithTenants.auction_status === 'move_out';
    },

    isAuctionPaid() {
        return (this.leaseWithTenants && (this.leaseWithTenants.auction_status == 'move_out' || this.leaseWithTenants.auction_status == 'complete')) ? true : false;
    },
    
    auctionAmount() {
        return (this.leaseWithTenants.LeaseAuction && this.leaseWithTenants.LeaseAuction.amount) ? this.leaseWithTenants.LeaseAuction.amount : 0;
    },

    auctionCleaningDeposit() {
        return (this.leaseWithTenants.LeaseAuction && this.leaseWithTenants.LeaseAuction.cleaning_deposit && !this.leaseWithTenants.LeaseAuction.refund_id) ? this.leaseWithTenants.LeaseAuction.cleaning_deposit : 0;
    },

    totalCredits(){
        return this.credits.reduce((a, p) => a + Math.round(p.amt_remaining*1e2)/1e2, 0);
    },
    disableRefundButton(){
      if (this.leaseWithTenants.LeaseAuction.refund_id || this.auctionCleaningDeposit <= 0){
        return true;
      }
      if (this.leaseWithTenants.LeaseAuction.has_clean_out_period_passed){
        return !this.hasPermission('refund_after_clean_out_period');
      }
      return false;
    },
    showRPI(){
      return this.refundPendingInvoices.length > 0;
    },

    totalRefunds(){
      return this.refundPendingInvoices.reduce((acc,curr)=> acc += curr.subtotal, 0);
    },

    disableRefund(){
      return !this.refundPendingInvoices.some(invoice => invoice.reinitiate_refund === true);
    }
  },

  methods: {
    nextPeriod() {
      this.$emit('nextPeriod');
    },

    prevPeriod() {
      this.$emit('prevPeriod');
    },

    insuranceDialogClose(){
        this.insuranceDialog = false;
        this.$emit('insuranceDialogClose');
    },

    editService(s, t){
      if(!this.isAdmin) return;

      if(t === 'filter'){
          var getSelected = this.services.filter(service => service.id === s.id);
          this.selectedService = getSelected[0];
      } else {
          this.selectedService = s;
      }

      //Checks whether user can edit service
      this.editServiceDialog = this.checkIfServiceEditable(s);
      },

      checkIfServiceEditable(s){
          if (s.last_billed && !s.recurring) return false
          return true
      },

      auctionSale() {
        this.$emit('auctionSale', this.leaseWithTenants);
      },
      
      goCredit(){
        this.show_credit_model = true;
      },
      goRefund(){
        this.show_refund_model = true;
      },
      isPtodSettingEnabled(){
        const ptodSetting = this.ptodSettings.find(setting => setting.name === SETTINGS.ALLOW_PTOD);
        if (ptodSetting?.value) {
          this.isPtodEnabled = parseInt(ptodSetting.value) === 1;
        }
      },

      async fetchRefundInvoicesDetails(){
        let r = await api.get(
          this,
          api.LEASES + this.leaseWithTenants.id + "/refund-invoices"
        );
        this.refundPendingInvoices = r.refund_invoices;
      }

  },
  watch: {
    ptodSettings() { 
       this.isPtodSettingEnabled();
    }
  }
}
</script>
<style lang="scss" scoped>
  .lease-card-details-wrapper {
    ::v-deep {
      .v-btn:not(.v-btn--round).v-size--x-small {
        padding: 0px 4px;
      }
    }
  }

  @media (min-width: 600px) and (max-width:837px) {
    .col-6{
      flex: 60%;
      max-width: 60%;
    }
    .col-4 {
      flex: 0 0 30%;
      max-width: 30%;
    }.col-2 {
      flex: 0 0 10%;
      max-width: 10%;
    }
  }
</style>