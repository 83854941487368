<template>
    <hb-modal
      size="large"
      title="Pending Refund"
      v-model="dialog"
      @close="close"
      show-help-link
      >
        <template v-slot:content>
        <v-card-text class="pa-0 ma-0">
          <v-container class="pa-0 ma-0">

            <hb-form  label="Select Pending Refund*" full>
              <HbSelect
               :items="getRefundOptions"
                v-model="reversal.refund"
                item-text="text"
                item-value="value"
                placeholder="Select Refund*"
                v-validate.disable="'required|max:999'"
                data-vv-name="refund"
                data-vv-as="Refund"
                name="refund"
                id="refund"
                :rows="3"
                :error="errors.has('refund')"
              />
            </hb-form>
  
            <hb-form label="Refund Payment Type*" >
                <HbSelect
                :items="['Direct Refund']"
                v-model="reversal.refund_type"
                placeholder="Select Refund Type*"
                v-validate.disable="'required|max:999'"
                data-vv-name="Refund Type*"
                data-vv-as="Refund Type*"
                name="Refund Type*"
                id="Refund Type"
                :rows="3"
                :error="errors.has('Refund Type')"
                :disabled="disable_type_selection"
              />

              <v-col cols="6"  class="ma-0 pa-0 py-2">
              <v-text-field
              v-if="reversal.refund && reversal.refund_type"
                cols="6" 
                class="my-n1 pr-2"
                v-model="reversal.account_name"
                v-validate.disable="'required|max:32'"
                data-vv-as="Account Name"
                data-vv-scope="reversal"
                :error="errors.has('reversal.account_name')"
                label="Account Name*"
                id="account_name"
                name="account_name"
                >
              </v-text-field>

              <v-text-field
                cols="6" 
                v-if=" isNZ !== null && !isNZ && reversal.refund && reversal.refund_type"
                class="my-n1 pr-2"
                v-model="reversal.bsb_number"
                v-validate.disable="'required|numeric|length:6'"
                maxlength="6"
                data-vv-as="BSB Number"
                data-vv-scope="reversal"
                :error="errors.has('reversal.bsb_number')"
                label="BSB Number*"
                id="bsb_number"
                name="bsb_number"
                >
              </v-text-field>
           
              <v-text-field
                cols="6" 
                v-if="reversal.refund && reversal.refund_type"
                class="my-n1 pr-2"
                 v-model="reversal.account_number"
                v-validate.disable="{ required: true, numeric: true, customAccountNumberValidation: true }"
                data-vv-as="Account Number"
                data-vv-scope="reversal"
                :error="errors.has('reversal.account_number')"
                label="Account Number*"
                id="account_number"
                name="account_number"
              >
              </v-text-field>
            </v-col>
            </hb-form>
          </v-container>
        </v-card-text>
           
            
        </template>
        <template v-slot:actions>
          <hb-btn
            color="primary"
            @click="confirm()"
            :loading="isLoading($options.name)"
            :disabled="!reversal.refund ?? !disable_type_selection"
          >
          Initiate Refund
          </hb-btn>
        </template>
    </hb-modal>
  
  </template>
  <script type="text/babel">
      import Status from '../includes/Messages.vue';
      import { EventBus } from '../../EventBus.js';
      import api from '../../assets/api.js';
      import Loader from '../assets/CircleSpinner.vue';
      import moment from 'moment';
      import { notificationMixin } from  '../../mixins/notificationMixin.js';
import reversal from '../../constants/reversal.js';
      export default {
          name: "Credit",
          mixins: [notificationMixin],
          data() {
              return {
                  reversal: {
                      refund: '',
                      refund_type: '',
                      account_number: '',
                      bsb_number: '',
                      account_name: '',
                      reason:''
                  },
                  disable_type_selection: true,
                  is_initiated_refund: false,
                  loading: false,
                  selected_invoice: {},
                  isAUS: false,
                  isNZ: false
              }
          },
          components:{
              Status,
              Loader
          },
          computed: {
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
            getRefundOptions() {
                return this.refundPendingInvoices.filter((invoice)=>{
                  return invoice.reinitiate_refund;
                }).map(invoice => ({
                    text: `Refund Pending ${invoice.effective_date} $${invoice.subtotal}`, // Display text for the dropdown
                    value: invoice.id                 // Value associated with the option
                }));


            }
          },
          async created(){
      
            await this.isAUSOrNZ(); 
          
          },
          mounted() {
              this.$validator.extend('customAccountNumberValidation', {
              validate: value => {
                if (this.isAUS) {
                const regex = /^[0-9]{4,9}$/;
                return regex.test(value);
              } else if (this.isNZ) {
                const regex = /^[0-9]{14,16}$/;
                return regex.test(value);
              }
              return false;
            },
              getMessage: field => {
                if (this.isAUS) {
                  return `The ${field} must be a valid Australian account number (4-9 digits).`;
                } else if (this.isNZ) {
                  return `The ${field} must be a valid New Zealand account number (14-16 digits).`;
                }
                return `Please contact the onboarding team to get the address updated.`
              }
            })
          },
          props: ['lease_id', 'contact_id', 'value', 'property_id', 'refundPendingInvoices'],
          methods:{
            async isAUSOrNZ() {
              let r = await api.get(this, api.PROPERTIES + this.property_id);
              this.properties = r.property;
              if(this.properties.Address.country  && /^(AUS|AU|Australia)$/i.test(this.properties.Address.country)) {
                this.isAUS = true;
                this.isNZ = false;
              } else if(this.properties.Address.country && /^(NZ|NZL|New Zealand)$/i.test(this.properties.Address.country)) {
                this.isNZ = true;
                this.isAUS = false;
              } else {
                this.isAUS = false;
                this.isNZ = false;
              }
              console.log('is AUS or NZ ?', this.isAUS, this.isNZ);  
            },
            
            async confirm(){
              let valid =true;
              if(!this.is_initiated_refund){
                valid = await this.$validator.validateAll('reversal');
              }
              
              if (!valid) {
                  return;
                }
                
                  try{
                  if(!this.is_initiated_refund){
                    await api.post(this, api.DIRECT_REFUNDS+ 'pending-refund/' + this.reversal.refund ,  this.reversal );
                  }else{
                    let data ={
                      refund_status :"approval_pending", 
                      id: this.selected_invoice.direct_refund[0].id,
                      payment_id : this.selected_invoice.direct_refund[0].payment_id
                    }
                    await api.post(this, api.DIRECT_REFUNDS+ 'update_status', {...data})
                  }

                  this.showMessageNotification({type: 'success', description: "Refund is successfully initiated"});
                  this.$emit("refund-initiated");
                  this.close()
                  }
                  catch(err){
                    this.showMessageNotification({type: 'error', description: err});
                  
                  }
             



            },
            close(){
              this.$emit('close');
            }
          },

          watch:{
            "reversal.refund"() {
              this.reversal.refund_type = '',
              this.reversal.account_number = '',
              this.reversal.bsb_number = '',
              this.reversal.account_name = '',
              this.reversal.reason =''
              let refundInvoice = this.refundPendingInvoices.find((inv)=>{
                return inv.id == this.reversal.refund;
              });
              if(refundInvoice){
                this.selected_invoice = refundInvoice;
                if(refundInvoice.direct_refund.length == 0){
                  this.disable_type_selection = false;
                  this.is_initiated_refund = false;
                }else{
                  this.is_initiated_refund = true;
                  this.disable_type_selection = true;
                }
              }
            } 
          }
      }
  
  </script>
  