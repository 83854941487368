<template>
    <div>
        <hb-modal v-model="dialog" :size="'medium'" style="overflow: hidden;" force-scroll-to-top>
            <template v-slot:title>
                <div class="ml-1" :class="{ 'display-invoice-mobile-title': $vuetify.breakpoint.xs }">
                    <hb-icon>mdi-cash-plus</hb-icon>
                    <span> Credit Invoice Line</span>
                </div>
            </template>
            <template v-slot:content>
                <v-card class="hb-border-default pa-3 ma-3" elevation="0">
                    <div>
                        <div><span class="hb-font-caption">Line to credit:</span></div>
                        <div><span class="hb-font-body-medium">{{ line.Product.name + " - " +
                            invoice.Lease.Unit.number }}</span> <span class="hb-font-body">
                                ({{ line.start_date | formatDateServices }} -
                                {{ line.end_date | formatDateServices }})</span></div>
                    </div>
                </v-card>
                <div class="pa-1">
                    <v-row no-gutters class="mt-1 mb-2">
                        <v-col cols="5" class="d-flex align-center justify-start">
                            <span class="ml-3 hb-font-caption-medium"> </span>
                        </v-col>
                        <v-col cols="2" class="d-flex align-center justify-start px-0">
                            <span class="hb-font-caption-medium">Charge</span>
                        </v-col>
                        <v-col cols="2" class="d-flex align-center justify-start px-0">
                            <span class="hb-font-caption-medium">Paid Amount</span>
                        </v-col>
                        <v-col cols="3" class="d-flex align-center justify-start pl-0">
                            <span class="hb-font-caption-medium">Unpaid Amount</span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-1" v-for="(item, index) in lineItems" :key="index">
                        <v-col cols="5" class="d-flex align-center justify-start">
                            <span class="ml-3 hb-font-caption-medium">{{ item.title }}</span>
                        </v-col>
                        <v-col cols="2" class="d-flex align-center justify-start px-0">
                            <span class="hb-font-caption">${{ item.charge?.toFixed(2) }}</span>
                        </v-col>
                        <v-col cols="2" class="d-flex align-center justify-start px-0">
                            <span class="hb-font-caption">${{ item.paidAmount?.toFixed(2) }}</span>
                        </v-col>
                        <v-col cols="3" class="d-flex align-center justify-start pl-0">
                            <span class="hb-font-caption">${{ item.unpaidAmount?.toFixed(2) }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class="my-3 mx-3"></v-divider>
                    <div class="my-2 pl-3">
                        <span class="hb-font-caption">Total creditable amount:</span> <span
                            class="hb-font-caption-medium">
                            {{ totalCreditable | formatMoney }}</span>
                    </div>
                    <div class="pl-3">
                        <v-row no-gutters class="mb-0 mt-3">
                            <v-col cols="4" class="hb-font-caption-medium pt-2">
                                Line Item Credit:
                            </v-col>
                            <v-col class="justify-start">
                                <hb-text-field 
                                    name="amount" 
                                    class="hide-number-spinner" 
                                    condensed box medium
                                    v-model="lineItemCredit" 
                                    v-validate.disable="'required|max:45'" type="number"
                                    data-vv-scope="reversal" 
                                    data-vv-name="amount"
                                    id="amount"
                                    :placeholder="'$ 0'"
                                    :error="errors.has('reversal.amount')">
                                </hb-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mb-0 mt-n2">
                            <v-col cols="4">
                                <span class="hb-font-caption-medium">Tax Credit: </span>
                            </v-col>
                            <v-col class="justify-start">
                                <span class="hb-font-caption"> {{ taxCredit | formatMoney }}</span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mb-1">
                            <v-col cols="4">
                                <span class="hb-font-caption-medium">Total Credit: </span>
                            </v-col>
                            <v-col class="justify-start">
                                <span class="hb-font-caption"> {{ totalCredit | formatMoney }}</span>
                            </v-col>
                        </v-row>
                        <!-- <v-row no-gutters class="mb-0 mt-3">
                            <v-col cols="8" class="justify-start">
                                <hb-textarea
                                    v-model="reasonForCredit"
                                    v-validate.disable="'required|max:1000'"
                                    id="reason"
                                    name="reason"
                                    data-vv-scope="reversal"
                                    data-vv-as="Reason for Reversal"
                                    :error="errors.has('reversal.reason')"
                                    :placeholder="'Enter Reason for Credit'"
                                ></hb-textarea>
                            </v-col>
                        </v-row> -->
                        <!-- <v-row no-gutters class="mb-0 mt-2">
                            <v-col cols="8" class="justify-start">
                                <span class="hb-font-caption"> A payment amount is applied to invoice.</span><br />
                                <span class="hb-font-caption">How would you like to settle the amount below.</span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mb-2 mt-2" v-for="(p, i) in getFilteredPayments(invoice)" :key="p.id">
                            <v-col cols="8" class="justify-start">
                                <hb-select :items="paymentOptions[i]?.options || []"
                                    v-model="paymentOptions[i].selectedOption" label="Payment Method"
                                    required></hb-select>
                            </v-col>
                        </v-row> -->
                    </div>
                </div>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="primary" @click="applyCredit">Apply Credit</hb-btn>
            </template>
            <!-- Add this debugging div -->
            <div v-if="false">
                Debug: {{ { refundFlag, payment, refundOption: refund_option, isRefund } }}
            </div>
        </hb-modal>
    </div>
</template>

<script>
import { notificationMixin } from '../../mixins/notificationMixin.js';
import api from '../../assets/api.js';
import moment from 'moment';
import { mapMutations } from 'vuex';

export default {

    name: 'CreditModal',
    props: ['value', 'line', 'invoice'],
    mixins: [notificationMixin],
    data() {
        return {
            lineItemCredit: '',
            refundAmount: 0,
            taxCredit: 0,
            totalCredit: 0,
            reasonForCredit: '',
            paymentMethod: null,
            totalCreditable: 0,
            paymentOptions: [
                { selectedOption: null }
            ],
            is_fatzebra_payment: false,
            is_fatzebra_card_payment: false,
            is_fatzebra_non_card_payment_method: false,
            if_eftpos_payment: false,
            paymentMethods: ['Payment cash', 'Credit card', 'Bank transfer'],
            lineItems: [],
            refundFlag: false,
            is_void: false,
            refundConfirmation: false,
            is_inter_property_payment: false,
            selectedInvoices: [],
            refund_option: 'full',
            payment: {},
            void_data: {},
            credit_data: {},
            reversalConfirmation: false,
            isRefund: false,
            reasonForCreditError: false
        };
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    async created() {
        console.log("Line: ", this.line);
        let line_adjustments = this.line.LineAllocations
            .filter(ila => ila.type === 'adjustment')
            .reduce((total, ila) => total + ila.total_allocation, 0);
        let adjustmentAllocations = this.line.LineAllocations
            .filter(ila => ila.type === 'adjustment');
        let adjustmentCostAllocation = adjustmentAllocations.reduce((total, ila) => total + ila.cost_allocation, 0);
        let adjustmentTaxAllocation = adjustmentAllocations.reduce((total, ila) => total + ila.tax_allocation, 0);  
        this.lineItems = [
            { title: 'Line Item Amount', charge: this.line.cost - this.line.totalDiscounts, paidAmount: this.line.line_item_amount_paid - adjustmentCostAllocation, unpaidAmount: this.line.cost - this.line.line_item_amount_paid - this.line.totalDiscounts },
            { title: 'Tax Amount', charge: this.line.totalTax, paidAmount: this.line.line_tax_amount_paid - adjustmentTaxAllocation, unpaidAmount: this.line.totalTax - this.line.line_tax_amount_paid },
            { title: 'Subtotal', charge: this.line.total + this.line.totalTax, paidAmount: this.line.line_item_amount_paid + this.line.line_tax_amount_paid - line_adjustments, unpaidAmount: this.line.line_pending_amount }
        ];
        this.totalCreditable = this.line.line_item_amount_paid + this.line.line_pending_amount + this.line.line_tax_amount_paid - this.line.totalTax - adjustmentCostAllocation;
        await this.fetchData()
    },
    watch: {
        lineItemCredit(value) {
            this.closeMessageNotification()
            let amount = parseFloat(value);
            let line_adjustments = this.line.LineAllocations
                .filter(ila => ila.type === 'adjustment')
                .reduce((total, ila) => total + ila.cost_allocation, 0);
            // consider only product cost, and subtract the product adjustments (do not consider tax allocations)
            const maxCredit = this.line.subtotal - line_adjustments || 0;

            if (amount > maxCredit) {
                console.error("Entered credit amount exceeds the total line charge ", amount, maxCredit);
                this.showMessageNotification({
                    type: "error",
                    description: `Entered amount exceeds the creditable amount.`
                });
                return;
            }

            const taxPercentage = this.line?.total_tax > 0 && this.line.cost > 0 ? (this.line?.total_tax / this.line.cost) * 100 : 0;
            console.log("Tax Percentage:", taxPercentage);

            const taxOnCredit = (amount * taxPercentage) / 100;
            console.log("Tax on Credit:", taxOnCredit);
            this.totalCredit = Number(value || 0) + Number(taxOnCredit || 0);
            this.taxCredit = Number(taxOnCredit || 0);
        },
    },
    methods: {
        ...mapMutations({
            setReversalObject: 'paymentsStore/setReversalObject'
        }),
        async fetchData() {
            await api.get(this, api.PROPERTIES + this.invoice.Property.id + '/connections').then(async (c) => {

                if (c && c.connections) {
                    console.log("c.connections?.length", c.connections.length);
                    if (c.connections.length > 0) {
                        let fatZebraConnection = c.connections.find(c => c.name === 'fatzebra');
                        this.is_fatzebra_payment = fatZebraConnection ? true : false;

                        let FatZebraNonCardPayments = c.connections.find(c => c.name === 'fatzebra' && ['directdebit', 'eftpos', 'cash', 'check'].includes(c.type));
                        this.is_fatzebra_non_card_payment_method = FatZebraNonCardPayments ? true : false;
                    }
                }
            }).catch(err => {
                console.error("Error fetching connections:", err);
            });
            this.invoice.date = moment(this.invoice.date).format('MM/DD/YYYY');
            this.invoice.due = moment(this.invoice.due).format('MM/DD/YYYY');
            this.getpaymentOptions();

            if (this.invoice?.Payments[0]?.payment_id) {
                api.get(this, api.GET_PAYMENT + this.invoice.Payments[0].payment_id + "?unit_info=true").then(async (r) => {
                    this.payment = r.payment;
                    this.payment.Property = r.payment.Property
                    this.getRefundOptions();
                    this.selectedInvoices = this.is_inter_property_payment ? this.payment.InterPropertyPayment.appliedPayments : this.payment.AppliedPayments;//map(payment => payment.invoiceId);
                    this.auctionPayment = this.payment.is_auction_payment;
                    this.is_auctioned_lease_payment = this.payment.is_auctioned_lease_payment;
                    this.is_inter_property_payment = this.payment.is_inter_property_payment;
                });
            }
        },
        getRefundOptions() {
            let text = ''
            if (['card', 'ach'].indexOf(this.payment.method) >= 0) {
                text = this.payment.PaymentMethod.card_type + '****' + this.payment.PaymentMethod.card_end
            } else {
                text = this.payment.method
            }

            this.refund_option = {
                text,
                value: this.payment.PaymentMethod.id || 1
            }

        },

        async credit(amount) {
            const payload = { amount };
            api.post(this, api.INVOICES + `${this.invoice.id}/invoice_line/${this.line.id}/credit`, payload)
                .then((response) => {
                    console.log("Credit updated successfully", response);
                    this.showMessageNotification({
                        type: "success",
                        description: `Credit applied successfully to invoice line.`
                    });
                    this.invoice = response.invoice;
                })
                .catch((error) => {
                    console.error("Failed to update credit", error);
                    this.showMessageNotification({
                        type: "error",
                        description: `Failed to credit the invoice line. Please try again later.`
                    });
                });
            return;
        },

        async refund(refundAmount) {
            this.isRefund = true;
            this.refundFlag = true;
            this.is_void = true;
            console.log('Refund flags:', { refundFlag: this.refundFlag, isRefund: this.isRefund, is_void: this.is_void });

            let data = {
                amount: parseFloat(refundAmount)?.toFixed(2),
                is_prepay: false,
                invoices: [this.invoice]
            }

            if (this.refund_option.value === 0) {
                data.is_prepay = true
            }

            // Initialize the reversal object before setting it
            this.reversal = data;
            this.setReversalObject({ reversal: data });

            // Add a console log to verify the component should show
            console.log('Reversal component should show:', {
                refundFlag: this.refundFlag,
                payment: this.payment,
                refundOption: this.refund_option
            });

        },
        async void(invoice) {
            // api.post(this, api.INVOICES + invoice.id + '/void', data).then(r => {
            //   this.showVoidInvoice = false;
            //   this.dialog = false;
            //   this.state = 'view';
            //   this.$emit('close');
            //   EventBus.$emit('invoice_saved');
            //   if(msg) {
            //     this.successSet('TransactionHistory', `Invoice ${this.invoice.number} had been voided. The remaining balance has been placed in prepay/refund`);
            //   }
            // });
        },
        async applyCredit() {
            const valid = await this.$validator.validateAll('reversal');
            if (!valid) {
                this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                return;
            }
            await this.closeMessageNotification()
            let validInputs = await this.validateInputs();
            if (!validInputs) {
                return;
            }
            let amount = parseFloat(this.lineItemCredit);
            const unpaidAllocation = this.line.line_pending_amount || 0;
            let line_adjustments = this.line.LineAllocations
                .filter(ila => ila.type === 'adjustment')
                .reduce((total, ila) => total + ila.cost_allocation, 0);
            // consider only product cost, and subtract the product adjustments (do not consider tax allocations)
            const maxCredit = this.line.subtotal - line_adjustments || 0;

            if (amount > maxCredit) {
                console.error("Entered credit amount exceeds the total line charge ", amount, maxCredit);
                this.showMessageNotification({
                    type: "error",
                    description: `Entered amount exceeds the creditable amount.`
                });
                return;
            }

            const taxPercentage = this.line?.total_tax > 0 && this.line.cost > 0 ? (this.line?.total_tax / this.line.cost) * 100 : 0;
            console.log("Tax Percentage:", taxPercentage);

            const taxOnCredit = (amount * taxPercentage) / 100;
            console.log("Tax on Credit:", taxOnCredit);
            this.totalCredit = Number(amount || 0) + Number(taxOnCredit || 0);
            amount = this.totalCredit;
            this.taxCredit = Number(taxOnCredit || 0);

            let line_total = this.line.cost + this.line.total_tax - this.line.total_discounts;


            if ((Math.round((amount + line_adjustments) * 1e2) / 1e2) > line_total) {
                this.showMessageNotification({
                    type: "error",
                    description: `Adjustment cannot be credited`
                });
                return;
            }

            if (amount <= unpaidAllocation) {
                console.log(`Crediting ${amount} to the unpaid balance.`);
                await this.credit(amount);
            }

            if (amount > unpaidAllocation) {
                let creditAmount = parseFloat(unpaidAllocation?.toFixed(2));
                this.refundAmount = parseFloat((amount - creditAmount)?.toFixed(2));
                console.log("creditAmount", creditAmount);
                console.log("refundAmount", this.refundAmount);
                console.log(`Crediting $${creditAmount} to the unpaid balance and refunding $${this.refundAmount} via void modal.`);
                if (creditAmount > 0 && this.refundAmount > 0) {
                    console.log("Inside credit and refund");
                    this.$emit("voidModal", { isCredited: true, refundAmount: this.refundAmount, line: this.line, creditAmount: creditAmount });
                } else {
                    console.log("Inside credit or refund else");
                    if (creditAmount > 0) {
                        console.log("Inside credit");
                        await this.credit(creditAmount)
                    }
                    if (this.refundAmount > 0) {
                        console.log("Inside refund");
                            this.$emit("voidModal", { isCredited: true, refundAmount: this.refundAmount, line: this.line, creditAmount: creditAmount });
                    }
                    // await this.void(this.invoice)
                }
            }
            this.cancel();
        },

        async validateInputs(){
            if (this.lineItemCredit <= 0) {
                this.showMessageNotification({
                    type: "error",
                    description: "Credit amount must be more than 0."
                });
                return false;
            }
            return true;
        },
        getFilteredPayments(invoice) {
            if (this.credit_line_item && this.line_id) {
                const line = invoice.InvoiceLines.find(line => line.id === this.line_id);
                if (!line) return [];
                const paymentIds = line.LineAllocations.map(allocation => allocation.payment_id);
                return invoice.Payments.filter(payment => paymentIds.includes(payment.Payment.id) && payment.Payment.method !== 'adjustment');
            } else {
                return invoice.Payments.filter(payment => payment.Payment.method !== 'adjustment');
            }
        },
        cancel() {
            this.dialog = false;
        },
        updateCreditAmount(line, creditAmount) {
            let amount = parseFloat(creditAmount);

            const unpaidAllocation = line.line_pending_amount || 0;
            const paidAllocation = line.line_item_amount_paid + line.line_tax_amount_paid || 0;
            const availableCredit = unpaidAllocation + paidAllocation;
            const maxCredit = line.subtotal + line.total_tax || 0;

            if (amount > maxCredit) {
                console.error("Entered credit amount exceeds the maximum allowable credit for this line.");
                this.showMessageNotification({
                    type: "error",
                    description: `Entered credit amount ($${amount}) exceeds the total line charge ($${maxCredit}).`
                });
                return;
            }

            console.log("Available Credit:", availableCredit);
            console.log("Unpaid Allocation:", unpaidAllocation);
            console.log("Paid Allocation:", paidAllocation);

            const taxPercentage = line?.total_tax > 0 && line.cost > 0 ? (line?.total_tax / line.cost) * 100 : 0;
            console.log("Tax Percentage:", taxPercentage);

            const taxOnCredit = (amount * taxPercentage) / 100;
            console.log("Tax on Credit:", taxOnCredit);
            amount = Number(creditAmount) + taxOnCredit;
            console.log("totalCreditAmount with tax", amount);

            let line_total = line.cost + line.total_tax - line.total_discounts;
            let line_adjustments = line.LineAllocations
                .filter(ila => ila.type == 'adjustment')
                .reduce((total, ila) => total + ila.total_allocation, 0);

            if ((Math.round((amount + line_adjustments) * 1e2) / 1e2) > line_total) {
                this.showMessageNotification({
                    type: "error",
                    description: `Adjustment cannot be credited`
                });
                return;
            }

            if (amount <= unpaidAllocation) {
                console.log(`Crediting ${amount} to the unpaid balance.`);
                const payload = { amount };
                api.post(this, api.INVOICES + `${this.invoice.id}/invoice_line/${line.id}/credit`, payload)
                    .then((response) => {
                        console.log("Credit updated successfully", response);
                        this.invoice = response.invoice;
                    })
                    .catch((error) => {
                        console.error("Failed to update credit", error);
                    });
                return;
            }

            if (amount > unpaidAllocation) {
                const creditAmount = parseFloat(unpaidAllocation?.toFixed(2));
                const refundAmount = parseFloat((amount - creditAmount)?.toFixed(2));
                console.log("creditAmount", creditAmount);
                console.log("refundAmount", refundAmount);
                console.log(`Crediting $${creditAmount} to the unpaid balance and refunding $${refundAmount} via void modal.`);
                if (creditAmount > 0 && refundAmount > 0) {
                    console.log("Inside credit and refund");
                    this.$emit("open-void-modal", { is_credited: true, refundAmount: refundAmount, line: line, creditAmount: creditAmount });
                } else {
                    if (creditAmount > 0) {
                        const payload = { amount: creditAmount };
                        api.post(this, api.INVOICES + `${this.invoice.id}/invoice_line/${line.id}/credit`, payload)
                            .then((response) => {
                                console.log("Credit updated successfully", response);
                                this.invoice = response.invoice;
                            })
                            .catch((error) => {
                                console.error("Failed to update credit", error);
                            });
                    }
                    if (refundAmount > 0) {
                        console.log("Inside refund");
                        this.$emit("open-void-modal", { is_credited: true, refundAmount: refundAmount, line: line, creditAmount: creditAmount });
                    }
                }
            }

        },
        getpaymentOptions() {
            console.log("Invoice: ", this.invoice);
            this.paymentOptions = []
            this.invoice.Payments.filter((p) => p.Payment.method !== "adjustment").forEach(p => {
                let amount = this.credit_line_item ? this.refundAmount : p.amount;
                if (p.Payment.method && p.Payment.method === 'credit') {
                    this.paymentOptions.push({
                        payment_id: p.id,
                        options: [{
                            value: Number(p.id),
                            text: `Apply ($${amount}) to a future charge`,
                            type: 'future',
                            disabled: !!this.invoice.Lease.moved_out,
                            ...p
                        }],
                        selectedOption: null
                    });
                    return;
                }

                let text = ''
                if (['card', 'ach'].indexOf(p.Payment.method) >= 0) {
                    text = p.PaymentMethod ? p.PaymentMethod.card_type + '****' + p.PaymentMethod.card_end : ''
                } else {
                    if (this.is_fatzebra_payment && this.is_fatzebra_non_card_payment_method) {
                        switch (p.Payment.method) {
                            case 'cash':
                                text = 'Direct Refund';
                                break;
                            case 'directdebit':
                                text = 'Direct Refund';
                                break;
                            case 'check':
                                text = 'Direct Refund';
                                break;
                            case 'eftpos':
                                text = 'EFTPOS Refund/Direct Refund';
                                break;
                            default:
                                text = '';
                                break;
                        }
                    } else {
                        text = p.Payment.method
                    }
                }

                let options = [
                    {
                        value: p.id,
                        text: `Refund ($${amount}) to ${text}`,
                        type: 'refund',
                        ...p
                    }]

                if (this.invoice.lease_id) {
                    options.push({
                        value: 0,
                        text: `Apply ($${amount}) to a future charge`,
                        type: 'future',
                        disabled: !!this.invoice.Lease.moved_out,
                        ...p
                    });
                }

                this.paymentOptions.push({
                    payment_id: p.id,
                    options,
                    selectedOption: null
                })
            })
        },
    }
}
</script>

<style scoped>
.line-to-credit {
    margin-bottom: 16px;
}

::v-deep(input[type="number"])::-webkit-inner-spin-button,
::v-deep(input[type="number"])::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::v-deep(input[type="number"]) {
  -moz-appearance: textfield;
  /* Firefox */
}

</style>