export default Object.freeze({
    BILL_DAY:{
      ANNIVERSARY:"Anniversary",
      FIRST_OF_THE_MONTH:"1st of the Month"  
    },
    NEW_MOVE_IN_SLUG: 'new_move_in',
    TRANSFER_ADD_SPACE_SLUG: 'transfer_add_space',
    AUTOPAY_ENROLLMENT_SLUG: 'autopay_enrollment',
    INSURANCE_COVERAGE_SLUG: 'insurance_coverage',
    LEASE_TEMPLATE: 'lease_template',
    CHECKLIST:[
      { key: "lease", name: "Lease Agreement", tag: "lease" },
      { key: "autopay", name: "AutoPay Enrollment", tag: "autopay" },
      { key: "deny-coverage", name: "Deny Insurance/Protection Form", tag: "deny-coverage" },
      { key: "enroll-coverage", name: "Insurance / Protection Enrollment Document", tag: "enroll-coverage" },
      { key: "vehicle", name: "Vehicle Addendum", tag: "vehicle" },
      { key: "military", name: "Military Waiver", tag: "military" }
    ],
    DOCUMENT_TAGS: {
      LEASE: 'lease',
      AUTOPAY: 'autopay',
      DENY_COVERAGE: 'deny-coverage',
      ENROLL_COVERAGE: 'enroll-coverage',
      VEHICLE: 'vehicle',
      MILITARY: 'military',
      OTHER: 'other'
    },
  });
  