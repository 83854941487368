<template>
  <div>
    <v-container class="payment-method-container">
      <div>
        <v-row no-gutters :class="$vuetify.breakpoint.xs ? 'mb-3': 'mb-2 justify-space-between'">
          <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6"
            :class="{'pr-0': $vuetify.breakpoint.xs, 'pr-5': !$vuetify.breakpoint.xs}">
            <v-col class="pa-0 mt-4" v-show="cardMethods.length">
              <div class="card-methods-container">
                <div class="card-methods-content">
                  <v-col>
                    <v-radio-group dense v-model="method" :mandatory="false" @change="handleMethodChange">
                      <v-radio value="existing">
                        <template v-slot:label>
                          <v-select style="font-size: 14px;" :disabled="method !== 'existing'" hide-details
                            :value="payment.payment_method_id" :items="cardMethods" item-value="id" clearable
                            item-text="card_end" :error-messages="errors.collect('existing_payment_method_id')"
                            placeholder="Card On File" dense
                            @change="handleUpdate('payment', 'payment_method_id', $event)"
                            v-validate="method === 'existing'? 'required' : ''"
                            :class="{'custom-field-error' : errors.first('existing_payment_method_id')}"
                            name="existing_payment_method_id" id="existing_payment_method_id"
                            data-vv-name="existing_payment_method_id" data-vv-as="Card On File">
                            <template v-slot:selection="data">
                              ****{{data.item.card_end}}
                            </template>

                            <template v-slot:item="data">
                              <v-list-item-avatar tile>
                                <v-img v-show="data.item.card_type === 'visa'" src="/img/cc_icons/64/visa.png"></v-img>
                                <v-img v-show="data.item.card_type === 'mastercard'"
                                  src="/img/cc_icons/64/mastercard.png"></v-img>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <span class="font-weight-medium">{{data.item.name_on_card}}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle>****{{data.item.card_end}}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-select>
                        </template>
                      </v-radio>

                      <v-radio value="new" @change="handleUpdate('paymentMethodID', 'null', $event)">
                        <template v-slot:label>
                          <hb-icon class="mr-3" color="#101318">mdi-credit-card-outline</hb-icon>New Credit/Debit Card
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </div>
              </div>
            </v-col>
            <v-col v-show=" method === 'new'" class="pa-0 mb-2">
              <v-row no-gutters :class="{'mt-4' : method === 'new'}">
                <v-col :cols="$vuetify.breakpoint.xs ? 6 : 8" sm="6" :md="dense_header ? 6 : 7" :lg="dense_header ? 6: 7" :xl="dense_header ? 5 : 7" class="pa-0 ma-0" >
                  <v-text-field :class="{'custom-field-error' : errors.first('name_on_card')}" hide-details
                    :value="payment_method.name_on_card" v-validate="'required|max:45'" data-vv-name="name_on_card"
                    data-vv-as="Name On Card" :error-messages="errors.collect('name_on_card')" label="Cardholder Name"
                    id="card-name" name="card-name" autocomplete="cc-card-name"
                    @input="handleInputUpdate('payment_method', 'name_on_card', $event)">
                  </v-text-field>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.xs ? 6 : 4"  sm="6" :md="dense_header ? 6 : 5" :lg="dense_header ? 6 : 5" :xl="dense_header ? 7 : 5"
                  v-if="paymentConfig.SHOW_TENANT_NAME && contact && contact.first && (contact.last || isRentAsBusiness)">
                  <hb-checkbox label="Same as Tenant" v-model="allowTenantName" hide-details class="float-right mt-3">
                  </hb-checkbox>
                </v-col>
              </v-row>
              <v-row dense class="mt-3">
                <v-col cols="12">
                  <card-number :method="method" ref="hbCardNumber" />
                </v-col>
              </v-row>
              <v-row dense class="mt-1">
                <v-col cols="4">
                  <hb-select box :class="{'custom-field-error' : errors.first('exp_month')}" :items="monthList"
                    hide-details :value="payment_method.exp_mo" v-validate="'required'" data-vv-name="exp_month"
                    data-vv-as="Expiration Month" :error="errors.collect('exp_month').length > 0" label="MM"
                    @change="handleUpdate('payment_method', 'exp_mo', $event)"
                    @blur="$validator.validate('exp_month')"  :clearable="false" ></hb-select>
                </v-col>
                <v-col cols="4">
                  <hb-select box :class="{'custom-field-error' : errors.first('exp_yr')}" :items="yearList" hide-details
                    :value="payment_method.exp_yr" v-validate="'required'" data-vv-name="exp_yr"
                    data-vv-as="Expiration Year" :error="errors.collect('exp_yr').length > 0" label="YY"
                    @change="handleUpdate('payment_method', 'exp_yr', $event)"
                    @blur="$validator.validate('exp_yr')" :clearable="false"></hb-select>
                </v-col>
                <v-col cols="4">
                  <card-cvv :method="method" ref="hbCvvNumber" />
                </v-col>
              </v-row>
            </v-col>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6" v-show=" method === 'new'"
            :class="{'mx-1 mt-2': $vuetify.breakpoint.xs, 'mt-6' : (!$vuetify.breakpoint.xs && method === 'new')}">
            <v-row v-if="!dense_header" dense no-gutters>
              <v-col v-if="paymentConfig.SHOW_BILLING_ADDRESS">
                <span class="font-weight-medium">Billing Address</span>
                <v-checkbox label="Default Address" v-model="allowDefaultAddress" hide-details
                  class="float-right mt-0 pt-0"></v-checkbox>
              </v-col>
            </v-row>
            <v-row v-else dense>
              <v-col class="py-0">
                <span class="label-heading-1 font-weight-medium">Billing Address</span>
                  <v-checkbox label="Default Address" v-model="allowDefaultAddress"
                  v-if="ten_address && ten_address.length > 0" hide-details class=" float-right"></v-checkbox>
              </v-col>
            </v-row>

            <v-row no-gutters>
             <v-col>
            <address-autofill
              class="mt-3 mb-2"
              v-model="autofillData"
              @input="addressDataSet($event)"
            >
            </address-autofill>
            </v-col>
            </v-row>
            
            <v-row dense class="mt-2">
              <v-col cols="12">
                <v-text-field :class="{ 'custom-field-error': errors.first('address') }" ref="address" hide-details
                  :value="payment_method.address" v-validate="'required|max:100'" data-vv-name="address"
                  data-vv-as="Address" :error-messages="errors.collect('address')" label="Street*"
                  id="card-billing-address" name="card-billing-address" autocomplete="cc-card-billing-address"
                  @input="handleInputUpdate('payment_method', 'address', $event)">
                </v-text-field>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-text-field :class="{ 'custom-field-error': errors.first('address2') }" hide-details
                  :value="payment_method.address2" v-validate="'max:45'" data-vv-name="address2"
                  data-vv-as="Billing Address" :error-messages="errors.collect('address2')" label="Apt, Suite, Etc."
                  id="card-billing-address2" name="card-billing-address2" autocomplete="cc-card-billing-address2"
                  @input="handleInputUpdate('payment_method', 'address2', $event)">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="mt-1">
                <HbAutocomplete :class="{ 'custom-field-error': errors.first('country') }" :items="countriesListMixin"
                  hide-details v-validate="'required'" :value="payment_method.country" label="Country*" single-line
                  id="country" data-vv-as="Country" name="country" :error-messages="errors.collect('country')"
                  @change="handleUpdate('payment_method', 'country', $event)" backgroundColor="">
                </HbAutocomplete>
              </v-col>
            </v-row>

            <v-row  class="pt-0">
              <v-col cols="12">
                <HbAutocomplete :class="{ 'custom-field-error': errors.first('city') }" hide-details
                  :items="paymentCityListMixin" :value="payment_method.city"
                  v-validate="paymentCityListMixin.length ? 'required' : ''" label="City*" single-line data-vv-as="City"
                  id="card-city" name="card-city" :error-messages="errors.collect('city')"
                  @change="handleUpdate('payment_method', 'city', $event)"
                  @keydown.enter="setCityList($event.target.value, true)" backgroundColor="" append-icon="">
                </HbAutocomplete>
              </v-col>
            </v-row>

            <v-row dense >             
              <v-col cols="6" class="mt-2">
                <HbAutocomplete :class="{ 'custom-field-error': errors.first('state') }" :items="paymentStateListMixin"
                  hide-details item-text='name' item-value='value' :value="payment_method.state" label="State"
                  single-line data-vv-as="State" name="credit_state" id="credit_state"
                  :error-messages="errors.collect('state')" @change="handleUpdate('payment_method', 'state', $event)"
                  backgroundColor="" />
              </v-col>
              <v-col cols="6" class="mt-1">
                <v-text-field :class="{ 'custom-field-error': errors.first('card-zip') }" hide-details
                  :value="payment_method.zip" v-validate="'required|max:10'" data-vv-name="zip" data-vv-as="Zip"
                  :error-messages="errors.collect('zip')" label="Zip*" id="card-zip" name="card-zip"
                  autocomplete="cc-card-zip" @input="handleInputUpdate('payment_method', 'zip', $event)">
                </v-text-field>
              </v-col>
            </v-row>
            
            <v-row v-if="!dense_header" class="mt-3">
              <v-col cols="7" class="d-flex" v-if="paymentConfig.SHOW_SAVE_PAYMENT">
               <v-checkbox class="pt-0"  :disabled="auto_charge" hide-details :input-value="payment_method.save_to_account" @change="updateSaveToAccount($event)" label="Save Payment Method"></v-checkbox>
             </v-col>
            </v-row>

            <v-row dense v-if="!dense_header">
              <v-col>
                <hb-textarea :class="{ 'custom-field-error': errors.first('notes') }"  hide-details
                  :value="payment.notes" data-vv-name="notes" :error="errors.has('notes')" placeholder="Notes"
                  id="card-notes" name="card-notes" autocomplete="cc-card-notes"
                  @input="handleInputUpdate('payment', 'notes', $event)" class="pb-4 mt-4" backgroundColor="">
                </hb-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- <v-row dense>
          <v-col>
            <v-text-field
              :class="{'custom-field-error' : errors.first('name_on_card')}"
              hide-details
              :value="payment_method.name_on_card"
              v-validate="'required|max:45'"
              data-vv-name="name_on_card"
              data-vv-as="Name On Card"
              :error-messages="errors.collect('name_on_card')"
              label="Name On Card*"
              id="card-name"
              name="card-name"
              autocomplete="cc-card-name"
              @input="handleInputUpdate('payment_method', 'name_on_card', $event)"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" v-if="paymentConfig.SHOW_TENANT_NAME && contact && contact.first && (contact.last || isRentAsBusiness)">
            <hb-checkbox label="Same as Tenant Name" v-model="allowTenantName"
              hide-details class="float-right mt-5">
            </hb-checkbox>
          </v-col>
        </v-row> -->

        <!-- <v-row dense v-if="method === 'new'">
          <v-col cols="8">
            <card-number :method="method" ref="hbCardNumber" />
          </v-col>
          <v-col cols="4">
            <card-cvv :method="method" ref="hbCvvNumber"/>
          </v-col>
        </v-row> -->

        <!-- <v-row dense>
          <v-col cols="8">
            <v-select
              :class="{'custom-field-error' : errors.first('exp_month')}"
              :items="monthList"
              hide-details
              :value="payment_method.exp_mo"
              v-validate="'required'"
              data-vv-name="exp_month"
              data-vv-as="Expiration Month"
              :error-messages="errors.collect('exp_month')"
              label="Expiration Month*"
              @change="handleUpdate('payment_method', 'exp_mo', $event)"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              :class="{'custom-field-error' : errors.first('exp_yr')}"
              :items="yearList"
              hide-details
              :value="payment_method.exp_yr"
              v-validate="'required'"
              data-vv-name="exp_yr"
              data-vv-as="Expiration Year"
              :error-messages="errors.collect('exp_yr')"
              label="Expiration Year*"
              @change="handleUpdate('payment_method', 'exp_yr', $event)"
            ></v-select>
          </v-col>
        </v-row> -->

        <!-- <v-row v-if="!dense_header" dense class="mt-6">
          <v-col v-if="paymentConfig.SHOW_BILLING_ADDRESS">
            <span class="font-weight-medium">Billing Address</span>
            <v-checkbox label="Default Address" v-model="allowDefaultAddress" hide-details class="float-right mt-0 pt-0"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-else dense class="mt-5">
          <v-col cols="12" class="py-0">
            <span class="label-heading-1 font-weight-medium">Billing Address</span>
            <br>
            <v-checkbox label="Use tenant's Address" v-model="allowDefaultAddress" v-if="ten_address && ten_address.length > 0" hide-details class="mt-3"></v-checkbox>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-text-field
              :class="{'custom-field-error' : errors.first('address')}"
              ref="address"
              hide-details
              :value="payment_method.address"
              v-validate="'required|max:100'"
              data-vv-name="address"
              data-vv-as="Address"
              :error-messages="errors.collect('address')"
              label="Billing Address*"
              id="card-billing-address"
              name="card-billing-address"
              autocomplete="cc-card-billing-address"
              @input="handleInputUpdate('payment_method', 'address', $event)"
              >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              :class="{'custom-field-error' : errors.first('address2')}"
              hide-details
              :value="payment_method.address2"
              v-validate="'max:45'"
              data-vv-name="address2"
              data-vv-as="Billing Address"
              :error-messages="errors.collect('address2')"
              label="Apt, Suite, Etc."
              id="card-billing-address2"
              name="card-billing-address2"
              autocomplete="cc-card-billing-address2"
              @input="handleInputUpdate('payment_method', 'address2', $event)"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" class="mt-3">
            <HbAutocomplete
                :class="{'custom-field-error' : errors.first('country')}"
                :items="countriesListMixin"
                hide-details
                v-validate="'required'"
                :value="payment_method.country"
                label="Country*"
                single-line
                id="country"
                data-vv-as="Country"
                name="country"
                :error-messages="errors.collect('country')"
                @change="handleUpdate('payment_method', 'country', $event)"
            >
            </HbAutocomplete>
          </v-col>
          <v-col cols="12" class="mt-3">
            <HbAutocomplete
               :class="{'custom-field-error' : errors.first('state')}"
               :items="paymentStateListMixin"
               hide-details
               item-text='name'
               item-value='value'
               :value="payment_method.state"
               label="State"
               single-line
               data-vv-as="State"
               name="credit_state"
               id="credit_state"
               :error-messages="errors.collect('state')"
               @change="handleUpdate('payment_method', 'state', $event)"
              />
          </v-col>
          <v-col cols="8" class="mt-5">
            <HbAutocomplete
              :class="{'custom-field-error' : errors.first('city')}"
              hide-details
              :items="paymentCityListMixin"
              :value="payment_method.city"
              v-validate="paymentCityListMixin.length ? 'required' : ''"
              label="City*"
              single-line
              data-vv-as="City"
              id="card-city"
              name="card-city"
              :error-messages="errors.collect('city')"
              @change="handleUpdate('payment_method', 'city', $event)"
              @keydown.enter="setCityList($event.target.value, true)"
            >
            </HbAutocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :class="{'custom-field-error' : errors.first('card-zip')}"
              hide-details
              :value="payment_method.zip"
              v-validate="'required|max:10'"
              data-vv-name="zip"
              data-vv-as="Zip"
              :error-messages="errors.collect('zip')"
              label="Zip*"
              id="card-zip"
              name="card-zip"
              autocomplete="cc-card-zip"
              @input="handleInputUpdate('payment_method', 'zip', $event)"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row dense v-if="!dense_header">
          <v-col>
            <v-textarea
              :class="{'custom-field-error' : errors.first('notes')}"
              rows="2"
              hide-details
              :value="payment.notes"
              data-vv-name="notes"
              :error-messages="errors.collect('notes')"
              label="Notes"
              id="card-notes"
              name="card-notes"
              autocomplete="cc-card-notes"
              @input="handleInputUpdate('payment', 'notes', $event)"
              >
            </v-textarea>
          </v-col>
        </v-row> -->

      </div>

    </v-container>


  </div>
</template>

<script type="text/babel">
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import PaymentMethodBase from './PaymentMethodBase.vue';
    import VuetifyStatepicker from '../../includes/VuetifyStatepicker.vue';
    import { mapGetters, mapMutations, mapActions } from "vuex";
    import { EventBus } from '../../../EventBus';
    import moment from 'moment';
    import { countryStateCityMixin } from '../../../mixins/countryStateCityMixin';
    import AddressAutofill from '../../autofill/AddressAutofill.vue';

    export default {
        mixins: [ countryStateCityMixin ],
        data() {
            return {
              method: null,
              allowTenantName: false,
              monthList:[
                { text:'01', value:'01' },
                { text:'02', value:'02' },
                { text:'03', value:'03' },
                { text:'04', value:'04' },
                { text:'05', value:'05' },
                { text:'06', value:'06' },
                { text:'07', value:'07' },
                { text:'08', value:'08' },
                { text:'09', value:'09' },
                { text:'10', value:'10' },
                { text:'11', value:'11' },
                { text:'12', value:'12' },
              ],
              
              allowDefaultAddress: false
            }
        },
        components:{
            HbDatePicker,
            VuetifyStatepicker,
            AddressAutofill
        },
        computed:{
          yearList(){
            let year = moment().format('YYYY');
            let years = [];
            for(let i = 0; i < 11; i++){
              let fullYear = (+year + i).toString()
              years.push({
                text:  fullYear.slice(2),
                value: (+year + i).toString()
              })
            }
            return years;
          }, 
          ...mapGetters({
            paymentConfig: 'paymentsStore/getPaymentSourceConfig',
            getErrors: 'paymentsStore/getErrors',
            contact: 'paymentsStore/getContact',
            payment: 'paymentsStore/getPayment',
            payment_method: 'paymentsStore/getPaymentMethod',
            payment_methods: 'paymentsStore/getContactPaymentMethods'
          }),
            cardMethods(){
                return this.payment_methods.filter(pm => pm.type === 'card');
            },
            isRentAsBusiness(){
              return this.contact?.rent_as_business ? true : false;
            }
        },
        async mounted(){
          if(this.cardMethods.length){
              this.method = 'existing';
          } else {
            this.method = 'new';
          }
        },
        created(){
          this.setCountriesListEvent()
          if(this.payment_method.country){
            this.setPaymentStatesListEvent(this.payment_method.country)
            if(!this.payment_method.state){
              this.setPaymentCountryCitiesListEvent(this.payment_method.country)
            }else{
              this.setPaymentCitiesListEvent(this.payment_method.country, this.payment_method.state)
            }
            this.setCityList(this.payment_method.city)
          }
        },
        destroyed() {
        },
        props: ['auto_charge', 'dense_header','ten_address'],
        methods:{
          ...mapMutations({
            setCheckErrors: 'paymentsStore/setCheckErrors',
            setPaymentInformation: 'paymentsStore/setPaymentInformation',
            setPayment: 'paymentsStore/setPayment',
            setPaymentMethod: 'paymentsStore/setPaymentMethod',
            setPaymentMethodObject: 'paymentsStore/setPaymentMethodObject',
            setPaymentMethodID: 'paymentsStore/setPaymentMethodID',
          }),
          focusIf(num, ref, evt){
            if(evt.target.value.length === num){
                this.$refs[ref].focus();
            }
          },
          setCityList(value, toValidate = false){
            if(!value) return
            const isSpecialCharacter = this.validateCityName(value)
            const cityValidation = this.errors.items.indexOf('city')
            if(toValidate){
                this.errors.items.splice(cityValidation);
                if(isSpecialCharacter){
                    return this.errors.add({
                                field: 'city',
                                msg: 'Please add a valid city name.'
                            }) 
              }
            }
            const isCityExists = this.paymentCityListMixin.some((city) => city.toLowerCase() === value.toLowerCase())
            if(isCityExists) return

            this.paymentCityListMixin.push(value)
            const country = this.countriesListMixinRaw.find(item => item.name === this.payment_method.country || this.ten_address[0].Address.country);
            this.addMissingCity({
                country_code: country.isoCode,
                state_code: this.payment_method.state || this.ten_address[0].Address.state,
                city: value
            })
          },
          setAddress(){
            const countryValue = this.setCountryNameEvent(this.ten_address[0].Address.country)
            this.setPaymentStatesListEvent(countryValue)
            this.setPaymentCitiesListEvent(countryValue, this.ten_address[0].Address.state)
            this.setCityList(this.ten_address[0].Address.city)
            let updatedPM = {
              address: this.ten_address[0].Address.address,
              address2: this.ten_address[0].Address.address2,
              zip: this.ten_address[0].Address.zip,
              city: this.ten_address[0].Address.city,
              state: this.ten_address[0].Address.state,
              country: countryValue

            }
            this.setPaymentMethodObject({paymentMethod: {...this.payment_method, ...updatedPM}});
          },
          resetAddress(){
            let updatedPM = {
              address: '',
              address2: '',
              zip: '',
              city: '',
              state: '',
              country: ''

            }
            this.setPaymentMethodObject({paymentMethod: {...this.payment_method, ...updatedPM}});
          },
          handleUpdate(obj, property_name, property_value){
              if(obj === 'payment'){
                this.setPayment({property: property_name, propertyValue: property_value});
              }
              if(obj === 'paymentMethodID'){
                console.log('HIIIIIII')
                this.setPaymentMethodID('resetPaymentMethodID');
              }
              if(obj === 'payment_method'){
                this.setPaymentMethod({property: property_name, propertyValue: property_value});
                switch(true){
                    case property_name === 'country':
                      this.setPaymentMethod({property: 'state', propertyValue: ''});
                      this.setPaymentMethod({property: 'city', propertyValue: ''});
                      this.setPaymentStatesListEvent(this.payment_method.country)
                      this.setPaymentCountryCitiesListEvent(this.payment_method.country)
                      break;
                    case property_name === 'state':
                      this.setPaymentMethod({property: 'city', propertyValue: ''});
                      this.setPaymentCitiesListEvent(this.payment_method.country, this.payment_method.state)
                      break;
                    case property_name === 'city':
                      this.setPaymentMethod({property: property_name, propertyValue: property_value});
                      break;
                }
              }
              
            },
            handleInputUpdate : _.debounce(async function(obj, property_name, property_value) {
              this.handleUpdate(obj, property_name, property_value);
            }, 200),

            updateSaveToAccount(val){
              this.setPaymentMethod({property: 'save_to_account', propertyValue: !!val}); 
            },

            handleMethodChange(){
              this.$validator.reset();
              if(this.method!='existing'){
              this.payment.payment_method_id = null;
            }
            },

            async validateFields(){
              if(this.method === 'existing'){
                return this.$validator.validate('existing_payment_method_id')
              }
              if(this.method === 'new'){
                // promiseAll use kro
                const fieldsValidation = this.$validator.validateAll();
                const cardNumberValidation = this.$refs.hbCardNumber.validateCardNumber();
                const cardCVVValidation  = this.$refs.hbCvvNumber.validateCardCVV();
                const [fieldsValid, cardNumberValid, cardCVVValid] = await Promise.all([
                  fieldsValidation,
                  cardNumberValidation,
                  cardCVVValidation
                ]);

                return fieldsValid && cardNumberValid && cardCVVValid;
              }
            },

            addressDataSet(autofillData) {
            if(this.payment_method){
                if(autofillData?.country){
                  this.payment_method.country = autofillData.country
                  this.setPaymentStatesListEvent(this.payment_method.country)
                  this.setPaymentCountryCitiesListEvent(this.payment_method.country)
                }
                if(autofillData?.state  && this.paymentStateListMixin){
                     for(let state of this.paymentStateListMixin){
                      if(state.name  === autofillData.state){
                        this.payment_method.state = state.value
                      }
                     }
                    this.setPaymentMethod({property: 'city', propertyValue: ''});
                    this.setPaymentCitiesListEvent(this.payment_method.country, this.payment_method.state)
                }
                if(autofillData?.city){
                  this.payment_method.city = autofillData?.city
                }
                if(autofillData?.postalCode){
                  this.payment_method.zip = autofillData?.postalCode
                }
                if(autofillData?.address1){
                  this.payment_method.address = autofillData?.address1
                }
                if(autofillData?.apt){
                  this.payment_method.address2 = autofillData?.apt
                }
               
            }
            
          }
        },
        watch:{
          allowTenantName(val){
            if (val) {
              this.setPaymentMethod({property: 'name_on_card', propertyValue: `${this.contact.first} ${!this.isRentAsBusiness ? this.contact.last : ''}`});
            } else {
              this.setPaymentMethod({property: 'name_on_card', propertyValue: ''});
            }
          },
          auto_charge(){
            this.setPaymentMethod({property: 'save_to_account', propertyValue: this.auto_charge});
          },
          allowDefaultAddress(val){
            if (val) {
              this.setAddress();
            } else {
              this.resetAddress();
            }
          }
        }


    }
</script>
<style scoped>
  .payment-method-container{
    color: #101318;
  }
  .label-heading-1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101318;
  }
  .card-methods-container{
    width:100%;
    margin-top: 25px;
    background-color: white;
  }
  .card-methods-content{
    border: 1px solid #C4CDD5;
    border-radius: 6px;
  }
  .hb-autocomplete-width-370{
  max-width: 100% !important;
  }
  .v-text-field{
    padding-top: 0px !important;
    margin-top: 0px !important;
  }
  .hb-text-field-width-370{
    max-width: 100% !important;
  }

</style>
