<template>
  <hb-modal
    size="medium"
    title="Reject Reason"
    v-model="showRejectReason"
    confirmation
    show-help-link
  >
    <template v-slot:content>
      <div class="pa-4">
        <span class="hb-text-night">You are required to provide a reason for rejecting the direct debit application.</span>
      </div>

      <div class="pa-4">
        <v-text-field
          single-line
          dense
          flat
          class="input-font-size"
          label="Reject Reason*"
          v-model="reject_reason"
          data-vv-name="reject_reason"
          data-vv-as="reject reason"
          v-validate="'required'"
          :error-messages="errors.collect('reject_reason')"
          hide-details
        ></v-text-field>
      </div>
    </template>

    <template v-slot:actions>
      <hb-btn color="primary" @click="submitRejectReason" :disabled="isSubmitting || !reject_reason">
        Submit
      </hb-btn>
    </template>
  </hb-modal>
</template>

<script>
import { notificationMixin } from '../../mixins/notificationMixin.js';

export default {
  name: "DisplayReject",
  mixins: [ notificationMixin ],
  props: {
    refund_id: {
      type: Number,
      required: true 
    },
    showRejectReason: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      reject_reason: '',
      isSubmitting: false,
    };
  },
  methods: {
  
    async submitRejectReason() {
      this.isSubmitting = true;
      this.$emit('submit-reject-reason', this.reject_reason);
      this.isSubmitting = false;    }
  }
};
</script>
