<template>
     
    
    <hb-text-field
        v-model="cvv"
        box
        :class="{'custom-field-error' : errors.first(`${label}`)}"
        v-validate="`required|numeric|length:${maxlength}`"
        :data-vv-name="label"
        :data-vv-as="label"
        :error="errors.has(`${label}`)"
        :label="`${label}*`"
        id="card-cvv"
        name="card-cvv"
        autocomplete="cc-card-cvv2"
        counter
        :maxlength="maxlength"
        @blur="$validator.validate(`${label}`)"
        @input="updateCVV($event)"
        
    >
    <template v-slot:append>
        <HbIcon 
           custom-size="20"
           :color="errors.has('card_cvv') ? '#ff5252' : '#637381'"
           mdi-code="mdi-lock"                           
                                    
         />
        </template>
    </hb-text-field>
</template>

<script type="text/babel">
    import creditCardType from 'credit-card-type';
    import { mapGetters, mapMutations } from "vuex";
    import { notificationMixin } from  '../../mixins/notificationMixin';
    export default {
        name: "CardCVV",
        mixins: [ notificationMixin ],
        data: () => ({
            maxlength: 3,
            label: 'CVV',
            cvv:""
        }),
        destroyed() {
                  },
        created(){

        },
        methods: {
             ...mapMutations({
                setCheckErrors: 'paymentsStore/setCheckErrors',
                setPaymentMethod: 'paymentsStore/setPaymentMethod',
            }),

            updateCVV : _.debounce(async function(value) {
                this.setPaymentMethod({property: 'cvv2', propertyValue: this.cvv});
            }, 1000),

            validateCardCVV(){
                return this.$validator.validateAll();  ;
            }
        },
        computed: {
            ...mapGetters({
              notification:'charmCallStore/notification',
              getErrors: 'paymentsStore/getErrors',
              payment_method: 'paymentsStore/getPaymentMethod',
            }),
            card_number(){
                return this.payment_method.card_number || '';
            }
        },
        watch: {
            card_number() {
                if(this.card_number) {
                    let card_results = creditCardType(this.card_number);
                    if(card_results && card_results.length){
                        let card = card_results[0];
                        if(card.code){
                            this.label = `${card.code.name}`;
                            this.maxlength = card.code.size;
                        }
                    }
                }
            },
            method(val){
                if(val !== 'new'){
                    this.$validator.reset();  
                }
            }
        },
        props: ['method'],
    };
</script>

<style scoped>
::v-deep(.hb-text-field-wrapper .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner) {
  margin-top: 0px !important;
}
</style>