<template>
    <hb-modal v-model="dialog" show-help-link size="medium" :title=" type == 'sheet' ? 'Upload Spreadsheet' :'Upload Files'" :confirmation="type == 'sheet'" @close="$emit('close')">
        <template v-slot:content>
          <div class="px-5">
            <div v-if="type == 'sheet'" class="pt-5 pb-1">
                <span class="hb-text-night"> <span class="font-weight-medium">Note: </span>Make sure that the spreadsheet you are uploading is complete.</span>
            </div>
            <v-file-input multiple v-model="files" :placeholder="type == 'sheet' ? 'Select Spreadsheet': '' "></v-file-input>
            <div v-if="type == 'sheet'" class="my-n1 pb-6">
                <span v-if="true" class="hb-text-night"> Supported file types: <span class="font-weight-medium">.csv, .xls, or .xlsx</span></span>
            </div>
            <div v-if="file_types && file_types.length" class="my-n1 pb-6">
                <span class="hb-text-night"> Supported file types: <span class="font-weight-medium">{{ file_types.join(' ') }}</span></span>
            </div>
            <v-select
              v-if="multipleLeases && multipleLeases?.length > 0 && (!isNational || properties !== 'all-property')" 
              :items="multipleLeases"
              id="leases"
              name="leases"
              flat
              item-text="Unit.number"
              :item-value="isNational ? `unit_id` : `id`"
              label="Select Space"
              v-model="uploadSpace"
              v-validate="'required'"
              single-line
              hide-details
              data-vv-as="upload_space"
              class="pa-0 ma-0 pb-5">
            </v-select>
          </div>
        </template>
        <template v-slot:actions>
            <hb-btn @click="upload" :disabled="uploadInProgress" :loading="uploadInProgress" color="primary">Upload</hb-btn>
        </template>
      </hb-modal>
</template>

<script type="text/babel">

    import Status from '../includes/Messages.vue';
    import api from '../../assets/api';
    import { mapGetters } from 'vuex';
    import { notificationMixin } from  '@/mixins/notificationMixin.js';

    export default {
        name: 'UploadFile',
        data: function(){
           return {
               id: null,
               options: {
                   url: '',
                   uploadMultiple: false,
                   headers: {

                   }
               },
               files:[],
               filePreview: [],
               uploadSpace: '',
               uploadInProgress: false,
           }
        },
        mixins: [ notificationMixin ],
        components:{
            Status
        },
        computed: {
            ...mapGetters({
                getAuthHeader: 'authenticationStore/getAuthHeader'
            }),
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
        },
        created(){
            var paths = location.hostname.split('.');
            this.options.url = process.env.API_PORT + '://' + process.env.API_SUBDOMAIN + '.' + process.env.DOMAIN + ':' + process.env.API_PORT + '/v1/maintenance' + this.id;
            var subdomain = paths[0];
            this.options.headers = {
                "Authorization":  this.getAuthHeader,
                'Access-Control-Allow-Origin': 'https://' + subdomain +'.' + process.env.DOMAIN,
                'Referrer-Policy': 'origin-when-cross-origin'
            };

        },
        filers:{
            getFileName(file){
              let read = new FileReader();
                read.readAsBinaryString(file);
                return read.onloadend = function(){
                    return read.result;
                }
            }
        },
        methods:{ 
            async upload(){
                this.uploadInProgress = true;
                let path = '';
                switch(this.model){
                    case 'leases':
                        path += api.LEASES;
                        break;
                    case 'contacts':
                        path += api.CONTACTS;
                        break;
                    case 'units':
                        path += api.UNITS;
                        break;
                    case 'properties':
                        path += api.PROPERTIES;
                        break;
                    case 'accounting':
                        path += api.ACCOUNTING
                        break;
                    case 'national-accounts':
                        path += api.NATIONAL_ACCOUNT
                        break;
                    case 'uploads':
                        path += api.UPLOADS
                        break;
                    case 'payroll':
                        path += api.UPLOAD_PAYROLL

                }

                if(!this.files.length){
                    this.uploadInProgress = false;
                    this.showMessageNotification({type: 'error', description: "Please choose a file"});
                    return;
                }

                if(this.file_types && this.file_types.length){
                    for(let file of this.files){
                        let extension = file.name.split('.').pop();
                        if(!this.file_types.includes(extension)){
                            this.uploadInProgress = false;
                            this.showMessageNotification({type: 'error', description: "Unsupported file type."});
                            return;
                        }
                    }
                }

                if(this.multipleLeases && this.multipleLeases.length > 0  && (!this.isNational || this.properties !== 'all-property') ){
                    
                    if(!this.uploadSpace){
                        this.uploadInProgress = false;
                        this.showMessageNotification({type: 'error', description: "Please choose a space to upload this file."});
                        return;
                    }
                    if (this.isNational) {
                        path += this.foreign_id + '/upload';
                    } else {
                        path += this.uploadSpace + '/upload';
                    }
                } else {
                    if(this.model === 'accounting'){
                        path += 'upload-spreadsheet'
                    }
                    else if(this.model === 'uploads'){
                        path = path;
                    }
                    else if (this.model === 'payroll'){
                        path = path;
                    }
                    else{
                        path += this.foreign_id + '/upload';
                    }
                }

                let body = {
                    document_type: this.type,
                    document_type_id: this.document_type_id
                }

                if(this.model === 'uploads'){
                    body.model = 'document';
                    body.foreign_id = this.foreign_id ?? 'misc_uploads';
                }
                
                // only for national account doc
                if(this.model ==='national-accounts'){
                    body.space_id = this.uploadSpace
                    if(this.properties != 'all-property')body.properties = JSON.stringify(this.properties)
                }

                // only for national account doc
                if(this.model ==='national-accounts'){
                    body.space_id = this.uploadSpace
                    if(this.properties != 'all-property')body.properties = JSON.stringify(this.properties)
                }
                try {
                    let response = await api.postFile(this, path, body, this.files);
                    this.showMessageNotification({ type: 'success', description: "File has been successfully uploaded!" });
                    this.dialog = false;
                    this.files = [];
                    this.$emit('refetch');
                    this.$emit('close');
                    this.$emit('save', response);
                } catch (error) {
                    this.showMessageNotification({ type: 'error', description: error });
                } finally {
                    this.uploadInProgress = false;
                }
            },
            close(){
                this.$emit('close');
            }
        },
        props: {
            model:{
                required: true,
                type: String
            },
            foreign_id:{
                required: true,
                type: String
            },
            type:{
                type: String,
                required: false,
                default: null
            },
            document_type_id: {
                type: String,
                required: false,
                default: null
            },
            path:{
                type: String,
                required: false
            },
            file_types:{
                type: Array,
                required: false
            },
            upload_single:{
                type: Boolean,
                required: false
            },
            value:{
                type: Boolean,
                default: false
            },
            multipleLeases:{
                type: Array,
                required: false
            },
            isNational:{
                type: Boolean,
                required: false
            },
            properties:{
                type: String,
                required: false
            }
        }
    }

</script>

<style>
    .img-preview{
        float: left;
        padding: 20px;
        width: 100px;
        position: relative;
    }

    .file-details{
        margin-left: 80px;
        padding: 20px;
    }

    .file-progress{
        height: 2px;
        border-radius: 2px;
        overflow: hidden;
        margin: 6px 0;
        width: 100%;
        background-color: #e2e2e2;
        position:relative;

    }
    .progress-indicator{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        background-color: #00a1c8;
    }

    .uploader-file h5{
        color: #788f9b;
        font-size: 14px;
        margin: 0 0 10px 0;
        padding: 0;
        text-transform: none;
        font-weight: normal;
    }

    .img-preview img{
        max-width: 100%;

        border:1px solid #e2e2e2;
    }

    .drag-and-drop-area{
        min-height: 250px;
        text-align: center;
        border: 2px dashed #e2e2e2;
        padding: 15px 20px;
        margin-bottom: 20px;

    }
    .upload-text{
        margin-top: 60px;
        cursor: pointer;

    }
    .upload-icon{
        font-size: 36px;
        color: #00b2ce;
    }
    .underlined{
        padding-bototm: 3px;
        border-bottom: 1px solid #788f9b;
    }

    .drag-and-drop-area h4{
        color: #788f9b;
        font-weight: 300;
        font-size: 16px;
        text-transform: none;
        line-height: 24px;
        margin: 10px 0 0 0;

        padding: 0;

    }
    .file-status{
        text-transform: uppercase;
    }


    .hide-sm{
        display:block;
    }
    .show-sm{
        display:none;
    }
    @media (max-width: 767px) {

        .hide-sm{
            display:none;
        }
        .show-sm{
            display:block;

        }
    }


</style>
