<template>
  <div>
    <div v-for="(billingCycle, i) in billingCycles" :key="i">
      <v-row class="ma-0 pa-0">
        <v-col
          cols="12"
          class="ma-0 pa-0"
        >
          <div>{{ billingCycle.billing_cycle }}</div>
          <div :class="durationPeriodColor" class="hb-font-caption pb-2">
            {{ billingCycle.start_date | formatLocalShortDate }}
            <span v-if="billingCycle.end_date">- {{ billingCycle.end_date | formatLocalShortDate }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script type="text/babel">

export default {
  name: "BillingCycleInfo",
  props: {
    billingCycles: {
      type: Array
    },
    durationPeriodColor: {
      type: String,
      required: false,
      default: "hb-text-light",
    }
  },
};
</script>