import { render, staticRenderFns } from "./ForteAch.vue?vue&type=template&id=bf963660&scoped=true&"
import script from "./ForteAch.vue?vue&type=script&lang=js&"
export * from "./ForteAch.vue?vue&type=script&lang=js&"
import style0 from "./ForteAch.vue?vue&type=style&index=0&id=bf963660&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf963660",
  null
  
)

export default component.exports