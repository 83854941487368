<template>
  <div>
    <!-- Billing Cycle -->
    <hb-form 
      label="Billing Cycle"
      :empty="false"
      :full="true"
      :active.sync="active"
      :editable="hasPermission('edit_contact_billing_settings')"
      @save="showBillingCycleChangeModel"
      @cancel="closeBillingCycleChangeModal"
      v-if="isBillingCycleAllowed && billingProfile?.BillingCycleOptions?.length && !billingProfile?.payment_cycle"
    >
      <!-- Billing Cycle On Edit mode -->
      <template v-slot:edit>
        <div>
          <hb-select  
            :items="billingProfile?.BillingCycleOptions" 
            v-model="form.billing_cycle_id"
            id="billing_cycle"
            name="billing_cycle"
            item-value="billing_cycle_id"
            hide-details
            :clearable="false"
            placeholder="Select New Billing Cycle"
          >
            <template v-slot:selection="{ item }">
              {{ item.value | formatMonth }}
            </template>
            <template v-slot:item="{ item }">
              {{ item.value | formatMonth }}
            </template>
          </hb-select>
        </div>
      </template>
      <!-- Billing Cycle On Read mode -->
      <template v-slot:display>
        <div>
          {{ getCurrentBillingCycle }}
        </div>
      </template>
    </hb-form>
    <hb-modal
      size="medium"
      confirmation
      title="Change Billing Cycle"
      v-model="show_billing_cycle_change_model"
      @close="closeBillingCycleChangeModal"
      :showHelpLink="false"
    >
      <template v-slot:content>
        <span>
          <v-row class="px-7 py-3 hb-font-body">
            <div>You are about to change the default <span class="hb-font-body-medium hb-text-night">Billing Cycle</span> for the following spaces:</div>
          </v-row>
          <v-row class="px-7 pb-3">
            <hb-select
              v-model="form.billing_cycle_id"
              id="billing_cycle"
              name="billing_cycle"
              item-value="billing_cycle_id"
              :clearable="false"
              placeholder="Billing Cycle"
              :items="billingProfile?.BillingCycleOptions"
              @change="getEstimates"
            >
              <template v-slot:selection="{ item }">
                <span class="hb-font-body-medium hb-text-night">{{ item.value | formatMonth }}</span>
              </template>
              <template v-slot:item="{ item }">
                {{ item.value | formatMonth }}
              </template>
            </hb-select>
          </v-row>

          <v-row class="px-7 pb-3 hb-font-body">
            <span>*Select all spaces to be changed.</span>
          </v-row>

          <v-row class="px-7 pb-3 hb-font-body">
            <span>Preview and confirm the changes being made. For any space type where the selected Billing cycle is not applicable, the lease continues to be Monthly.</span>
          </v-row>

          <v-row
            v-if="loading"
            style="height: 200px;"
            align="center"
            justify="center"
            no-gutters
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
          <v-row
            class="pb-3 px-7"
            v-for="(lease, i) in selectAbleLeases"
            :key="'selectAbleLeases_' + i"
          >
            <v-row class="px-3 w-100">
              <hb-checkbox
                :value="selectAbleLeases[i].pay"
                v-model="selectAbleLeases[i].pay"
              ></hb-checkbox>
              <span class="hb-font-body-medium hb-text-night">Space {{ lease.Unit.number }}</span>
            </v-row>
            <v-row v-if="lease?.GeneratedInvoices?.length > 0" class="pl-11 pr-3 hb-lh-0 hb-text-light hb-font-body">
              <v-col cols="6" class="mx-0 px-0">Next Rent Due</v-col>
              <v-col cols="6" class="mx-0 px-0 text-right">{{lease?.GeneratedInvoices[0]?.due | formatLocalShortDate}}</v-col>
            </v-row>
            <v-expansion-panels
              class="hb-expansion-panel"
              multiple
              flat
            >
              <v-expansion-panel class="pl-8 hb-bill-day-panel">
                <v-expansion-panel-header>
                  <div class="hb-font-caption">
                    Details
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0 ma-0">
                  <div v-if="lease.CurrentInvoices?.length > 0 || lease.GeneratedInvoices?.length > 0">
                    <v-row v-if="lease.CurrentInvoices?.length > 0" class="ma-0 pa-0 hb-font-body">
                      <v-col cols="6" class="ma-0 pa-0 hb-font-caption-medium"> Current Invoice(s):</v-col>
                    </v-row>

                    <v-row
                      class="ma-0 pa-0 hb-font-body"
                      v-for="(invoice, i) in lease.CurrentInvoices"
                      :key="'invoice_' + i"
                    >
                      <v-row v-if="invoice.number" no-gutters class="invoice-line-row">
                        <v-col>
                          <span class="caption">Invoice #{{ invoice.number }}</span>
                        </v-col>
                      </v-row>

                      <div class="w-100">
                        <v-row
                          class="ma-0 pa-0 hb-font-body"
                          v-for="(lines, i) in invoice?.InvoiceLines"
                          :key="'invoiceLine' + i"
                        >
                          <v-col cols="6" class="ma-0 pa-0">
                              {{lines.Service.name}}
                              <span v-if="lines.Service.recurring === 1" style="font-size:10px;">({{  lines.start_date | formatDateServices}} - {{ lines.end_date | formatDateServices}})</span>
                            </v-col>
                            <v-col cols="6" class="ma-0 pa-0 text-right">
                              {{ (lines.cost * lines.qty) | formatMoney }}
                            </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0 hb-font-body">
                          <v-col cols="6" class="ma-0 pa-0">Discounts</v-col>
                          <v-col cols="6" class="ma-0 pa-0 text-right"
                            >({{ invoice.discounts | formatMoney }})
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0 hb-font-body">
                          <v-col cols="6" class="ma-0 pa-0">Tax</v-col>
                          <v-col cols="6" class="ma-0 pa-0 text-right"
                            >{{ invoice.total_tax | formatMoney }}
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0 pb-2 hb-font-body">
                          <v-col cols="6" class="ma-0 pa-0">Total:</v-col>
                          <v-col cols="6" class="ma-0 pa-0 text-right">
                            {{ (invoice.sub_total + invoice.total_tax - invoice.total_discounts) | formatMoney }}
                          </v-col>
                        </v-row>
                        <v-row v-if="invoice.total_payments" class="ma-0 pt-1 pa-0 hb-font-body">
                          <v-col cols="6" class="ma-0 pa-0">Previous Payments:</v-col>
                          <v-col cols="6" class="ma-0 pa-0 text-right">
                            {{ invoice.total_payments - credit_amount[invoice.id] | formatMoney }}
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0 hb-font-body">
                          <v-col cols="6" class="ma-0 pa-0">Billing Cycle Change Credit:</v-col>
                          <v-col cols="6" class="ma-0 pa-0 text-right">
                            {{ credit_amount[invoice.id] | formatMoney }}
                          </v-col>
                        </v-row>
                      </div>
                    </v-row>

                    <v-row v-if="lease?.GeneratedInvoices?.length > 0" class="ma-0 mt-2 pa-0 hb-font-body">
                      <v-col cols="6" class="ma-0 pa-0 hb-font-caption-medium"> Charge:</v-col>
                    </v-row>

                    <v-row
                      class="ma-0 pa-0 hb-font-body"
                      v-for="(invoice, i) in lease.GeneratedInvoices"
                      :key="'previous_invoice_' + i"
                    >
                      <div class="w-100">
                        <v-row
                          class="ma-0 pa-0 hb-font-body"
                          v-for="(lines, i) in invoice.InvoiceLines"
                          :key="'invoiceLine' + i"
                        >
                          <v-col cols="6" class="ma-0 pa-0">
                            {{lines.Service.name}}
                            <span v-if="lines.Service.recurring === 1" style="font-size:10px;">({{  lines.start_date | formatDateServices}} - {{ lines.end_date | formatDateServices}})</span>
                          </v-col>
                          <v-col cols="6" class="ma-0 pa-0 text-right">
                            {{ (lines.cost * lines.qty) | formatMoney }}
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0 hb-font-body">
                          <v-col cols="6" class="ma-0 pa-0">Discounts</v-col>
                          <v-col cols="6" class="ma-0 pa-0 text-right">
                            ({{ invoice.discounts | formatMoney }})
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0 hb-font-body">
                          <v-col cols="6" class="ma-0 pa-0">Tax</v-col>
                          <v-col cols="6" class="ma-0 pa-0 text-right">
                            {{ invoice.total_tax | formatMoney }}
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0 pb-2 hb-font-body">
                          <v-col cols="6" class="ma-0 pa-0">Total:</v-col>
                          <v-col cols="6" class="ma-0 pa-0 text-right">
                            {{ (invoice.sub_total + invoice.total_tax - invoice.total_discounts) | formatMoney }}
                          </v-col>
                        </v-row>

                        <v-row v-if="invoice.total_payments" class="ma-0 pt-1 pa-0 hb-font-body">
                          <v-col cols="6" class="ma-0 pa-0">Previous Payments:</v-col>
                          <v-col cols="6" class="ma-0 pa-0 text-right">
                            {{ invoice.total_payments | formatMoney }}
                          </v-col>
                        </v-row>
                      </div>
                    </v-row>

                    <v-row  class="ma-0 mt-2 pa-0 hb-font-body">
                      <v-col cols="6" class="ma-0 pa-0 hb-font-caption-medium"> Balance:</v-col>
                      <v-col cols="6" class="ma-0 pa-0 hb-font-caption-medium text-right">{{ balance_due[lease.id] | formatMoney }}</v-col>
                    </v-row>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-row class="w-100" v-if="i + 1 !== selectAbleLeases?.length">
              <v-col col="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-row>
        </span>
      </template>
      <template v-slot:left-actions v-if="selectAbleLeaseExist">
        <span>{{ selectedLeases.length }} of {{ selectAbleLeases.length }} Selected</span>
      </template>
      <template v-slot:right-actions>
        <hb-btn @click="updateBillingCycle" :loading="btnLoading" :color="shouldProcessPayment ? 'secondary' : 'primary'" :disabled="(selectAbleLeaseExist && selectedLeases?.length <= 0) || loading">{{ shouldProcessPayment ? 'Skip Payment' : 'Continue' }}</hb-btn>
        <hb-btn v-if="shouldProcessPayment" @click="processPayment" :loading="btnLoading" :disabled="selectedLeases?.length <= 0 || loading || btnLoading">Continue</hb-btn>
      </template>
    </hb-modal>
    <BillingProfileTakePaymentModal
      v-model="show_take_payment_modal"
      v-if="show_take_payment_modal"
      :contact="contact"
      :lease="selectedLeases[0]"
      :disable_payment="disable_payment"
      source="BILLING_PROFILE"
      @update="updateBillingCycle"
      @close="closeBillingCycleChangeModal"
    >
    </BillingProfileTakePaymentModal>
  </div>
</template>
<script>
import { EventBus } from '../../../EventBus.js';
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import BillingProfileTakePaymentModal from './BillingProfileTakePaymentModal.vue';
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "BillingCycle",
  props: ["contact", "editable", "form", "billingProfile"],
  mixins: [ notificationMixin ],
  data (){
    return {
      active: "",
      loading: false,
      show_billing_cycle_change_model: false,
      btnLoading: false,
      show_take_payment_modal: false,
      disable_payment: false,
      selectAbleLeases: [],
      credit_details: {}
    }
  },
  computed: {
    ...mapGetters({
      currentBillingProfile: 'billingProfileStore/currentBillingProfile',
      paymentMethods: 'billingProfileStore/paymentMethods',
      hasPermission: "authenticationStore/rolePermission",
      isBillingCycleAllowed: 'propertiesStore/isBillingCycleAllowed'
    }),
    getCurrentBillingCycle(){
      let billing_cycle = this.billingProfile?.BillingCycleOptions?.find( bco => bco.billing_cycle_id === this.form.billing_cycle_id );
      return billing_cycle?.value ? this.$options.filters.formatMonth(billing_cycle?.value) : 'Select New Billing Cycle';
    },
    selectedLeases(){
      return this.selectAbleLeases.filter(l => l.pay);
    },
    selectAbleLeaseExist(){
      return this.selectAbleLeases?.length > 0;
    },
    shouldProcessPayment(){
      return this.selectedLeases?.find(lease => lease?.CurrentInvoices?.length > 0 || lease?.GeneratedInvoices.length > 0);
    }
  },
  components: {
    BillingProfileTakePaymentModal
  },
  async created() {
    
  },
  methods: {
    ...mapActions({
      updateBillingProfiles: 'billingProfileStore/updateBillingProfiles',
      getBillingProfiles: 'billingProfileStore/getBillingProfiles',
    }),
    ...mapMutations({
      setLeases: 'paymentsStore/setLeases',
      setPaymentSource: 'paymentsStore/setPaymentSource',
      pushInvoices : 'paymentsStore/pushInvoices',
      setInvoicesObject : 'paymentsStore/setInvoicesObject',
      setLeaseProperty: 'paymentsStore/setLeaseProperty'
    }),

    async showBillingCycleChangeModel(){
      if(this.form.billing_cycle_id === this.billingProfile?.billing_cycle_id){
        this.showMessageNotification({type: 'error', description: 'Please select a different billing cycle to change.'});
        return;
      }
      this.getEstimates();
      this.show_billing_cycle_change_model = true;
    },
    closeBillingCycleChangeModal(){
      this.active = null;
      this.loading = false;
      this.btnLoading = false;
      this.show_billing_cycle_change_model = false;
      this.show_take_payment_modal = false;
      this.disable_payment = false;
      this.$emit('cancel')
    },
    processPayment(){
      this.btnLoading = true;
      this.setPaymentSource({ paymentSource: 'BILLING_PROFILE' });
      this.setLeases({leases: this.selectedLeases});

      let invoices = [];
      for (let i = 0; i < this.selectedLeases.length; i++) {
        let open_invoices = [];
        if(this.selectedLeases[i]?.OpenInvoices?.length > 0) open_invoices.push(...this.selectedLeases[i].OpenInvoices);
        if(this.selectedLeases[i]?.GeneratedInvoices?.length > 0) open_invoices.push(...this.selectedLeases[i].GeneratedInvoices);
        this.setLeaseProperty({ leaseIndex: i, property: 'OpenInvoices', propertyValue: open_invoices });
        invoices.push(...open_invoices);
      }
      this.setInvoicesObject({ invoices });
      this.show_take_payment_modal = true;
    },
    async getEstimates(){

      this.credit_amount = {};
      this.balance_due = {};
      this.selectAbleLeases = [];

      if(this.form.billing_cycle_id === this.billingProfile?.billing_cycle_id){
        return;
      }

      try {
        this.loading = true;
        let data = {...this.form, dryrun: true}

        let result = await this.updateBillingProfiles({contact: this.contact, data});
        this.selectAbleLeases = result?.billing_cycle_leases ;
        this.credit_amount = result?.credit_details?.reduce((acc, cr) => {  
          acc[cr.invoice_id] = (acc[cr.invoice_id] || 0) + cr.credit_amount;  
          return acc;  
        }, {});

        for (let i = 0; i < this.selectAbleLeases?.length; i++) {
          let lease = this.selectAbleLeases[i];
          this.balance_due[lease.id] = lease?.CurrentInvoices?.reduce((acc, op) => acc + op.balance, 0);
          this.balance_due[lease.id] = this.balance_due[lease.id] + lease?.GeneratedInvoices?.reduce((acc, op) => acc + op.balance, 0);
        }
        this.loading = false;
      } catch (error) {
        this.showMessageNotification({type: 'error', description: error});
      }
    },
    async updateBillingCycle(data = {}){

      this.btnLoading = true;
      this.disable_payment = true;

      data = {...this.form, ...data, dryrun: false}

      let lease_ids = this.selectedLeases?.map(l => l.id);
      data.lease_ids = lease_ids;

      try {
        await this.updateBillingProfiles({contact: this.contact, data});
        await this.getBillingProfiles({contact: this.contact});
        this.showMessageNotification({ type: 'success', description: 'Billing Cycle has been updated successfully.' });
        this.closeBillingCycleChangeModal();
        EventBus.$emit('reload_tenant_profile_leases');
      } catch (error) {
        this.btnLoading = false;
        this.loading = true;
        this.showMessageNotification({type: 'error', description: error});
        this.disable_payment = false;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  .w-100{
    width: 100%;
  }
  .hb-lh-0{
    line-height: 0px;
  }
  .hb-bill-day-panel {
    background-color: transparent !important;
  }
  button.v-expansion-panel-header, .theme--light.v-expansion-panels.hb-expansion-panel .v-expansion-panel--active > .v-expansion-panel-header{
    min-height: 30px !important;
  }
  .invoice-line-row {
    margin-bottom: 0;
  }
  .invoice-line-row .caption {
    color: #637381;
  }
</style>