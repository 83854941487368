<template>
  <div v-resize="adjustNotesActionBar">
    <v-alert
      dense
      outlined
      :value="error"
      dismissible
      v-model="error"
      @input="onAlertClosed"
      type="error">
      Please review fields
    </v-alert>
    <v-card flat>
        <v-card-title
          class="title"
          primary-title
          v-if="interactiveMode === undefined || !interactiveMode"
        >
        <span>Add Note</span>
        <v-row justify="end">
          <v-btn icon @click="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <!-- <div v-if="mailNotes === false" class="same-line-comp" ref="child2">
        <space-selection-dropdown :setSelection="setSelectionFlag" :mainViewOptions="spaceListViewOptions" @spaceSelectionChanged="onSelectedSpace" @setDefaultSpaceValue="onSelectedSpace">
                        </space-selection-dropdown>
      
      </div> -->
      <v-divider></v-divider>
      
       <v-card-text class="pl-0 py-0 pr-0 hide-overflow d-flex flex-column">
          <v-row class="pr-3 rich-text-editor">
            <v-col class="pa-0 pl-3" cols="12">
              <rich-text-editor 
                ref="richTextEditor"
                solo
                dense
                flat
                hide-details
                autofocus
                background-color="#FFFFFF"
                :prose-height="this.richTextEditorHeight > 0 && this.usageContext ? `${this.richTextEditorHeight}px`: `${textareaStyle}px`"
                v-model="content"
                placeholder="Compose a note..."
			/>
              <!-- <v-textarea
                solo
                dense
                flat
                hide-details
                autofocus
                background-color="#FFFFFF"
                :height="interactiveMode ? textareaStyle: ''"
                v-model="content"
                placeholder="Compose a note..."
              >
              </v-textarea> -->
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row v-if="mailNotes === false" no-gutters class="pl-3 pt-3">
            <v-col>
                <div class="hb-text-night hb-font-body-medium">
                  Pin Note
                </div>
            </v-col>
            <v-col>
              <hb-radio-group row v-model="pined">
                <hb-radio label="Yes" value="1"></hb-radio>
                <hb-radio label="No" value="0"></hb-radio>
              </hb-radio-group>
            </v-col>
          </v-row>
        <v-row v-if="errorCheck === true">
          <div class="py-2 px-3">
            <hb-checkbox v-model="notesModalResolvedCheckbox" label="Mark document as Resolved."></hb-checkbox>
          </div>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions ref="noteActions" class="py-3">

        <hb-menu v-if="mailNotes === false">
              <template v-slot:menu-activator>
                  <v-btn icon> <v-icon>mdi-paperclip</v-icon></v-btn>            
              </template>
                    <v-list>
                        <v-list-item class="pointer-cursor" @click="showFile = true">
                                          Upload
                        </v-list-item>
                         <v-list-item class="pointer-cursor" @click="openUploadDocs(0)">                                           
                                          Invoices                                           
                        </v-list-item>
                        <v-list-item class="pointer-cursor" @click="openUploadDocs(1)">                                           
                                          Receipts                                           
                        </v-list-item>
                        <v-list-item class="pointer-cursor" @click="openUploadDocs(2)">                                           
                                          Tenant Documents                                            
                        </v-list-item> 
                        <v-list-item class="pointer-cursor" @click="removeattachment()">                                           
                                          Remove Attachment                                           
                        </v-list-item>
                    </v-list>
        </hb-menu>
        <span v-if="this.attachments.length">{{this.attachments[0].name}}</span>
        <span v-else-if="this.existingDoc">{{docName()}}</span>
<!--        <v-btn icon> <v-icon>mdi-paperclip</v-icon> </v-btn>-->
<!--        <v-btn icon> <v-icon>mdi-plus</v-icon>  </v-btn>-->
        <v-spacer></v-spacer>
          <hb-link class="mr-2" @click="onClose">Cancel</hb-link>
          <hb-btn class="mr-2" color="primary" @click="SendNote">Save</hb-btn>
      </v-card-actions>
    </v-card>
    <AttachDocsUploads v-if="openUploadDocsStatus" @close="updateUpload" :uploadDocumentAttachments="uploadDocumentAttachments" :attachSignLink="attachSignLink" :contactid="contactId" :propertyid="propertyid" :leases="leases" :selectedTypeOfDoc="selectedTypeOfDoc"></AttachDocsUploads>
    <add-file
      :show="showFile"
      :onClose='onFileClose'
      :getAttachments="getAttachments" 
    >
    </add-file>
  </div>
</template>

<script>
import api from '../../assets/api.js';
import { EventBus } from '../../EventBus.js';
import RichTextEditor from '../assets/RichTextEditor.vue';
import addFile from './AddFile';
import AttachDocsUploads from './UploadAttach.vue'
import SpaceSelectionDropdown from '../settings/spaceSelectionDropdown/spaceSelectionDropdown.vue';
import { stickBottomActionBarToBottom } from '../../utils/common.js';

export default {
  name:'AddNote',
  data () {
    return {
      richTextEditorHeight: 0,
      pined: '0',
      existingDoc:"",
      showFile: false,
      attachments:[],   
      selectedTypeOfDoc:null,
      openUploadDocsStatus:false,
      content: '',
      error:false,
      textareaStyle: '',
      notesModalResolvedCheckbox: null,
      noteInteractionId: '',
      // setSelectionFlag:false,
      // notesCategoryId:''
    }
  },
  components: {
    'rich-text-editor': RichTextEditor,
    'add-file': addFile,
    'AttachDocsUploads': AttachDocsUploads,
    SpaceSelectionDropdown
  },
  props: ['contactId','onClose','interactiveMode','textarea_height', 'context','onChatError','notesCategoryId','leases','propertyid','errorCheck','mailNotes', 'noteInteractionId','spaceListViewOptions','noteCategoryRef','usageContext','spaceListView'],
  // watch:{
  //   immediate: true,
  //   notesCategoryObj: {
  //     handler:function(newValue,oldValue){
  //     console.log('notes category value in watcher',newValue,oldValue);
  //     this.notesCategoryId = newValue
  //   },
  //   deep:true
  // }
  // },
  mounted() {
    this.adjustNotesActionBar()
    EventBus.$on('onInteraction', this.adjustNotesActionBar) 
    // to get ref and set style when rendered
    // this.$nextTick(() => {
    //   console.log("mounted");
    //   const child1Rect = this.noteCategoryRef.getBoundingClientRect()
    //   const child2 = this.$refs.child2;
    //   console.log("mounted",child1Rect.top,child1Rect.left,child2);

    //   // // Set the position of child2 relative to child1
    //   child2.style.position = 'absolute';
    //   child2.style.top = -60 + 'px'; // Set the top position based on child1's position
    //   child2.style.left = child1Rect.left + 'px'; // Set the left position based on child1's position
    // });
  },
  created () {
    this.textareaStyle = this.$props.textarea_height + 50
    // this.spaceListView =  this.spaceListViewOptions[1] ?? this.spaceListViewOptions[0]
    // this.setSelectionFlag = this.spaceListViewOptions.length > 1
    this.$emit('updateNoteCategories','removeAllNote')
  },
    beforeDestroy(){
        this.onChatError('')
        EventBus.$off('onInteraction')
    },
  methods: {
    adjustNotesActionBar(){
      this.$nextTick(() => {
        const noteActions = this.$refs.noteActions
        const richTextEditor = this.$refs.richTextEditor.$refs.editorContainer
        this.richTextEditorHeight = stickBottomActionBarToBottom(richTextEditor,noteActions)
      })
    },
    docName(){
      if(this.existingDoc.src){
     let docname =  this.existingDoc.name ? this.existingDoc.name : this.existingDoc.DocumentType.name
      const parts = this.existingDoc.src.split(".");
      const fileExtension = parts.length > 1 ? parts.pop().toLowerCase() : ''   
          if (fileExtension.length<5) {
            if(!docname.endsWith(`.${fileExtension}`)){
                return  `${docname}.${fileExtension}`
            }else{
               return docname
               }
          } else {
            return docname
          }
        }else{
          let fileArray = []
          fileArray.push(this.existingDoc)
          this.attachments = fileArray;
          return this.existingDoc.name
        }
    },
    removeattachment(){
      this.attachments=[]
      this.existingDoc=""
    },
    openUploadDocs(x){
      console.log(x);
         this.openUploadDocsStatus = true
         this.selectedTypeOfDoc = x
    },
    updateUpload(){
         this.openUploadDocsStatus = false
    },
    onFileClose () {
      this.showFile = false
    },
    getAttachments (attachments) {
      this.removeattachment()
      this.attachments = attachments;
      console.log(this.attachments)
      this.onFileClose();
    },    
    attachSignLink(item){
      this.content += 'Please click the link to sign your documents '+ item +'.';
      console.log(item,"itemUrl");
      this.openUploadDocsStatus= false
    },
    uploadDocumentAttachments(doc){
      this.removeattachment()
      this.existingDoc = doc
      this.openUploadDocsStatus= false
    },
    async upload () {
            if(this.attachments.length){
        let body = {
          document_type: this.type,
          document_type_id: this.document_type_id
      }
      let response = await api.postFile(this,  api.CONTACTS + this.contactId + '/upload', body, this.attachments);
      return response
      }else{
      return {
        uploads:{
              id:this.existingDoc.id,
              mimetype:this.existingDoc.mimetype
        }
      }
      }
    },
    onAlertClosed(value) {
      // 'value' will be false if the alert is closed
      if (!value) {
        const child2 = this.$refs.child2;
          child2.style.top = '-56px' 
        console.log('spaceListView',this.spaceListView);
        // console.log('Alert closed');
        // Perform actions upon alert closure
      }
    },
    async SendNote () {
      // console.log('spaceListView',this.spaceListView);
      
      console.log(this.notesModalResolvedCheckbox, 'testtt')
      if (this.notesModalResolvedCheckbox === true) {
        let dataResolved = {
          resolved: this.notesModalResolvedCheckbox ? true : false
        };
        await api.put(this, api.INTERACTIONS + this.noteInteractionId, dataResolved);

      }
      if (this.content.trim() !== '' && typeof this.notesCategoryId !== 'string' && this.mailNotes == false) {
        // var data = {
        //     content: this.content,
        //     pinned: this.pined,
        //     attachments: this.attachments,
        //     notes_category_id:this.notesCategoryId[0].id
        // }
          try {
            var data = {
                  content: this.content,
                  pinned: this.pined,
                  notes_category_id: this.notesCategoryId[0].id,
                  property_id: this.spaceListView.Unit.property_id,
                  unit_number: this.spaceListView.Unit.number,
                  attachments: this.attachments,

              }

            if (this.attachments.length || this.existingDoc) {
            let fileUploadResponse = await this.upload();
            data.upload_id = fileUploadResponse.uploads.id
            if(this.attachments.length){
              data.attachments[0].content_type = fileUploadResponse.uploads.mimetype
            } else{
              data.src = this.existingDoc.src
              data.docName= this.docName()
              data.attachments = [
                {
                  "content_type":fileUploadResponse.uploads.mimetype ? fileUploadResponse.uploads.mimetype : "application/pdf"
                }
              ]
            }  
        } 
              let note = await api.post(this, api.CONTACTS + this.contactId + '/notes', data)
              data.contact_id = this.contactId;
              data.id = note.id;
              if(data.pinned){
                  EventBus.$emit('pinned_interaction', data);
              }
              this.$emit('updateNoteCategories','addAllNote')
              this.onClose(true);
          } catch(err) {
              this.onChatError('An error occurred. Could not save phone call info');
          }
      }else if(this.mailNotes == true){
        var payload = {
          content: this.content,
        }
        api.post(this, api.INTERACTIONS + this.noteInteractionId + '/notes', payload)
        this.onClose(true);
      } 
      else {
        this.error = true
        const child2 = this.$refs.child2;
        child2.style.top = '-114px' 
      }
    },
    // onSelectedSpace(spaceNumber){
      // this.spaceListView = spaceNumber
    // }
  },
  
}
</script>


<style scoped>
.rich-text-editor {
  overflow-y: auto;
}
.label-div {
  background-color: #F9FAFB;
  height: 48px;
  color: #101318;
}
.hide-overflow {
  overflow: hidden;
}
.same-line-comp{
  position: absolute;
  top:-48px;
  left: 270px;
  width:calc(100% - 82%);
  min-width: 130px;
}
</style>
