<template>
    <div class="pa-4">
        <v-row class="mx-0" v-if="!isInteractionActive && !isFetchingNotes">
            <v-col md="12">
                <hb-btn color="primary" small class="float-right" @click="isInteractionActive = true">
                    <hb-icon small color="#FFFFFF">mdi-plus</hb-icon>
                </hb-btn>
            </v-col>
        </v-row>
        <v-row class="interaction-action-bar mx-0 py-1 compose-title-bar" v-if="isInteractionActive">
            <v-col cols="12" class="pa-0">
                <div class="font-weight-medium subtitle-1 ml-4 d-flex align-center">
                    <span><v-icon size="24" style="vertical-align: middle">mdi-content-paste</v-icon>Add Note</span>
                    <v-spacer></v-spacer>
                    <span class="pr-1">
                        <hb-btn icon tooltip="Close" @click="onNoteClose">mdi-close</hb-btn>
                    </span>
                </div>
            </v-col>
        </v-row>

        <v-alert
            dense
            outlined
            :value="error"
            dismissible
            v-model="error"
            @input="onAlertClosed"
            type="error"
            class="mt-3"
        >
            Please review fields
        </v-alert>
        <div v-if="isInteractionActive">
            <v-divider></v-divider>

            <v-card-text class="pl-0 py-0 pr-0 hide-overflow d-flex flex-column">
                <v-row class="pr-3 rich-text-editor">
                    <v-col class="pa-0 pl-3" cols="12">
                    <rich-text-editor 
                        ref="richTextEditor"
                        solo
                        dense
                        flat
                        hide-details
                        autofocus
                        background-color="#FFFFFF"
                        :prose-height="this.richTextEditorHeight > 0 ? `${this.richTextEditorHeight}px`: `${textareaStyle}px`"
                        v-model="content"
                        placeholder="Compose a note..."
                    />
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters class="pl-3 pt-3">
                    <v-col>
                        <div class="hb-text-night hb-font-body-medium">
                        Pin Note
                        </div>
                    </v-col>
                    <v-col>
                    <hb-radio-group row v-model="pined">
                        <hb-radio label="Yes" value="1"></hb-radio>
                        <hb-radio label="No" value="0"></hb-radio>
                    </hb-radio-group>
                    </v-col>
                </v-row>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions ref="noteActions" class="py-3">
                <v-spacer></v-spacer>
                <hb-link class="mr-2" @click="onNoteClose">Cancel</hb-link>
                <hb-btn class="mr-2" color="primary" @click="addSpaceNote" :disabled="isLoading(this.$options.name)" :loading="isLoading(this.$options.name)">Save</hb-btn>
            </v-card-actions>
        </div>
        <div class="pa-0 ma-0">
            <v-row no-gutters>
                <v-col v-if="!isInteractionActive" class="pb-0 px-3">
                <div ref="chatList" class="results-container" @scroll.self="fetchOnScroll">
                    <v-card elevation="0" style="background-color: #F9FAFB;" class="mx-auto" tile>
                    <v-list dense class="pt-0 pb-0">
                        <div
                            no-gutters
                            v-for="(r, i) in spaceNotesListRefresh"
                            :key="'multiple_sync_' + i"
                            :id="`interaction_id_${i}`"
                            class="mb-2 justify-center position-relative"
                            >
                            <hb-communication
                                :class="{'isPinned':r.pinned === 1 ? true : false}"
                                :active="i == activeIndex"
                                :elevation="i == activeIndex"
                                :expanded.sync="r.expanded"
                                :type="hbCommunicationData(r)?.type"
                                :time="hbDateFormat(r.created)"
                                :pinned="r.pinned === 1 ? true : false"
                                :admin-name="hbCommunicationData(r)?.adminName"
                                :hide-resend-button="true"
                                @pinned-icon-clicked="changePinStatus(r,i)"
                                @click="markStatus(r, i)"
                            >
                                <div v-html="r.content" class="chat-window-hb-communication-default-slot-wrapper"></div>
                            </hb-communication>
                        </div>
                    <v-row class="mx-0" v-if="!isFetchingNotes && !spaceNotesList.length">
                        <v-col md="12" class="pa-0">
                            <hb-empty-state
                                message="No space notes found"
                            />
                        </v-col>
                    </v-row>
                    <v-progress-circular
                        v-if="isFetchingNotes"
                        class="async-loader mt-10"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                    </v-list>
                    </v-card>
                </div>
                </v-col>
            </v-row>
            </div>
    </div>

</template>

<script type="text/babel">
import api from '../../assets/api.js';
import RichTextEditor from '../assets/RichTextEditor.vue';
import Loader from '../assets/CircleSpinner.vue';
import moment from 'moment';
    export default {
        name: "UnitNotes",
        data() {
            return {
                spaceNotesList: [],
                isInteractionActive: false,
                error: false,
                richTextEditorHeight: 0,
                textAreaHeight: 82,
                textareaStyle: '',
                content: '',
                pined: '0',
                activeIndex: 0,
                isFetchingNotes: false,
                isAllNotesFetched: false,
                isChangingPinStatus: false,
            }
        },
        components:{
            'rich-text-editor': RichTextEditor,
            'loader' : Loader
        },
        props: ['unit'],
        created(){
            this.textareaStyle = this.textAreaHeight + 50
            this.fetchData();
        },
        computed: {
            interactionsCount () {
                return this.spaceNotesList.length;
            },
            spaceNotesListRefresh() {
                return this.spaceNotesList.map((r) => {
                    return r;
                })
            },
        },
        methods:{
            fetchData(refresh){
                this.isFetchingNotes = true;
                let offset = this.spaceNotesList.length;
                let limit = 10;
                if(refresh) {
                    offset = 0;
                    limit = this.spaceNotesList.length;
                }
                let path = `/notes?limit=${limit}&offset=${offset}`;
                api.get(this, api.UNITS + this.unit.id + path).then(r => {
                    if(refresh) {
                        this.spaceNotesList = [];
                    }
                    if(r.Notes.length) {
                        this.spaceNotesList = [...this.spaceNotesList, ...r.Notes];
                        this.spaceNotesList = this.sortAndFormatChatList(this.spaceNotesList);
                    }
                    this.isAllNotesFetched = r?.Notes.length ? false : true;
                    this.isFetchingNotes = false;
                });
            },
            async fetchOnScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
                  if (scrollTop + clientHeight >= scrollHeight) {
                    if((this.spaceNotesList.length%10) === 0 && !this.isAllNotesFetched && !this.isLoading(this.$options.name)) {
                        await this.fetchData()
                    }
                }
            },
            async changePinStatus(r, index){
                if(!this.isLoading(this.$options.name) && !this.isChangingPinStatus) {
                    this.isChangingPinStatus = true;
                    let data = {
                        pinned: (r.pinned) ? 0 : 1,
                    }
                    r.pinned = (r.pinned) ? 0 : 1;
                    let filteredChatListSorted = this.spaceNotesList;
                    this.spaceNotesList = []
                    this.spaceNotesList = this.sortAndFormatChatList(filteredChatListSorted);
                    await api.put(this, api.NOTES + '/' + r.id, data).then(r => {
                    }).catch(error => {
                        console.log(error);
                    }).finally(() => {
                        this.isChangingPinStatus = false;
                    });
                }
            },
            sortAndFormatChatList (interactionList) {
                this.activeIndex = 0;
                let notelist = interactionList.filter(x=> x.pinned === 1).sort((a,b)=> new Date(b.created) - new Date(a.created));
                let otherChat = interactionList.filter(x=> x.pinned !== 1)
                otherChat = otherChat.filter(x=> x.read !== 0).sort((a,b)=> new Date(b.created) - new Date(a.created));
                let finalChat = [];
                finalChat = finalChat.concat(notelist);
                finalChat = finalChat.concat(otherChat);
                finalChat = finalChat.map(item => {
                    return { ...item, expanded: false }
                })
                return finalChat;
            },
            async addSpaceNote () {
                if (this.content.trim() !== '') {
                    try {
                        var data = {
                            content: this.content,
                            pinned: this.pined,
                            property_id: this.unit.property_id,
                            unit_id: this.unit.id,
                        }
                        await api.post(this, api.UNITS + this.unit.id + '/notes', data);
                        this.spaceNotesList = [];
                        this.fetchData();
                        this.isInteractionActive = false;
                        this.error = false;
                        this.onNoteClose();
                    } catch(err) {
                        console.log(err);
                    }
                }
                else {
                    this.error = true;
                }
            },
            markStatus (messageObj, i) {
              this.activeIndex = i;
            },
            onNoteClose (reloadData) {
                this.content = '';
                this.pined = '0';
                this.isInteractionActive = false;
                this.error = false;
            },
            onAlertClosed(value) {
                this.error = false;
            },
            hbCommunicationData(r) {
                return {
                    type: 'note',
                    adminName: (r.first || '') + ( r.last ? ' ' + r.last : '')
                };
            },
            hbDateFormat(dateTime) {
                const daysDiff = moment.utc(dateTime).local().calendar();
                const targetDate = moment.utc(dateTime).local();
                if (daysDiff.split(' ')[0] === 'Today') {
                    return `Today, ${targetDate.format('h:mma')}`;
                } else if (daysDiff.split(' ')[0] === 'Yesterday') {
                    return `Yesterday, ${targetDate.format('h:mma')}`;
                } else {
                    return targetDate.format('MMM D, YYYY @ h:mma');
                }
            },
        }
    }
</script>

<style scoped>
    .interaction-action-bar,
    .interaction-action-bar i{
        background: #E0F5F5!important;
    }
        .interaction-action-bar,
    .compose-title-bar {
        height: 48px;
        background: #E0F5F5;
    }

    .interaction-action-bar,
    .compose-title-bar {
        height: 48px;
        background: #E0F5F5;
    }
    .isPinned >>> .hb-communication-background{
        background: #FFF9DA!important;
    }
    .results-container{
      max-height: 37svh;
      overflow: auto;
    }
    .async-loader{
        top: 50%;
        left: 50%;
        -ms-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }
</style>
