<template>
  <div>
    <!-- Billing Cycle change model -->
    <hb-modal
      v-model="dialog"
      title="Change Billing Cycle"
      size="medium"
      confirmation
      @close="cancelBillingCycleChange"
      show-help-link
    >
      <template v-slot:content>
        <div class="px-6 pt-4">You are about to change the default <span class="hb-font-body-medium hb-text-night">Billing Cycle</span> for the following space:</div>
        <v-row
          v-if="billingPeriodLoading"
          style="height: 50px;"
          align="center"
          justify="center"
          no-gutters
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <div v-else-if="billing_cycle_id" class="px-6 pt-4">
          <hb-radio-group v-model="billing_cycle_period" @change="getEstimates">
            <hb-radio
              label="Current Billing Period"
              value="current"
              v-if="startFromCurrentPeriod"
            ></hb-radio>

            <span v-if="billingPeriods.future === undefined || !billingPeriods.future" >
              <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>
            <hb-radio v-else
              :label="`Next Billing Period: ${billingPeriods.future && $options.filters.formatDateServices(
                billingPeriods.future[0].start
              )} - ${billingPeriods.future && $options.filters.formatDateServices(billingPeriods.future[0].end)}`"
              value="next"
            ></hb-radio>
          </hb-radio-group>
        </div>
        <div class="px-6">
          <hb-select  
            :items="lease?.BillingCycleOptions" 
            v-model="billing_cycle_id"
            id="billing_cycle"
            name="billing_cycle"
            item-value="billing_cycle_id"
            :clearable="false"
            label="Billing Cycle"
            @change="getEstimates"
          >
            <template v-slot:selection="{ item }">
              <span class="hb-font-body-medium hb-text-night">{{ item.value | formatMonth }}</span>
            </template>
            <template v-slot:item="{ item }">
              {{ item.value | formatMonth }}
            </template>
          </hb-select>
        </div>
        <div class="px-6 pt-2">
          Preview and confirm the changes being made.
        </div>
        <div class="px-6 pt-2">
          <span class="hb-font-body-medium hb-text-night">Space {{ lease.Unit.number }}</span>
        </div>
        <v-row
          v-if="loading"
          style="height: 200px;"
          align="center"
          justify="center"
          no-gutters
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <div class="px-6" v-else-if="balance_due > 0">
          <v-row v-if="generatedInvoices?.length > 0" class="pl-3 pr-3 hb-lh-0 hb-text-light hb-font-body">
            <v-col cols="6" class="mx-0 px-0">Next Rent Due</v-col>
            <v-col cols="6" class="mx-0 px-0 text-right">{{ generatedInvoices[0].due | formatLocalShortDate }}</v-col>
          </v-row>
          <v-expansion-panels
            class="hb-expansion-panel"
            multiple
            flat
          >
            <v-expansion-panel class="hb-bill-cycle-panel">
              <v-expansion-panel-header>
                <div class="hb-font-caption">
                  Details
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 ma-0">
                <v-row v-if="currentInvoices?.length > 0" class="ma-0 pa-0 hb-font-body">
                  <v-col cols="6" class="ma-0 pa-0 hb-font-caption-medium"> Current Invoice(s):</v-col>
                </v-row>
                
                <v-row
                  class="ma-0 pa-0 hb-font-body"
                  v-for="(invoice, i) in currentInvoices"
                  :key="'invoice_' + i"
                >
                  <div class="w-100">
                    <v-row v-if="invoice.number" no-gutters class="invoice-line-row">
                      <v-col>
                        <span class="caption">Invoice #{{ invoice.number }}</span>
                      </v-col>
                    </v-row>
                    <v-row
                      class="ma-0 pa-0 hb-font-body"
                      v-for="(lines, i) in invoice.InvoiceLines"
                      :key="'invoiceLine' + i"
                    >
                      <v-col cols="6" class="ma-0 pa-0">
                        {{lines.Service.name}}
                        <span v-if="lines.Service.recurring === 1" style="font-size:10px;">({{  lines.start_date | formatDateServices}} - {{ lines.end_date | formatDateServices}})</span>
                      </v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right">
                        {{ (lines.cost * lines.qty) | formatMoney }}
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0 hb-font-body">
                      <v-col cols="6" class="ma-0 pa-0">Discounts</v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right"
                        >({{ invoice.discounts | formatMoney }})
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0 hb-font-body">
                      <v-col cols="6" class="ma-0 pa-0">Tax</v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right"
                        >{{ invoice.total_tax | formatMoney }}
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0 pb-2 hb-font-body">
                      <v-col cols="6" class="ma-0 pa-0">Total:</v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right">
                        {{ (invoice.sub_total + invoice.total_tax - invoice.total_discounts) | formatMoney }}
                      </v-col>
                    </v-row>
                    <v-row v-if="invoice.total_payments" class="ma-0 pt-1 pa-0 hb-font-body">
                      <v-col cols="6" class="ma-0 pa-0">Previous Payments:</v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right">
                        {{ invoice.total_payments - credit_amount[invoice.id] | formatMoney }}
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0 hb-font-body">
                      <v-col cols="6" class="ma-0 pa-0">Billing Cycle Change Credit:</v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right">
                        {{ credit_amount[invoice.id] | formatMoney }}
                      </v-col>
                    </v-row>
                  </div>
                </v-row>

                <v-row v-if="generatedInvoices?.length > 0" class="ma-0 mt-2 pa-0 hb-font-body">
                  <v-col cols="6" class="ma-0 pa-0 hb-font-caption-medium"> Charge:</v-col>
                </v-row>

                <v-row
                  class="ma-0 pa-0 hb-font-body"
                  v-for="(invoice, i) in generatedInvoices"
                  :key="'previous_invoice_' + i"
                >
                  <div class="w-100">
                    <v-row
                      class="ma-0 pa-0 hb-font-body"
                      v-for="(lines, i) in invoice.InvoiceLines"
                      :key="'invoiceLine' + i"
                    >
                      <v-col cols="6" class="ma-0 pa-0">
                        {{lines.Service.name}}
                        <span v-if="lines.Service.recurring === 1" style="font-size:10px;">({{  lines.start_date | formatDateServices}} - {{ lines.end_date | formatDateServices}})</span>
                      </v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right">
                        {{ (lines.cost * lines.qty) | formatMoney }}
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0 hb-font-body">
                      <v-col cols="6" class="ma-0 pa-0">Discounts</v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right">
                        ({{ invoice.discounts | formatMoney }})
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0 hb-font-body">
                      <v-col cols="6" class="ma-0 pa-0">Tax</v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right">
                        {{ invoice.total_tax | formatMoney }}
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0 pb-2 hb-font-body">
                      <v-col cols="6" class="ma-0 pa-0">Total:</v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right">
                        {{ (invoice.sub_total + invoice.total_tax - invoice.total_discounts) | formatMoney }}
                      </v-col>
                    </v-row>

                    <v-row v-if="invoice.total_payments" class="ma-0 pt-1 pa-0 hb-font-body">
                      <v-col cols="6" class="ma-0 pa-0">Previous Payments:</v-col>
                      <v-col cols="6" class="ma-0 pa-0 text-right">
                        {{ invoice.total_payments | formatMoney }}
                      </v-col>
                    </v-row>
                  </div>
                </v-row>

                <v-row  class="ma-0 mt-2 pa-0 hb-font-body" v-if="balance_due > 0">
                  <v-col cols="6" class="ma-0 pa-0 hb-font-caption-medium"> Balance:</v-col>
                  <v-col cols="6" class="ma-0 pa-0 hb-font-caption-medium text-right">{{ balance_due | formatMoney }}</v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </template>
      <template v-slot:right-actions>
        <hb-btn :loading="loading || loadingBtn" :color="balance_due > 0 ? 'secondary' : 'primary'" @click="saveBillingCycle" :disabled="!validBillingCycle || loading || loadingBtn">{{ balance_due > 0 ? 'Skip Payment' : 'Continue' }}</hb-btn>
        <hb-btn v-if="balance_due > 0" color="primary" @click="processPayment" :loading="loading || loadingBtn" :disabled="!validBillingCycle || loading || loadingBtn">Continue</hb-btn>
      </template>
    </hb-modal>

    <TakePaymentModal
      v-model="show_take_payment_modal"
      v-if="show_take_payment_modal"
      :contact="contact"
      :lease="lease"
      :disable_payment="disable_payment"
      :source="BILLING_CYCLE"
      @update="saveBillingCycle"
      @close="cancelBillingCycleChange"
    >
    </TakePaymentModal>
  </div>
</template>
<script>
import moment from "moment";
import { EventBus } from '../../../EventBus.js';
import LEASE from "@/constants/lease.js";
import api from "../../../assets/api.js";
import Loader from '../../assets/CircleSpinner.vue';
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import { mapGetters, mapMutations } from "vuex";
import TakePaymentModal from './TakePaymentModal.vue';

export default {
  name: "BillingCycleConversionModal",
  props: ["value", "lease", "contact", "new_billing_cycle_id"],
  mixins: [ notificationMixin ],
  data (){
    return {
      active: "",
      billing_cycle_id: null,
      loading: false,
      loadingBtn: false,
      billingPeriodLoading: false,
      show_take_payment_modal: false,
      disable_payment: false,
      BILLING_CYCLE: LEASE.PAYMENT_SOURCE.BILLING_CYCLE,
      billing_cycle_period: 'current',
      credit_amount: {},
      balance_due: 0,
      billingPeriods: {},
      currentInvoices: [],
      generatedInvoices: [],
      openInvoices: []
    }
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    startFromCurrentPeriod(){
      let startFromNext = moment(this.lease?.Metrics?.paid_through_date).isSameOrAfter(moment(this.billingPeriods?.current?.end));
      if(startFromNext){
        this.billing_cycle_period = 'next';
      }
      return !startFromNext;
    },
    validBillingCycle(){
      return this.lease?.BillingCycleOptions?.find(bco => bco.billing_cycle_id === this.billing_cycle_id);
    }
  },
  components: {
    Loader,
    TakePaymentModal
  },
  async created() {
    if(this.new_billing_cycle_id){
      this.billing_cycle_id = this.new_billing_cycle_id;
      this.getEstimates();
    }
  },
  methods: {
    ...mapMutations({
      setLeases: 'paymentsStore/setLeases',
      setPaymentSource: 'paymentsStore/setPaymentSource',
      pushInvoices : 'paymentsStore/pushInvoices',
      setInvoicesObject : 'paymentsStore/setInvoicesObject',
      resetPayments: 'paymentsStore/resetPayments',
      setLeaseProperty: 'paymentsStore/setLeaseProperty'
    }),
    async getEstimates(){

      this.credit_amount = {};
      this.balance_due = 0;
      this.generatedInvoices = [];
      this.currentInvoices = [];

      if(this.billing_cycle_id){
        this.fetchNextBillingPeriods();
      }

      if(!this.validBillingCycle){
        return;
      }

      try {
        this.loading = true;
        let data = {billing_cycle_period: this.billing_cycle_period, dryrun: true};

        if(this.validBillingCycle){
          data.new_billing_cycle_id = this.billing_cycle_id
        }

        if(this.billing_cycle_period === 'next'){
          data.start_date = this.billingPeriods.future[0].start;
        }

        let result = await this.changeBillingCycle(data);

        let {GeneratedInvoices, CurrentInvoices, OpenInvoices } = result?.lease;
        this.generatedInvoices = GeneratedInvoices;
        this.currentInvoices = CurrentInvoices;
        this.openInvoices = OpenInvoices;

        this.credit_amount = result?.credit_details?.reduce((acc, cr) => {  
          acc[cr.invoice_id] = (acc[cr.invoice_id] || 0) + cr.credit_amount;  
          return acc;  
        }, {});

        this.balance_due = this.currentInvoices?.reduce((acc, op) => acc + op.balance, 0) || 0;
        this.balance_due = this.balance_due + (this.generatedInvoices?.reduce((acc, op) => acc + op.balance, 0) || 0);
        this.loading = false;
      } catch (error) {
        this.showMessageNotification({type: 'error', description: error});
      }
    },
    async saveBillingCycle(data = {}){

      data = {...data, billing_cycle_period: this.billing_cycle_period, dryrun: false};

      if(this.validBillingCycle){
        data.new_billing_cycle_id = this.billing_cycle_id
      }

      if(this.billing_cycle_period === 'next'){
        data.start_date = this.billingPeriods.future[0].start;
      }

      this.loadingBtn = true;
      this.disable_payment = true;

      try {
        await this.changeBillingCycle(data);
        this.showMessageNotification({ type: 'success', description: 'Billing Cycle has been updated successfully.' });
        this.cancelBillingCycleChange();
        this.disable_payment = false;
        this.loadingBtn = false;
        EventBus.$emit('reload_tenant_profile_leases');
      } catch (error) {
        this.loadingBtn = false;
        this.showMessageNotification({type: 'error', description: error});
        this.disable_payment = false;
      }
    },
    processPayment(){
      this.loading = true;
      this.setPaymentSource({ paymentSource: this.BILLING_CYCLE });
      this.setLeases({leases: [this.lease]});
      let invoices = [];
      invoices.push(...this.openInvoices);
      invoices.push(...this.generatedInvoices);
      this.setLeaseProperty({ leaseIndex: 0, property: 'OpenInvoices', propertyValue: invoices });

      this.setInvoicesObject({ invoices });
      this.show_take_payment_modal = true;
    },
    async changeBillingCycle(data){
      return await api.put(this,`${api.LEASES}${this.lease.id}/change-billing-cycle`, data);
    },

    cancelBillingCycleChange(){
      this.active = null;
      this.disable_payment = false;
      this.loading = false;
      this.loadingBtn = false;
      this.show_take_payment_modal = false;
      this.resetPayments();
      this.$emit('close');
    },
    async fetchNextBillingPeriods() {
      try {
        this.billingPeriodLoading = true;

        let query = {future_months: 12, source: 'billing-cycle-period'}
        if(this.validBillingCycle){
          query.billing_cycle_id = this.billing_cycle_id
        }
        const res = await api.get(
          this,
          `${api.LEASES}/${this.lease.id}/billing_periods`,
          query
        );
        this.billingPeriods = res.billing_months;
        this.billingPeriodLoading = false;
      } catch(err) {
        this.showMessageNotification({type: 'error', description: err});
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  .w-100{
    width: 100%;
  }
  .hb-lh-0{
    line-height: 0px;
  }
  .hb-bill-day-panel {
    background-color: transparent !important;
  }
  button.v-expansion-panel-header, .theme--light.v-expansion-panels.hb-expansion-panel .v-expansion-panel--active > .v-expansion-panel-header{
    min-height: 30px !important;
  }
  .invoice-line-row {
    margin-bottom: 0;
  }
  .invoice-line-row .caption {
    color: #637381;
  }
</style>