<template>
  <div>
    <hb-modal
      v-model="dialog"
      title="Take a Payment"
      size="large"
      @close="$emit('close')"
      show-help-link
    >
      <template v-slot:content>
        <div class="pa-6 align-stretch hb-cloud-lighter">
          <payment-process
            :contactID="contact.id" 
            :propertyID="lease.Unit.property_id"
            :paymentSource="source"
            >
          </payment-process>
        </div>
      </template>
      <template v-slot:actions>
        <hb-btn class="mr-2" color="primary" @click="checkPaymentsErrors" :disabled="disable_payment_button" :loading="disable_payment_button">Process Payment</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
import PaymentProcess from '../../includes/PaymentProcess/Index.vue';
import { EventBus } from '../../../EventBus.js';

import { mapGetters, mapMutations, mapActions } from 'vuex';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';

export default {
  name: "TakePaymentModal",
  mixins: [ notificationMixin ],
  data() {
    return {
      disable_payment_button: false,
    };
  },
  components:{
    PaymentProcess
  },
  props: ["value", "contact", "lease", "source", "disable_payment"],
  created() {
    EventBus.$on('paymentReader', this.enablePayment);
  },
  destroyed() {
    EventBus.$off('paymentReader', this.enablePayment);
  },
  computed: {
    ...mapGetters({
      getErrors: 'paymentsStore/getErrors'
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions({
      getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject'
    }),
    ...mapMutations({
      setCheckErrors: "paymentsStore/setCheckErrors",
      checkOnlyCredit: 'paymentsStore/checkOnlyCredit',
    }),
    checkPaymentsErrors() {
      this.checkOnlyCredit();
    },
    async processPayment() {
      this.disable_payment_button = true;
      const paymentInfo = await this.getTransformedPaymentObject({
        id: this.$options.name,
        formErrors: this.formErrors
      });

      if(paymentInfo.errors && paymentInfo.errors.length) {
        this.showMessageNotification({ list: paymentInfo.errors });
        this.disable_payment_button = false;
        return;
      }

      if(paymentInfo && paymentInfo.payment) {
        try {
          this.payInvoice({paymentInfo: paymentInfo});
        } catch(err){
          this.showMessageNotification({ description: err });
        }           
      }              
    },
    async enablePayment(response) {
      this.disable_payment_button = false;
    },
    async payInvoice({ paymentInfo }) {
      const billingOnData = {
        payment_details: paymentInfo,
      };
      this.$emit('update', billingOnData)
    },
  },
  watch: {
    getErrors(value) {
      if(value === false) {
        this.processPayment();
        this.setCheckErrors({ value: null });
      }
    },
    disable_payment(new_value, old_value) {
      this.disable_payment_button = new_value;
    }
  }

}
</script>