<template>
  <div>
    <hb-form label="Billing Cycle">
      <BillingCycleInfo durationPeriodColor="#fff" :billingCycles="currentBillingCycles" />
      <div class="hb-text-light hb-font-caption pt-2 pb-2">
      <hb-tooltip>
        <template slot="item">
          <span style="border-bottom: thin dashed; cursor: pointer">
            Past Billing Cycles ({{ pastBillingCycles.length }})
          </span>
        </template>
        <template slot="body">
          <BillingCycleInfo
            :billingCycles="pastBillingCycles"
            durationPeriodColor="#fff"
          />
        </template>
      </hb-tooltip>
    </div>
      <hb-link @click="show_billing_cycle_change_model = true" v-if="editable">Change Billing Cycle</hb-link>
    </hb-form>

    <BillingCycleConversionModal
      v-model="show_billing_cycle_change_model"
      v-if="show_billing_cycle_change_model"
      :lease="lease"
      :contact="contact"
      @close="cancelBillingCycleChange"
    />
  </div>
</template>
<script>
import { EventBus } from '../../../EventBus.js';
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import { mapGetters, mapMutations, mapActions } from "vuex";
import BillingCycleConversionModal from "./BillingCycleConversionModal.vue";
import BillingCycleInfo from "./BillingCycleInfo.vue";

export default {
  name: "BillingCycle",
  props: ["lease", "contact", "editable", "billingCycles"],
  mixins: [ notificationMixin ],
  data (){
    return {
      active: "",
      show_billing_cycle_change_model: false
    }
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
    pastBillingCycles(){
      return this.billingCycles.filter(billingCycle => billingCycle.status === 'Past');
    },
    currentBillingCycles(){
      return this.billingCycles.filter(billingCycle => billingCycle.status !== 'Past');
    },
  },
  components: {
    BillingCycleConversionModal,
    BillingCycleInfo
  },
  methods: {
    ...mapActions({
      getBillingCycles: "contactStore/getBillingCycles"
    }),
    ...mapMutations({
      setLeases: 'paymentsStore/setLeases',
      setPaymentSource: 'paymentsStore/setPaymentSource',
      pushInvoices : 'paymentsStore/pushInvoices',
      setInvoicesObject : 'paymentsStore/setInvoicesObject',
      resetPayments: 'paymentsStore/resetPayments',
      setLeaseProperty: 'paymentsStore/setLeaseProperty'
    }),
    cancelBillingCycleChange(){
      this.active = null;
      this.show_billing_cycle_change_model = false;
    }
  }
}
</script>