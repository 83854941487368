<template>
    <div v-if="phone.phone">
        <!-- <span class="pr-2">{{phone.type}}</span> -->
        <template v-if="isOutBoundCallPermitted">
            <hb-menu disable-d-flex style="z-index: 10;">
                <template v-slot:menu-activator>
                    <span class="font-weight-medium hb-link-secondary">{{ phone.phone | formatPhone }}</span>
                </template>
                <v-list>
                    <v-list-item :ripple="false" @click="onMakePhoneCall(phone.phone)">
                        <v-list-item-title>Call {{ phone.phone |
                            formatPhone }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item :ripple="false" @click="openText" :disabled="!(phone.phone && phone.sms)">
                        <v-list-item-title v-if="!(phone.phone && phone.sms)">Texting
                            is not
                            authorized for this
                            number</v-list-item-title>
                        <v-list-item-title v-else>Text {{
                            phone.phone | formatPhone
                        }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </hb-menu>
        </template>
        <template v-else>{{ phone.phone | formatPhone }}</template>
        <template v-if="!phone.sms">
            <hb-tooltip>
                <template v-slot:item>
                    <HbIcon small class="ml-1" :style="'position:relative;top:4px;cursor:default'"
                        :mdi-code="'mdi-custom-message-off-outline'" />
                </template>
                <template v-slot:body>
                    {{ 'Texting is not authorized for this number' }}
                </template>
            </hb-tooltip>
        </template>
        <template v-else>
            <HbIcon small class="ml-1" :style="'position:relative;top:-.5px;'" :mdi-code="'mdi-message-outline'" />
        </template>
        <!-- <span v-if="phone.sms" class="pl-1 hb-text-light">Can SMS</span> -->
        <hb-modal size="large" v-model="showTextModal" title="Send Text" class="ma-0 py-0 interaction-window"
            show-help-link footer-off>
            <template v-slot:content>
                <send-sms v-if="showTextModal"
                    :to="phone.phone ? phone : contact?.Phones && contact?.Phones.length ? contact?.Phones[0].phone : ''"
                    :interactiveMode="true" :contactId="contact?.id" :onClose="closeText" :onChatError="onChatError"
                    :spaceListViewOptions="unitOptions" :contactPhoneList="contactPhoneList"
                    :phoneId="contact?.Phones && contact?.Phones?.length ? contact?.Phones[0]?.id : ''"
                    :textarea_height="200" :property_id="outBoundCallPropertyId" :properties="selectedProperties">
                </send-sms>
                <template v-else></template>
            </template>
        </hb-modal>
    </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SendSms from "../communication_center/SendSms.vue";
import { parsePhoneNumber } from 'libphonenumber-js';
import api from '../../assets/api.js';

export default {
    data() {
        return {
            showTextModal: false,
            outBoundCallPropertyId: "",
            unitOptions: [],
            contactPhoneList: [],
            chatError: ""
        }
    },
    components: {
        SendSms
    },
    props: {
        contact: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        propertyView: {
            type: String,
            default: ''
        },
        phone: {
            type: Object,
            required: true,
            default: () => ({})
        }
    },
    created() {
        this.sendPhoneList();
    },
    computed: {
        ...mapGetters({
            loggedInUser: "authenticationStore/getUserData",
            hasPermission: 'authenticationStore/rolePermission',
            charmSubscribedProperties: "charmSubscriptionStore/charmSubscribedProperties",
            selectedProperties: "propertiesStore/filtered",
        }),
        isOutBoundCallPermitted() {
            return this.hasPermission("mini_charm") && this.checkForCharmProperty();
        },
    },
    methods: {
        sendPhoneList() {
            this.contactPhoneList.push({
                contact_id: this.contact.id,
                id: this.phone.id,
                phone: this.formatPhoneNumber(this.phone.phone)
            })
        },
        formatPhoneNumber(value) {
            if (!value) return '';
            try {
                var parsedPhoneNumber = parsePhoneNumber('+' + value);

                if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                    if (parsedPhoneNumber.country === 'US') {
                        return '+1 ' + parsedPhoneNumber.formatNational();
                    } else {
                        return parsedPhoneNumber.formatInternational();
                    }
                } else {
                    value = value.toString();

                    var numbers = value.replace(/\D/g, ''), char = { 0: '(', 3: ') ', 6: '-' };
                    value = '';
                    for (var i = 0; i < numbers.length; i++) {
                        value += (char[i] || '') + numbers[i];
                    }
                }
            } catch (err) {
            }
            return value;
            // return formattedPhoneNumber
        },
        ...mapActions({
            makeOutboundCall: "charmCallStore/makeOutboundCall",
        }),
        checkForCharmProperty() {
            let property_ids = [];
            if (this.contact) {
                if (this.contact.Leases?.length) {
                    this.contact.Leases.map((val) => {
                        if (property_ids.indexOf(val?.Unit?.property_id) < 0) {
                            property_ids.push(val?.Unit?.property_id);
                        }
                        if (this.selectedProperties.some((obj) => obj.id === val?.Unit?.property_id)) {
                            this.unitOptions.push({
                                id: val?.id,
                                Unit: {
                                    number: val.Unit?.number,
                                    type: val.Unit?.type,
                                    property_id: val?.Unit?.property_id,
                                    state: val?.Unit?.state,
                                },
                                end_date: val?.end_date,
                            });
                        }
                    });
                }
                if (this.contact.Leads?.length) {
                    this.contact.Leads.map((val) => {
                        if (property_ids.indexOf(val?.property_id) < 0) {
                            property_ids.push(val?.property_id);
                        }
                    });
                }
                if (this.contact.Reservations?.length) {
                    this.contact.Reservations?.map((val) => {
                        if (property_ids.indexOf(val?.Unit?.property_id) < 0) {
                            property_ids.push(val?.Lease?.Unit?.property_id);
                        }
                        if (
                            this.selectedProperties.some((obj) => obj.id === val?.Lease?.Unit?.property_id)
                        ) {
                            this.unitOptions.push({
                                id: val.Lease?.id,
                                Unit: {
                                    number: val.Lease?.Unit?.number,
                                    type: val.Lease?.Unit?.type,
                                    property_id: val.Lease?.Unit?.property_id,
                                    state: val.Lease?.Unit?.state,
                                },
                                end_date: val.Lease?.end_date,
                            });
                        }
                    });
                }
                if (this.contact.Pending?.length) {
                    this.contact.Pending?.map((val) => {
                        if (property_ids.indexOf(val?.Unit?.property_id) < 0) {
                            property_ids.push(val?.Unit?.property_id);
                        }
                        if (this.selectedProperties.some((obj) => obj.id === val?.Unit?.property_id)) {
                            this.unitOptions.push({
                                id: val?.id,
                                Unit: {
                                    number: val.Unit?.number,
                                    type: val.Unit?.type,
                                    property_id: val?.Unit?.property_id,
                                    state: val?.Unit?.state,
                                },
                                end_date: val?.end_date,
                            });
                        }
                    });

                }
            }
            if (this.unitOptions.length) {
                if(this.propertyView!==""){
                    this.unitOptions = this.unitOptions.filter((property) => property.Unit.property_id === this.propertyView);
                }
                this.unitOptions.unshift({
                    id: null,
                    Unit: { number: "Tenant", type: "tenant", unit_type_id: "" },
                });
            }
            let charmProperty = false;
            property_ids?.map((id) => {
                this.charmSubscribedProperties.find((property) => {
                    if (property.property_id == id) {
                        this.outBoundCallPropertyId = id;
                        charmProperty = true;
                    }
                });
            });
            return charmProperty;
        },
        async onMakePhoneCall(phone) {
            const contactNotificationObject = await api.get(
                this,
                api.CHARM_CONTACT + this.contact?.id + "?scope=message-center"
            );
            let transformedObject = contactNotificationObject;
            transformedObject.phone_number = contactNotificationObject?.contact_phone;
            this.makeOutboundCall({ contact: transformedObject, agent: this.loggedInUser, phone: phone });
        },
        openText() {
            this.showTextModal = true;
        },
        closeText() {
            this.showTextModal = false;
            this.chatError = "";
        },
        onChatError(error) {
            this.chatError = error;
        },
    },

}
</script>
