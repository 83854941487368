<template>
  <div v-if="connections?.length === 0">
    <AsyncLoader></AsyncLoader>
  </div>
  <div v-else>
  <hb-modal
    v-model="dialog"
    :title="paymentMethod?.id ? 'Set Payment Method to AutoPay' : 'Add New Payment Method'"
    @close="reset"
    show-help-link
    :size="$vuetify.breakpoint.xs ? 'fullscreen' : ''"
  >
    <template v-slot:content>
      <v-row no-gutters class="bottom_divider">
        <v-col cols="12" class="card-options px-4 pt-3 pb-4">
          <hb-radio-group v-if="!paymentMethod?.id" class="mt-1" v-model="payment_type" :mandatory="false">
            <v-expansion-panels accordion focusable class="expansion-panel">
              <v-expansion-panel class="expansion-panel-header" v-if="cardProcessor">
                <v-expansion-panel-header expand-icon="">
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center hb-font-body-medium"> <hb-radio value="card"></hb-radio>Credit/Debit</div>
                      <div class="d-flex gap-2px">
                      <v-img src="/img/payment_method_logos/visa_card_new.svg" class="mr-1"  max-height="16"
                      max-width="26.47"></v-img>
                      <v-img src="/img/payment_method_logos/master_card_new.svg"  class="mr-1" max-height="16"
                      max-width="26.47"></v-img>
                      <v-img src="/img/payment_method_logos/american_express_new.svg" class="mr-1 rounded-sm"  max-height="16"
                      max-width="26.47"></v-img>
                      <v-img src="/img/payment_method_logos/discover_card_new.svg"  max-height="16"
                      max-width="26.47"></v-img>
                      </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>            
                  <authnet-card :dense_header="true" :ten_address="tenant_address" v-if="payment_type === 'card' && cardProcessor === 'authorizenet'" ref="authnetCard" :property_id="property_id" :contact_id="contact.id" :saveCard="true"></authnet-card>
                  <tsys-card :ten_address="tenant_address" :dense_header="true" v-if="payment_type === 'card' && cardProcessor === 'tsys'" ref="tsysCard"></tsys-card>
                  <tenant-payments-card :ten_address="tenant_address" :dense_header="true" v-if="payment_type === 'card' && cardProcessor === 'tenant_payments'" ref="tenantPaymentsCard" :property_id="property_id" :contact_id="contact.id" :saveCard="true"></tenant-payments-card>
                  <fat-zebra-card :ten_address="tenant_address" :dense_header="true" v-if="payment_type === 'card' && cardProcessor === 'fatzebra'" ref="fatzebraCard" :property_id="property_id" :contact_id="contact.id" :saveCard="true"></fat-zebra-card>             
               </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="expansion-panel-header" v-if="directDebitProcessor">
                <v-expansion-panel-header expand-icon="">
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center hb-font-body-medium">  <hb-radio value="directdebit"></hb-radio>Direct Debit</div>
                    <div><hb-icon class="mr-3" color="#101318">mdi-credit-card-outline</hb-icon></div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>            
                  <fat-zebra-direct-debit :ten_address="tenant_address" :property_id="property_id" :dense_header="true"  v-if="payment_type === 'directdebit' && directDebitProcessor === 'fatzebra'" ref="fatzebraDirectDebit"></fat-zebra-direct-debit>         
               </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="expansion-panel-header" v-if="achProcessor">
                <v-expansion-panel-header expand-icon="">
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center hb-font-body-medium"><hb-radio value="ach"></hb-radio>ACH/E-Check</div>
                    <div><hb-icon class="mr-3" color="#101318">mdi-credit-card-outline</hb-icon></div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>            
                  <tsys-ach :ten_address="tenant_address" :dense_header="true" v-if="payment_type === 'ach' && achProcessor === 'tsys'" ref="tsysAch"></tsys-ach>
                  <forte-ach :dense_header="true" v-if="payment_type === 'ach' && achProcessor === 'forte'" ref="forteAch"></forte-ach>
                  <tenant-payments-ach :ten_address="tenant_address" :dense_header="true" v-if="payment_type === 'ach' && achProcessor === 'tenant_payments'" ref="tenantPaymentsAch"></tenant-payments-ach>
               </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </hb-radio-group>
          <HbCard v-else :boxShadow="false" :border="false" flat class="px-3">
                <v-list-item
                  color="white"
                  class="autopay-item my-0 pr-0 pl-0 mx-0"
                >
                  <v-list-item-icon class="mx-0 px-0 my-1 pt-2">
                    <img
                      v-if="['card', 'ach', 'directdebit'].includes(paymentMethod.type)"
                      class="cc-icon"
                      :src="paymentMethod.type === 'card' ? (paymentMethod | getCCIcon) : `/img/cc_icons/64/${paymentMethod.type}.png`"
                    />
                  </v-list-item-icon>
                  <v-list-item-content class="mx-0 px-0 my-0 py-0">
                    <v-list-item-title>
                      <span
                        class="card-detail"
                        >{{ paymentMethod.name_on_card }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span
                        class="font-weight-medium text-uppercase hb-text-night hb-default-font-size"
                        >{{ paymentMethod.card_type }} *{{ paymentMethod?.card_end?.slice(-4) }}</span
                      >
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item> 
            </HbCard>
          </v-col>

        <v-col cols="8" class="payment-method-holder">

          <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
          <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success" style="position:absolute;bottom:.5%;right:1.6%;width:40%"></status>
        
          <authnet-card :dense_header="true" :ten_address="tenant_address" v-if="payment_type === 'card' && cardProcessor === 'authorizenet'" ref="authnetCard" :property_id="property_id" :contact_id="contact.id" :saveCard="true"></authnet-card>
          <tsys-card :ten_address="tenant_address" :dense_header="true" v-if="payment_type === 'card' && cardProcessor === 'tsys'" ref="tsysCard"></tsys-card>
          <tsys-ach :ten_address="tenant_address" :dense_header="true" v-if="payment_type === 'ach' && achProcessor === 'tsys'" ref="tsysAch"></tsys-ach> 
          <forte-ach :dense_header="true" v-if="payment_type === 'ach' && achProcessor === 'forte'" ref="forteAch"></forte-ach>
          <tenant-payments-card :ten_address="tenant_address" :dense_header="true" v-if="payment_type === 'card' && cardProcessor === 'tenant_payments'" ref="tenantPaymentsCard" :property_id="property_id" :contact_id="contact.id" :saveCard="true"></tenant-payments-card>
          <tenant-payments-ach :ten_address="tenant_address" :dense_header="true" v-if="payment_type === 'ach' && achProcessor === 'tenant_payments'" ref="tenantPaymentsAch"></tenant-payments-ach>
          <fat-zebra-card :ten_address="tenant_address" :dense_header="true" v-if="payment_type === 'card' && cardProcessor === 'fatzebra'" ref="fatzebraCard" :property_id="property_id" :contact_id="contact.id" :saveCard="true"></fat-zebra-card>
          <fat-zebra-direct-debit :ten_address="tenantAddress" :dense_header="true"  v-if="payment_type === 'directdebit' && directDebitProcessor === 'fatzebra'" :property_id="property_id" ref="fatzebraDirectDebit"></fat-zebra-direct-debit>

        </v-col>

      </v-row>
      <v-row no-gutters v-if="isMSRActive">
        <v-col class="services-section">

          <div class="mb-3" v-if="!documentLoading">
            <hb-notification
              type="caution"
              not-dismissable
              title-off
            >
              {{ LEASE.MULTI_SPACE_RENTAL_BANNERS.AUTOPAY_AND_INSURANCE_CONVERSION_BANNER }}
            </hb-notification>
          </div>
          
          <DocumentsSigner v-if="!documentLoading" :lease-id="lease.id" :current-contact="contact" :is-multi-space-lease="is_multi_space_lease" :processType="LEASE_CONFIGURATION.AUTOPAY_ENROLLMENT_SLUG" @documentsSigned="checkStatus"></DocumentsSigner>
          
          <v-row justify="center" align="center" v-if="documentLoading">
            <v-col class="text-center">
              <v-progress-circular
                width="2"
                size="32"
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </template>

    <template v-slot:right-actions>
      <hb-btn v-if="inCall && ivrPaymentType === 'card' &&  IvrPaymentEnable && ivrPaymentMethod == 'new' " color="primary" @click="completeSession()" :disabled="!enableIvrProcessPayment" :loading="ivrPaymentButtonLoading">Save Card {{ivrTimeout}}</hb-btn>
      <hb-btn
        v-else
        color="primary"
        @click="save"
        :disabled="!documentSigned || disableAutoPay || !payment_type"
        :loading="disableAutoPay || addMethodInProgress"
      >
        Save
      </hb-btn>
    </template>
  </hb-modal>
  </div>
</template>

<script>

import api from '../../assets/api.js'
import AuthnetCard from '../includes/PaymentMethods/AuthnetCard.vue'
import FatZebraCard from "../includes/PaymentMethods/FatzebraCard";
import FatZebraDirectDebit from "../includes/PaymentMethods/FatzebraDirectDebit";
import TsysCard from '../includes/PaymentMethods/TsysCard.vue'
import TsysAch from '../includes/PaymentMethods/TsysAch.vue'
import ForteAch from '../includes/PaymentMethods/ForteAch.vue'
import TenantPaymentsAch from '../includes/PaymentMethods/TenantPaymentsAch.vue'
import TenantPaymentsCard from '../includes/PaymentMethods/TenantPaymentsCard.vue'
import Status from '../includes/Messages.vue';
import { EventBus } from '../../EventBus.js'
import { mapMutations, mapGetters ,mapActions} from 'vuex';
import { notificationMixin } from  '../../mixins/notificationMixin.js';
import DocumentsSigner from '../includes/DocumentsSigner.vue';
import LEASE_CONFIGURATION from '@/constants/lease_configuration.js';
import LEASE from '@/constants/lease.js';
import { settingsMixin } from "@/mixins/settingsMixin.js";
import SETTINGS from "@/constants/settings.js";
import AsyncLoader from "../includes/Loading.vue"

export default {
  name: 'AddPaymentMethod',
  mixins: [notificationMixin, settingsMixin],
  data() {
    return {
      connections: [],
      payment_type: '',
      pm_errors: [],
      disableAutoPay: false, 
      documentSigned: true,
      documentLoading: true,
      addMethodInProgress: false
    }
  },
  props: ['property_id','contact','close','value','lease','paymentMethod', 'isBillingProfile'],
  components: {
    AuthnetCard,
    TsysCard,
    TsysAch,
    ForteAch,
    FatZebraCard,
    FatZebraDirectDebit,
    TenantPaymentsAch,
    TenantPaymentsCard,
    Status,
    DocumentsSigner,
    AsyncLoader
  },
  computed: {
    inCall() {
              return this.notification.status === 'active';
            },
          ivrTimeout(){
              return this.ivrTimer
          },
    ...mapGetters({
        ivrTimer:'charmCallStore/ivrTimer',
        ivrPaymentButtonLoading:'charmCallStore/ivrPaymentButtonLoading',
        paymentConnectorResponse:'charmCallStore/paymentConnectorResponse',
        enableIvrProcessPayment:'charmCallStore/enableIvrProcessPayment',
        IvrPaymentEnable:'charmCallStore/IvrPaymentEnable',   
        ivrPaymentMethod:'charmCallStore/ivrPaymentMethod',
        ivrPaymentType:'charmCallStore/ivrPaymentType',
        paymentData: 'paymentsStore/getPaymentInformation',
        notification:'charmCallStore/notification',
        payment: 'paymentsStore/getPayment',
        payment_method: 'paymentsStore/getPaymentMethod',
        template: 'onBoardingStore/template',
        singleSpaceRentalExists: "onBoardingStore/getSingleSpaceRental"
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      }
    },
    cardProcessor(){
      let processor = this.connections.find(c => c.type === 'card' );
      return processor ? processor.name : null;
    },
    achProcessor(){
      let processor = this.connections.find(c => c.type === 'ach' );
      return processor ? processor.name : null;
    },
    directDebitProcessor(){
      let processor = this.connections && this.connections.find(c => c.type === 'directdebit' );
      return processor ? processor.name : null;
    },
    tenant_address () {
      return this.contact.Addresses
    },
    is_multi_space_lease() { 
      if(!Object.keys(this.currentTemplate).length) return false;
      if (!this.currentTemplate?.multi_space_rental || this.singleSpaceRentalExists?.flag) return false;  
      return true;
    },
    currentTemplate(){
      return this.template[this.lease?.unit_id] || {}; 
    },
    isMSRAutoPayTriggerEnabled(){
      return this.currentTemplate?.multi_space_rental_types.length && this.currentTemplate?.multi_space_rental_types.some(item => item.slug === LEASE_CONFIGURATION.AUTOPAY_ENROLLMENT_SLUG && item.active === 1) || false;
    },
    isMSRActive(){
      return this.getFeatureSettingValueByName(SETTINGS.ALLOW_MULTI_SPACE_RENTAL) && this.currentTemplate?.multi_space_rental && this.isMSRAutoPayTriggerEnabled && this.singleSpaceRentalExists?.flag && this.lease?.id && !this.isBillingProfile;
    },

  },
  async created () {
    this.LEASE = LEASE;
    this.LEASE_CONFIGURATION = LEASE_CONFIGURATION;
    this.SETTINGS = SETTINGS;
    await this.addMSRCheckListLeaseItem();
    this.setPayment({property: 'source', propertyValue: 'In-store'});
    this.setContact({contact: this.contact});
    this.contact_id = this.contact.id
    this.getPaymentMethodOptions()
    this.updateIvrPaymentEnable(true)
    if(this.paymentMethod?.type){
      this.payment_type = this.paymentMethod.type;
    }
    if(this.contact?.id && this.lease?.Unit?.property_id && this.lease?.Unit?.unit_type_id){
        const params = {
          contact_id: this.contact?.id,
          property_id: this.lease.Unit.property_id,
          unit_type_id: this.lease.Unit.unit_type_id,
        }
        await this.$store.dispatch("onBoardingStore/updateSingleSpaceRental",params);
    }
  },
  destroyed(){
    this.updateIvrPaymentEnable(false);
  },
  methods: {
    async completeSession(){
      EventBus.$emit('complete-ivr-payment-session');
          },
    ...mapMutations({
      updateIvrPaymentEnable:'charmCallStore/updateIvrPaymentEnable',
      setCheckErrors: 'paymentsStore/setCheckErrors',
      setPayment: 'paymentsStore/setPayment',
      setContact: 'paymentsStore/setContact',
      setPaymentMethod: 'paymentsStore/setPaymentMethod',
      setPaymentMethodObject: 'paymentsStore/setPaymentMethodObject',
      resetPayments: 'paymentsStore/resetPayments',
      ivrPaymentTypeUpdate:'charmCallStore/ivrPaymentType'
    }),
    ...mapActions({     
            fetchPropertyConnections: 'paymentsStore/fetchPropertyConnections',    
    }),

    async getPaymentMethodOptions(){
      this.fetchPropertyConnections({propertyID:this.property_id})
      let r = await api.get(this, api.PROPERTIES + this.property_id +  '/connections');
      this.connections = r.connections;
      if(!this.connections.length){
        this.showMessageNotification({type: 'error', description: "This account is not set up to accept online payments."});
      }
    },

   async reset(is_save = false){
      if(!is_save && this.isMSRActive){
        await api.put(this, api.LEASES + 'delete-checklist-leases-uploads',{ lease_ids: [this.lease?.id] });
      }
      this.resetPayments();
      this.payment_type = '';
      this.$emit('close');
    },

    async save(){
      this.disableAutoPay = true;
      this.addMethodInProgress = true;

      // if payment method already exists, set it to auto pay
      if(this.paymentMethod?.id){
        this.$emit('setAutoPay', this.paymentMethod);
        this.dialog = false
        this.disableAutoPay = false;
        this.addMethodInProgress = false;
        return;
      }

      let valid = await this.validatePaymentMethod();
      if(!valid) {
        this.disableAutoPay = false;
        this.addMethodInProgress = false;
        return;
      }

      let data = {
        payment: this.generateObjectWithValues(this.payment, {}),
        property_id: this.property_id,
        contact_id: this.contact_id,
        payment_method: this.generateObjectWithValues(this.payment_method, {})
      }

      try{
        if(data.payment_method.type==='directdebit' && data.payment_method.bsb_number){
          data.payment_method.bsb_number = data.payment_method.bsb_number ? data.payment_method.bsb_number.slice(0, 3) + '-' + data.payment_method.bsb_number.slice(3) : "" 
        }
        let res = await api.post(this, api.PAYMENTS + 'payment-method', data);
        this.reset(true);
        this.$emit('refreshData', res.payment_method)
        EventBus.$emit('lease_edited');
        this.showMessageNotification({ type: 'success', description: 'New payment method has been successfully added.' });
        this.disableAutoPay = false;
      } catch(err){
        this.showMessageNotification({ list: [err] }); 
      } finally{
        this.disableAutoPay = false;
        this.addMethodInProgress = false;
      }

    },

    cancel(){  
      this.reset();
      this.$emit('close');
    },

    validatePaymentMethod(){
      if(this.payment_type === 'card' && this.cardProcessor === 'authorizenet') return this.$refs.authnetCard.validateFields();
      if(this.payment_type === 'ach' && this.achProcessor === 'forte') return this.$refs.forteAch.validateFields();
      if(this.payment_type === 'card' && this.cardProcessor === 'tsys') return this.$refs.tsysCard.validateFields();
      if(this.payment_type === 'ach' && this.achProcessor === 'tsys') return this.$refs.tsysAch.validateFields();
      if(this.payment_type === 'card' && this.cardProcessor === 'tenant_payments') return this.$refs.tenantPaymentsCard.validateFields();
      if(this.payment_type === 'ach' && this.achProcessor === 'tenant_payments') return this.$refs.tenantPaymentsAch.validateFields();
      if(this.payment_type === 'card' && this.cardProcessor === 'fatzebra') return this.$refs.fatzebraCard.validateFields();
      if(this.payment_type === 'directdebit' && this.directDebitProcessor === 'fatzebra') return this.$refs.fatzebraDirectDebit.validateFields();

  
    },

    generateObjectWithValues(source, target) {
      Object.keys(source).forEach((key) => {
        const value = source[key];
        if (value !== undefined && value !== "" && value !== null) {
          target[key] = value;
          if(key === 'save_to_account') target[key] = true;
        }
      });
      return target;
    },

    async addMSRCheckListLeaseItem(){
      if(this.isMSRActive){
          try {
            let data = {
              leases_data: [{lease_id: this.lease?.id, unit_id: this.lease?.unit_id, template_id: this.currentTemplate?.id}]
            }
            await api.post(this, api.LEASES + '/create-checklist-leases', data); 
            this.documentLoading = false;
          } catch (error) {
            this.showMessageNotification({ type: 'error', description: error });
          }
      }
    },
    checkStatus(status){
       this.documentSigned = status;
    }
  },
  watch: {

    paymentConnectorResponse(payload){
            if(!payload) return
            if(payload.status == 200){
                this.reset();
                this.$emit('refreshData', payload.payment_method)
                EventBus.$emit('lease_edited');
                this.showMessageNotification({ type: 'success', description: 'New payment method has been successfully added.' });
                }else{
                  if(payload?.msg){
                    this.showMessageNotification({ list: [payload.msg] });
                  }else{
                    this.showMessageNotification({ type: 'error', description: 'Something went wrong.' });
                  }
                }
          },
    payment_type() {
      this.ivrPaymentTypeUpdate(this.payment_type);
      this.closeMessageNotification();
      this.setPaymentMethod({property: 'type', propertyValue: this.payment_type}); 
      this.setPayment({property: 'type', propertyValue: this.payment_type});
    }
  }
}
</script>
<style scoped>
  .card-options{
    font-size: 14px;
  }
  .payment-method-holder{
    padding: 10px 20px;
  }
  .services-section{
    padding: 20px 24px 20px 24px;
  }
  .bottom_divider{
    border-bottom: 1px solid #c4cdd5;
  }
  .expansion-panel {
     border: 1px solid #DFE3E8
   }
  .expansion-panel >>> .v-expansion-panel:not(:first-child):after {
   border: 0.5px solid rgba(0, 0, 0, 0.12);
 }
 .expansion-panel .v-expansion-panel-content{
  background-color: #F9FAFB;
 }
 .expansion-panel .v-expansion-panel-header.v-expansion-panel-header--active,
 .expansion-panel .v-expansion-panel-header.v-expansion-panel-header--active::before {
    background-color: #e0f5f5;
 }
 .v-expansion-panel::before{
  box-shadow: 0px 4px 10px 3px rgba(71, 84, 116, 0.1) !important;
 }
 ::v-deep.v-input--radio-group .v-messages {
  display: none;
 }
 ::v-deep.v-input--radio-group .v-input__slot { 
  margin-bottom: 0px !important;
 }
</style>