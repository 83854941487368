<template>
  <div>
    <v-container class="payment-method-container">
      <div class="mt-3">
        <v-row no-gutters>
          <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6" class="py-0 mb-0">
        <v-row v-if="payment_type && payment_type.toLowerCase() === 'directdeposit'" class="mt-2 mb-0" no-gutters>
        <v-col  class="my-0 py-0" :class="{'hb-font-caption-medium' : $vuetify.breakpoint.xs , 'hb-font-body-medium' : !$vuetify.breakpoint.xs}" :cols="$vuetify.breakpoint.xs ? 7: 8">Transaction Amount:</v-col>
        <v-col class="py-0 my-0" :cols="$vuetify.breakpoint.xs ? 5: 4" >
       <v-row no-gutters>
        <v-col class="pa-0" :cols="(paymentData.amountTendered - paymentData.totalNewPaymentPaying > 0) ? 10 : 12">
          <hb-text-field
            box
            key="amountTendered"
            class="cash-input-filed"
            :class="{'custom-field-error' : errors.first('amountTendered') }"
            hide-details
            :value="getPrecisiedVal"
            v-validate="`required|min_value:${paymentData.totalNewPaymentPaying}`"
            data-vv-name="amountTendered"
            data-vv-as="Cash Amount Tendered"
            :error="errors.has('amountTendered')"
            prepend-inner-icon="mdi-currency-usd"
            id="amountTendered"
            name="amountTendered"
            label="Cash Amount Tendered*"
            @blur="$validator.validate('amountTendered')"
            @input="handleAmountTendered('amountTendered', $event)"
            condensed
          >
          <template v-slot:prepend-inner>
                 <div>
                  $
                 </div>
          </template>
          </hb-text-field>
        </v-col>
        <v-col v-show="paymentData.amountTendered - paymentData.totalNewPaymentPaying > 0" class="pa-0 ma-0 pl-4" cols="2">
          <div class="text-caption">Change Due</div>
          <div>{{ (paymentData.amountTendered - paymentData.totalNewPaymentPaying) | formatMoney }}</div>
        </v-col>
       </v-row>
       </v-col>
         </v-row>
      
         <v-row no-gutters>
          <v-col cols="12" class="hb-z-index-99999 ma-0 pa-0 date-picker-container">
             <hb-date-picker
                 @click:clear="settle_date = ''"
                 label="Date of Settlement"
                 :clearable="true"
                 :solo="false"
                 single-line
                 v-model="payment.settle_date"
                 data-vv-name="settle_date"
                 data-vv-as="settle_date"
                 id="settle_date"
                 name="settle_date"
                 v-validate="'required'"
                :message="errors.collect('settle_date')"
                @input="handleInputUpdate('payment', 'settle_date', $event)"
              ></hb-date-picker>
          </v-col>
        </v-row> 

        <v-row no-gutters>
          <v-col class="pa-0 ma-0 pt-4">
          <hb-textarea
            :class="{'custom-field-error' : errors.first('notes')}"
            hide-details
            :value="payment.notes"
            data-vv-name="notes"
            data-vv-as="Deposit Notes"
            :error="errors.has('notes')"
            label="Notes"
             class="pb-4 mt-4"
            id="cash-notes"
            name="cash-notes"
            autocomplete="cc-cash-notes"
            @input="handleInputUpdate('payment', 'notes', $event)"
            backgroundColor=""
          >
          </hb-textarea>
          </v-col>
        </v-row>
        
          </v-col>
        </v-row>
    </div>
    </v-container>
  </div>
</template>


<script type="text/babel">

    import PaymentMethodBase from './PaymentMethodBase.vue';
    import { EventBus } from '../../../EventBus';
    import { mapGetters, mapMutations } from "vuex";
    import HummingbirdDatepicker from '../../assets/HummingbirdDatepicker';

    export default {
        extends: PaymentMethodBase,
        data() {
            return {
            }
        },
        components: {
          'hb-date-picker': HummingbirdDatepicker
        },
        computed:{
          ...mapGetters({
            paymentConfig: 'paymentsStore/getPaymentSourceConfig',
            paymentData: 'paymentsStore/getPaymentInformation',
            payment_method: 'paymentsStore/getPaymentMethod',
            contact: 'paymentsStore/getContact',
            payment: 'paymentsStore/getPayment',
            getErrors: 'paymentsStore/getErrors'
        }),
          getPrecisiedVal() {
            return this.paymentData.amountTendered ? parseFloat(this.paymentData.amountTendered).toFixed(2) : this.paymentData.amountTendered;
          }
        },
        created(){
        },
        destroyed() {
        },
        props: ['payment_type'],
        methods:{
          ...mapMutations({
            setCheckErrors: 'paymentsStore/setCheckErrors',
            setPaymentInformation: 'paymentsStore/setPaymentInformation',
            setPayment: 'paymentsStore/setPayment',
            setPaymentMethod: 'paymentsStore/setPaymentMethod',
          }),

          handleAmountTendered : _.debounce(async function(property_name, property_value) {
            property_value = Number(parseFloat(property_value).toFixed(2)) || 0;
            this.setPaymentInformation({property: property_name, propertyValue: property_value});
          }, 1000),

          handleInputUpdate : _.debounce(async function(obj ,property_name, property_value) {
            if(obj === 'payment_information'){
              if(property_name === 'amountTendered') property_value = Number(parseFloat(property_value).toFixed(2)) || 0;
              this.setPaymentInformation({property: property_name, propertyValue: property_value});
            }
            if(obj === 'payment'){
              this.setPayment({property : property_name, propertyValue: property_value});
            }
          }, 200),

          validateFields(){
            return this.$validator.validateAll();
          }
        },
        watch:{
        }


    }
</script>
<style scoped>
.payment-method-container{
  display:flex;
  flex-direction: column;
}

.text-error {
  color: red !important;
}

.small-label {
  font-size: 11px; 
  color: grey;
}

.cash-input-filed {
  padding-top: 0px;
  margin-top: -5px;;
}

.payment-method-header{
    background-color: #E0F5F5;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  @media (min-width:600px){
    .payment-method-header{
      margin-left: 0 !important;
    }
  }

  .cash-input-filed >>> fieldset legend{
    display: none !important;
  }

 .date-picker-container >>> .v-text-field{
    padding-top: 2px !important;
    margin-top: 0px !important
  }

  .hb-text-field-condensed-wrapper{
    margin-right: 0px !important;
  }
  .hummingbird-date-text-field-wrapper{
    padding-top: 0px !important;
    margin-top: 0px !important;
  }

</style>
