<template>
  <div id="file-uploads" class="">
    <v-sheet class="chat-window-nav">
      <hb-tabs background-color="#FFFFFF">
        <v-tab v-for="menuOption in mainViewOptions" :value="menuOption" @click="mainViewChanged(menuOption)">
          {{ menuOption.Unit.number }}
        </v-tab>
      </hb-tabs>
    </v-sheet>
    <div class="doc-list" :style="$vuetify.breakpoint.width > 800 ? `height: 65vh` : ''" v-if=true >
      <v-card class="mb-4" :class="{ 'mr-6': showAside }" elevation="0">
        <v-data-table
          :mobile-breakpoint="320"
          :headers="headers"
          :items="uploads"
          :loading="loadingDocuments"
          loading-text="Loading Invoices..."
          class="hb-data-table"
          disable-pagination
          hide-default-footer
          :custom-sort="customSort"
          @click:row="onRowClick"
        >
          <template v-slot:item.status="{ item }">
            <hb-status-general :status="item.status"></hb-status-general>
          </template>
          <template v-slot:item.date="{ item }">
            <span>{{ item.date | formatDateServices }}</span>
          </template>
          <template v-slot:item.description="{ item }">
            <span>
              <hb-link
                color="secondary"
                class="text-capitalize"
                v-if="item.description"
              >
                <!-- @click="goDescription(item)"  -->
                <span
                  class="font-weight-medium"
                  v-if="item.description.prefix"
                  >{{ item.description.prefix }}</span
                >
                <span v-if="item.description.text">{{
                  item.description.text
                }}</span>
                <span v-if="!item.description.prefix || !item.description.text">{{ item.description + item.number }}</span>
              </hb-link>
            </span>
          </template>

          <template v-slot:item.charge="{ item }">
            <span> {{ item.amount | formatMoney }}</span>
          </template>
          <template v-slot:item.checkbox="{ item }" style="display: flex">
            <v-checkbox
              v-model="selectedoption"
              :value="item"
              hide-details
              class="square-checkbox"
            ></v-checkbox>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
<script type="text/babel">
import api from "../../assets/api.js";
import Status from "../includes/Messages.vue";
import Loader from "../assets/CircleSpinner.vue";
import moment from "moment";
import Modal from "../assets/Modal.vue";

import { EventBus } from "../../EventBus.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
//    import PublicDocuments from '../leases/PublicDocuments.vue';
import { notificationMixin } from "@/mixins/notificationMixin.js";
export default {
  name: "uploadInvoices",
  data() {
    return {
      previouslySelected: "",
      selectedoption: "",
      documents: [],
      document: {},
      hasSubmitted: false,
      uploads: [],
      lease: {},
      headers: [
        { text: "", value: "checkbox", align: "left", sortable: false },
        { text: "Date", value: "date", width: "80px", groupable: false },
        {
          text: "Invoice #",
          value: "description",
          width: "140px",
          groupable: false,
        },
        { text: "Amount", value: "charge", width: "100px", groupable: false },
        { text: "Status", value: "status", width: "110px", groupable: false },
      ],
      mainView: {
        id: null,
        Unit: {
          number: "Tenant",
          type: "tenant",
        },
      },
      dateRange: "",
      customStartDate: "",
      customEndDate: "",
      todaysDate: "",
      loadingDocuments: false,
      allowScrollRequest: true,
      loadingDocumentTemplates: false,
      documentMethod: "un-signed",
      documentType: { value: "all" },
      isDocumentReset: false,
      document_generate_type: "",
      fetchUnSignedDocumentUsingScroll: false,
      documentText: "Loading Invoices...",
    };
  },
  mixins: [notificationMixin],
  async created() {
    this.fetchData();
    await this.fetchDocumentTypes();
    await this.fetchUnSignedDocumentTypes();
    EventBus.$on("document_signed", this.fetchData);
    this.todaysDate = moment().startOf("day").format("YYYY-MM-DD");
  },

  components: {
    Modal,
    Loader,
    Status,
  },
  computed: {
    ...mapGetters({
      isAdmin: "authenticationStore/isAdmin",
      getUserData: "authenticationStore/getUserData",
      getDocuments: "documentsStore/getDocuments",
      getDocumentTypes: "documentsStore/getDocumentTypes",
      getUnSignedDocumentTypes: "documentsStore/getUnSignedDocumentTypes",
    }),

    getDocumentType() {
      return this.documentType.value != "all" ? this.documentType : "";
    },
    mainViewOptions() {
      var options = [];
      options.push({
        id: null,
        Unit: {
          number: "Tenant",
          type: "tenant",
        },
      });

      this.contactLeases.forEach((lease) => {
        if (this.propertyView) {
          if (lease.Unit.property_id == this.propertyView) {
            options.push({
              id: lease.id,
              end_date: lease.end_date,
              Unit: {
                number: lease.Unit.number,
                type: lease.Unit.type,
              },
            property_id: lease.Unit.property_id,
            });
          }
        } else {
          options.push({
            id: lease.id,
            end_date: lease.end_date,
            Unit: {
              number: lease.Unit.number,
              type: lease.Unit.type,
            },
            property_id: lease.Unit.property_id,
          });
        }
      });
      return options;
    },
  },
  methods: {
    async generateInvoice(invoice) {
      console.log(invoice);
      var data = {
        type: "invoice",
        format: "pdf",
        request_id: invoice.id,
      };

      let response = await api.post(this, api.REPORTS + "download-pdf", data).catch((err) => {
          this.showMessageNotification({ description: err });
          this.$emit("docLoadingemit", false);
        });
      const arr = new Uint8Array(response.data);
      var blob = new Blob([arr], { type: "application/pdf" });
      let fileName
      if(invoice.description.prefix && invoice.description.text){
        fileName = invoice.description.prefix + invoice.description.text
      }else{
        fileName = invoice.description + invoice.number
      }
      var file = new File(
        [blob],
        fileName + ".pdf",
        { type: blob.type }
      );
      return file;
    },
    async onRowClick(item) {
      if (this.previouslySelected == item) {
        this.selectedoption = "";
        this.previouslySelected = "";
        this.$emit("docSelectedemit", "");
      } else {
        this.$emit("docSelectedemit", "");
        this.$emit("docLoadingemit", true);
        let res = await this.generateInvoice(item);
        this.selectedoption = item;
        this.previouslySelected = item;
        this.$emit("docSelectedemit", res);
        this.$emit("docLoadingemit", false);

      }
    },
    ...mapActions({
      fetchDocumentsData: "documentsStore/fetchDocuments",
      fetchDocumentTypes: "documentsStore/fetchDocumentTypes",
      fetchUnSignedDocumentTypes: "documentsStore/fetchUnSignedDocumentTypes",
    }),
    ...mapMutations({
      setDocuments: "documentsStore/setDocuments",
    }),

    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === "description") {
          var fa = "";
          if (a.id && a.number) {
            fa = a.number
          } else {
            ("");
          }

          var fb = "";
          if (b.id && b.number) {
            fb = b.number;
          } else {
            ("");
          }
          if (!isDesc[0]) {
            if (fa > fb) {
              return -1;
            }
            if (fa < fb) {
              return 1;
            }
            return 0;
          } else {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          }
        }
        if (index[0] === "status") {
          var fa = "";
          if (a.id && a.status) {
            fa = a.status
          } else {
            ("");
          }

          var fb = "";
          if (b.id && b.status) {
            fb = b.status;
          } else {
            ("");
          }
          if (!isDesc[0]) {
            if (fa > fb) {
              return -1;
            }
            if (fa < fb) {
              return 1;
            }
            return 0;
          } else {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          }
        }
        if (index[0] === "charge") {
          var fa = "";
          if (a.id && a.amount) {
            fa = a.amount
          } else {
            ("");
          }

          var fb = "";
          if (b.id && b.amount) {
            fb = b.amount;
          } else {
            ("");
          }
          if (!isDesc[0]) {
            if (fa > fb) {
              return -1;
            }
            if (fa < fb) {
              return 1;
            }
            return 0;
          } else {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          }
        }
        if (index[0] === "date") {
          var fa = "";
          if (a.id && a.date) {
            fa = a.date
          } else {
            ("");
          }

          var fb = "";
          if (b.id && b.date) {
            fb = b.date;
          } else {
            ("");
          }
          if (!isDesc[0]) {
            if (fa > fb) {
              return -1;
            }
            if (fa < fb) {
              return 1;
            }
            return 0;
          } else {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          }
        }
      });

      return items;
    },
    async fetchDocuments(documentGenerateType = false) {
      this.loadingDocumentTemplates = true;
      if (this.documentMethod == "un-signed" && !documentGenerateType) {
        this.fetchNonSignedDocuments();
        return;
      }
      let limit = 30;
      let offset =
        this.documents.length == 0
          ? 1
          : Math.ceil(this.documents.length / limit) + 1;
      let params = "";
      if (documentGenerateType) {
        params = `?limit=${limit}&offset=${offset}&type[]=${this.document_generate_type}`;
      } else if (this.getDocumentType != "") {
        params = `?limit=${limit}&offset=${offset}&type[]=${this.getDocumentType.value}`;
      } else {
        params = `?limit=${limit}&offset=${offset}&fetch_details=true`;
      }

      console.log(
        "Before Request: ",
        moment().format("MMMM Do YYYY, h:mm:ss a")
      );
      let path =
      this.mainView.property_id && documentGenerateType
          ? `&property_ids[]=${this.mainView.property_id}`
          : "";
      api
        .get(this, api.DOCUMENT + params + path)
        .then((r) => {
          if (r.documents.length == 0) {
            this.allowScrollRequest = false;
            this.documentText = "No data found.";
          } else {
            this.allowScrollRequest = true;
          }
          this.tempDocs = [...this.documents, ...r.documents];
          this.documents = this.tempDocs;
          this.loadingDocumentTemplates = false;

          console.log(
            "After Request: ",
            moment().format("MMMM Do YYYY, h:mm:ss a")
          );
        })
        .catch((err) => {
          this.showMessageNotification({ description: err });
          this.loadingDocumentTemplates = false;
        });
    },

    async fetchNonSignedDocuments() {
      if (!this.fetchUnSignedDocumentUsingScroll) {
        await this.setDocuments([]);
        this.documentText = "Loading Invoices...";
        this.fetchUnSignedDocumentUsingScroll = false;
      }
      let limit = 30;
      let offset =
        this.getDocuments.length == 0
          ? 1
          : Math.ceil(this.getDocuments.length / limit) + 1;
      let documentTypes = "";
      if (this.getDocumentType != "") {
        documentTypes = `document_type=un-signed&type[]=${this.getDocumentType.value}`;
      } else {
        documentTypes = `document_type=un-signed`;
      }
      let path = this.mainView.property_id
        ? `&property_ids[]=${this.mainView.property_id}`
        : "";
      let data = {
        component: this,
        params:
          api.DOCUMENT +
          `?limit=${limit}&offset=${offset}&${documentTypes}` +
          path,
      };
      try {
        const currentDocuments = await this.fetchDocumentsData(data);
        if (currentDocuments.length === 0) {
          this.allowScrollRequest = false;
          this.documentText = "No data found.";
        }
        this.documents = this.getDocuments;
        this.loadingDocumentTemplates = false;
      } catch (err) {
        this.showMessageNotification({ description: err });
        this.loadingDocumentTemplates = false;
      } finally {
        this.loadingDocumentTemplates = false;
      }
    },

    async fetchData(lease, dateRange, customStartDate, customEndDate) {
      this.uploads = [];
      this.loadingDocuments = true;
      if (!lease || lease.id === null || !lease.id) {
        if (this.mainView.property_id) {
          let q = await api.get(
            this,
            api.CONTACTS + this.contactid + "/transactions",
            { property_id: this.mainView.property_id}
          );
          let transactions = q.transactions.tenant_ledger.filter(
            (l) => l.type == "invoice"
          );
          let filteredTransactions = transactions.filter((arr, index, self) => index === self.findIndex((t) => (t.id === arr.id)))
          this.uploads = filteredTransactions;
        } else {
          let q = await api.get(
            this,
            api.CONTACTS + this.contactid + "/transactions/all"
          );
          let transactions = q.transactions.tenant_ledger.filter(
            (l) => l.type == "invoice"
          );
          let filteredTransactions = transactions.filter((arr, index, self) => index === self.findIndex((t) => (t.id === arr.id)))
          this.uploads = filteredTransactions;
        }
      } else {
        if (this.mainView.property_id) {
          let q = await api.get(
          this,
          api.CONTACTS + this.contactid + "/transactions",
          { property_id: this.mainView.property_id, lease_id: lease.id }
        );
        let transactions = q.transactions.tenant_ledger.filter(
          (l) => l.type == "invoice"
        );
        let filteredTransactions = transactions.filter((arr, index, self) =>index === self.findIndex((t) => (t.id === arr.id)))
          this.uploads = filteredTransactions;
        }else{
          let q = await api.get(
            this,
            api.CONTACTS + this.contactid + "/transactions/all",
            { lease_id: lease.id }
          );
          let transactions = q.transactions.tenant_ledger.filter(
            (l) => l.type == "invoice"
          );
          let filteredTransactions = transactions.filter((arr, index, self) => index === self.findIndex((t) => (t.id === arr.id)))
          this.uploads = filteredTransactions;

        }
    
      }
      this.loadingDocuments = false;
    },

    async resetFilters() {
      this.dateRange = "";
      this.customStartDate = "";
      this.customEndDate = "";
      await this.setDocuments([]);
      this.documentText = "Loading Invoices...";
      this.documents = [];
      await this.fetchData(this.mainView);
    },
    async mainViewChanged(x) {
      this.$emit("docSelectedemit", "");
      console.log(x);
      this.mainView = x;
      this.uploads = [];
      await this.resetFilters();
    },
  },
  props: ["contact", "showAside", "contactLeases", "propertyView", "contactid"],
  watch: {
    document() {
      if (this.document.id) {
        this.hasSubmitted = false;
      }
    },

    customStartDate() {
      if (this.customStartDate === "") {
        this.customEndDate = "";
      }
    },
  },
};
</script>

<style lang="scss">
.select-filter {
  border: 1px solid #dfe3e8;
  border-radius: 4px;
  width: 175px;
  height: 40px;
  background: white;
}

.select-filter:hover {
  border: 1px solid #c4cdd5;
  cursor: pointer;
}

.select-filter.download-center-filters-active {
  border: 2px solid #00848e;
}

.document-filters-footer {
  border: 1px solid #dfe3e8;
  border-top: none;
}

.v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
<style scoped>
    .doc-list {
      overflow-y: auto;
    }
    .hb-data-table >>> .v-data-table__wrapper {
      border-left: none !important;
    }
</style>
