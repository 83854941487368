<template>
  <div>
    <v-container class="payment-method-container">
      <div class="mt-3">
        <v-row no-gutters>
          <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
        <v-row  no-gutters v-if="payment_type && payment_type.toLowerCase() === 'cash'" class="mt-3">
        <v-col  class="ma-0 pa-0" :class="{'hb-font-caption-medium' : $vuetify.breakpoint.xs , 'hb-font-body-medium' : !$vuetify.breakpoint.xs}" :cols="$vuetify.breakpoint.xs ? 7: 8">
          <span >Cash Amount Tendered : </span>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 5: 4" class=" ma-0 pa-0">
        <v-row no-gutters >
        <v-col class="pa-0" :cols="(paymentData.amountTendered - paymentData.totalNewPaymentPaying > 0) ? 9 : 12">
          <!-- <small class="small-label" :class="{'text-error' : errors.first('amountTendered') }">Cash Amount Tendered*</small> -->
          <hb-text-field
            box
            key="amountTendered"
            class="cash-input-filed"
            :class="{'custom-field-error' : errors.first('amountTendered') }"
            hide-details
            :value="getPrecisiedVal"
            v-validate="`required|min_value:${paymentData.totalNewPaymentPaying}`"
            data-vv-name="amountTendered"
            data-vv-as="Cash Amount Tendered"
            :error="errors.has('amountTendered')"
            id="amountTendered"
            name="amountTendered"
            label="Cash Amount Tendered*"
            @blur="$validator.validate('amountTendered')"
            @input="handleAmountTendered('amountTendered', $event)"
            condensed
          >
          <template v-slot:prepend-inner>
                 <div>
                  $
                 </div>
          </template>
          </hb-text-field>
        </v-col>
        <v-col v-show="paymentData.amountTendered - paymentData.totalNewPaymentPaying > 0" class="pa-0 ma-0 pl-4" cols="3">
          <div class="text-caption">Change Due</div>
          <div>{{ (paymentData.amountTendered - paymentData.totalNewPaymentPaying) | formatMoney }}</div>
        </v-col>
      </v-row>
       </v-col>
      </v-row>
      
      <v-row class="ma-0" no-gutters>
        <v-col class="pa-0 mb-4">
          <v-text-field
            key="ref_name"
            :class="{'custom-field-error' : errors.first('ref_name')}"
            hide-details
            :value= "payment.ref_name"
            v-validate="'required'"
            data-vv-name="ref_name"
            data-vv-as="Reference Name"
            :error-messages="errors.collect('ref_name')"
            placeholder="Reference Name*"
            id="cash-ref-name"
            name="cash-ref-name"
            autocomplete="cc-cash-ref-name"
            @input="handleInputUpdate('payment', 'ref_name', $event)"
            >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="ma-0" v-if="payment_type && payment_type.toLowerCase() === 'check'">
        <v-col class="pa-0  mt-1">
          <hb-text-field
            box
            key="number"
            :class="{'custom-field-error' : errors.first('number')}"
            hide-details
            :value="payment.number"
            v-validate="payment_type.toLowerCase() === 'check'? 'required': ''"
            data-vv-name="number"
            data-vv-as="Check Number"
            :error="errors.has('number')"
            placeholder="Check Number*"
            id="cash-number"
            name="cash-number"
            autocomplete="cc-cash-number"
            @input="handleInputUpdate('payment', 'number', $event)"
            @blur="$validator.validate('cash-number')"
            >
          </hb-text-field>
        </v-col>
        
      </v-row>

      <v-row class="pa-0 " no-gutters>
        <v-col class="pa-0" :class="{'mt-3': payment_type && payment_type.toLowerCase() === 'check'}">
          <hb-textarea
            :class="{'custom-field-error' : errors.first('notes')}"
            class="pb-3 mt-4"
            hide-details
            :value="payment.notes"
            data-vv-name="notes"
            :error="errors.has('notes')"
            placeholder="Notes"
            id="cash-notes"
            name="cash-notes"
            autocomplete="cc-cash-notes"
            @input="handleInputUpdate('payment', 'notes', $event)"
            backgroundColor=""
          >
          </hb-textarea>
        </v-col>
      </v-row>
             
          </v-col>
        </v-row>        
    </div>
    </v-container>
  </div>
</template>


<script type="text/babel">

    import PaymentMethodBase from './PaymentMethodBase.vue';
    import { EventBus } from '../../../EventBus';
    import { mapGetters, mapMutations } from "vuex";

    export default {
        extends: PaymentMethodBase,
        data() {
            return {
            }
        },
        components:{},
        computed:{
          ...mapGetters({
            paymentConfig: 'paymentsStore/getPaymentSourceConfig',
            paymentData: 'paymentsStore/getPaymentInformation',
            payment_method: 'paymentsStore/getPaymentMethod',
            contact: 'paymentsStore/getContact',
            payment: 'paymentsStore/getPayment',
            getErrors: 'paymentsStore/getErrors'
        }),
          getPrecisiedVal() {
            return this.paymentData.amountTendered ? parseFloat(this.paymentData.amountTendered).toFixed(2) : this.paymentData.amountTendered;
          }
        },
        created(){
        },
        destroyed() {
        },
        props: ['payment_type'],
        methods:{
          ...mapMutations({
            setCheckErrors: 'paymentsStore/setCheckErrors',
            setPaymentInformation: 'paymentsStore/setPaymentInformation',
            setPayment: 'paymentsStore/setPayment',
            setPaymentMethod: 'paymentsStore/setPaymentMethod',
          }),

          handleAmountTendered : _.debounce(async function(property_name, property_value) {
            property_value = Number(parseFloat(property_value).toFixed(2)) || 0;
            this.setPaymentInformation({property: property_name, propertyValue: property_value});
          }, 1000),

          handleInputUpdate : _.debounce(async function(obj ,property_name, property_value) {
            if(obj === 'payment_information'){
              if(property_name === 'amountTendered') property_value = Number(parseFloat(property_value).toFixed(2)) || 0;
              this.setPaymentInformation({property: property_name, propertyValue: property_value});
            }
            if(obj === 'payment'){
              this.setPayment({property : property_name, propertyValue: property_value});
            }
          }, 200),

          validateFields(){
            return this.$validator.validateAll();
          }
        },
        watch:{
        }


    }
</script>
<style scoped>
.payment-method-container{
  display:flex;
  flex-direction: column;
}

.v-text-field{
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.text-error {
  color: red !important;
}

.small-label {
  font-size: 11px; 
  color: grey;
}

.cash-input-filed {
  padding-top: 0px;
  margin-top: -5px;;
}

.payment-method-header{
    background-color: #E0F5F5;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  @media (min-width:600px){
    .payment-method-header{
      margin-left: 0 !important;
    }
  }

  .hb-text-field-width-370 {
    max-width: 100% !important;
  }

  .cash-input-filed >>> fieldset legend{
    display: none !important;
}
</style>
