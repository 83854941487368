import { render, staticRenderFns } from "./HbTask.vue?vue&type=template&id=4443d7e2&scoped=true&"
import script from "./HbTask.vue?vue&type=script&lang=js&"
export * from "./HbTask.vue?vue&type=script&lang=js&"
import style0 from "./HbTask.vue?vue&type=style&index=0&id=4443d7e2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4443d7e2",
  null
  
)

export default component.exports