<template>
  <v-card elevation="0" outlined :style="{ border: ` ${borderColor}` }">
      <v-card-title class="custom-header-charm pt-2 " :style="{ backgroundColor: CardHeaderColor , borderBottom: ` ${borderColor}` }">
        <span class="hb-font-body-medium">Card Information</span>
      </v-card-title>
  <div class="pa-4">
      <v-row >
              
        <div class="charm-ivr-info-card">
          <v-row class="ma-0 pa-0" align="center">
            <v-col cols="auto" class="d-flex justify-center ma-0 pa-0">
              <v-icon color="#3D8FFF" large>mdi-information</v-icon>
            </v-col>
            <v-col class="pl-1 ma-0 pa-0">
              Once card information has been collected, you will have 5 minutes to process the payment before the cached Card Information will expire and need to be recollected.
            </v-col>
          </v-row>
        </div>
       
      </v-row>
      <v-row  dense style="width: 100%;">
       
        <v-col>
          <div v-if=" !cardCollectionComplete" style="display: flex; align-items: center;">
            <hb-btn
            :loading="startCardCollectionLoading"
            :disabled="FormErrors"
            :color="ivrSessionButton === 'Cancel Card Collection' ? 'destructive' : 'primary'"
            @click="startCardCollectionSession()"  
          >
            {{ ivrSessionButton }}
          </hb-btn>
          <hb-icon v-if="cardInfoError" class="ml-auto" :color="'#FB4C4C'" largeClose>mdi-close-circle</hb-icon>
          </div>
          <div v-if=" cardCollectionComplete" style="display: flex; align-items: center;">
            <hb-menu>
              <template v-slot:menu-activator>
                <hb-btn color="secondary">
                  Recollect
                  <hb-icon small color="#000000">mdi-menu-down</hb-icon>
                </hb-btn>
              </template>
              <v-list>
                <v-list-item @click="collectCardDetails('all',false)">
                  <v-list-item-title>Entire Card</v-list-item-title>
                </v-list-item>
                <v-list-item @click="collectCardDetails('card',true)">
                  <v-list-item-title>Card Number</v-list-item-title>
                </v-list-item>
                <v-list-item @click="collectCardDetails('expDate',true)">
                  <v-list-item-title>Expiration Date</v-list-item-title>
                </v-list-item>
                <v-list-item @click="collectCardDetails('cvv',true)">
                  <v-list-item-title>CVV</v-list-item-title>
                </v-list-item>
              </v-list>
            </hb-menu>
            <hb-icon v-if="!cardInfoError" class="ml-auto" :color="'#02AD0F'" largeClose>mdi-check-circle-outline</hb-icon>
          </div>
        </v-col>
      </v-row>
    
      <v-row  dense style="width: 100%;" class="mt-3">
        <v-col cols="12">
          <HbTextField  label="Card Number*" v-model="Card" readonly/>
        </v-col>
      </v-row>
    
      <v-row   dense class="mt-3" >
        <v-col>
          <HbTextField label="MM" v-model="date" readonly/>
        </v-col>
        <v-col>
          <HbTextField  label="YYYY" v-model="year" readonly/>
        </v-col>
        <v-col>
          <HbTextField  label="CVV*" v-model="cvvIvr" readonly/>
        </v-col>
      </v-row>
    </div>
  </v-card>

        
</template>

<script>
import api from '../../../assets/api.js';
import { EventBus } from '../../../EventBus';
import { mapGetters, mapActions } from "vuex";
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
export default {
  name: "charmIvr",
  mixins: [ notificationMixin],
  props: ['saveCard','paymentSource','property_id','contact_id'],
  data() {
    return {
      cvvIvr:"",
      date:"",
      year:"",
      Card:"",
      recollect:false,
      ivrSessionButton:"Start Card Collection",
      staredCardCollectionSession:false,
      paymentSessionData:{},
      startCardCollectionLoading:false,
      cardCollectionComplete:false,
      borderColor:"1px solid #DFE3E8",
      cardInfoError:false,
      //timer 
      timeLeft:0,
      timer: null,
      timerActive: false ,
      receivePayConnectorResponse: false,
      paymentSessionCompleted:false,
      CardHeaderColor:"#F9FAFB",
      FormErrors:false
    };
  },
  created() {
    EventBus.$on('collect-card-info-ivr', this.cardInfo);
    EventBus.$on('complete-ivr-payment-session', this.completePaymentSession);
    EventBus.$on('receive_transfer_data_ivr',this.handleTransferData);
  },
  computed: {
    ...mapGetters({
      invoices: 'onBoardingStore/invoices',
      agentCallSid:'charmCallStore/agentCallSid',
      notification: 'charmCallStore/notification',
      IvrPaymentEnable: 'charmCallStore/IvrPaymentEnable',
      companyId:'authenticationStore/getCid',
      getToken:'authenticationStore/getToken',
      payment: 'paymentsStore/getPayment',
      payment_method: 'paymentsStore/getPaymentMethod',
    }),
    inCall() {
      return this.notification.status === 'active';
    },
   
  },
  methods: {
    formattedTime() {
    const minutes = Math.floor(this.timeLeft / 60);
    const seconds = this.timeLeft % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
},
    startTimer() {
    if (this.timerActive) return;
    this.timerActive = true;
    this.timeLeft = 300; // 5 minutes in seconds
    this.timer = setInterval(() => {
      this.timeLeft--;
        this.ivrTimer(this.formattedTime());
        if (this.timeLeft <= 0) {
            clearInterval(this.timer);
            this.timerActive = false;
           
            this.showMessageNotification({ type: 'error', description:" Cached Card Information has expired and must be recollected."});
            this.cancelCardCollection({borderColor:"1px solid #FB4C4C"})
        }
    }, 1000);
},





    async completePaymentSession(){
                  EventBus.$emit('disable_reader_dropdown');
                  this.ivrPaymentButtonLoading(true)
                  clearInterval(this.timer);
                  this.timerActive = false;
                  this.timeLeft = 0;
                  this.ivrTimer("");
                  // setTimeout(() => {
                  // if(! this.receivePayConnectorResponse){
                  //     this.cancelCardCollection({borderColor:"2px solid #FB4C4C"});
                  //     let error = [`Invalid Card Information was provided.` ,
                  //     ` Card Information entry is cancelled.`,
                  //     `Click Start Card Collection to re-start collection of Card Information.`]
                  //     this.showMessageNotification({ type: 'error', list:  error});
                  //     this.cardInfoError = true
                  //    }
                  // }, 120000);
            await api.put(this.$app, api.UPDATE_CARD_COLLECTION, {
                callSid: this.notification.twilioDetails?.call,
                status: "complete",
                paymentSid: this.paymentSessionData.Sid || this.paymentSessionData.sid
              })
                .then(() => {
                  this.paymentSessionCompleted = true
                  console.log("Payment Session Completed")
                }).catch(()=>{
                  this.showMessageNotification({ type: 'error', description:"The payment session encountered an error. Something went wrong."});
                })   
    },
    ...mapActions({
      ivrPaymentSessionStarted:'charmCallStore/ivrPaymentSessionStarted',
      getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject',
      ivrPaymentButtonLoading:'charmCallStore/ivrPaymentButtonLoading',
      enableIvrProcessPayment:'charmCallStore/enableIvrProcessPayment',
      paymentConnectorResponse:'charmCallStore/paymentConnectorResponse',
      generateInvoice: 'onBoardingStore/generateInvoice',
      ivrTimer: 'charmCallStore/ivrTimer',
      checkSaveCardErrors:'paymentsStore/checkSaveCardErrors'
          }),
    resetData(){
          this.cvvIvr=""
          this.date=""
          this.year=""
          this.Card=""
          this.ivrSessionButton="Start Card Collection"
          this.staredCardCollectionSession=false
          this.ivrPaymentSessionStarted(false)
          this.paymentSessionData={}
          this.startCardCollectionLoading=false
          this.cardCollectionComplete=false
          this.enableIvrProcessPayment(false)
          this.paymentConnectorResponse("")
          this.ivrPaymentButtonLoading(false)
          clearInterval(this.timer);
          this.timerActive = false;
          this.timeLeft = 0;
          this.ivrTimer("");
          this.receivePayConnectorResponse = false
          EventBus.$emit('enable_reader_dropdown');
    },
    async collectCardDetails(key,recollect = false) {
    this.recollect = recollect
    const captureOptions = {
        card: { capture: "payment-card-number", captureText: "Payment Card Number" },
        cvv: { capture: "security-code", captureText: "Security Code" },
        expDate: { capture: "expiration-date", captureText: "Expiration Date" }
    };
    if(recollect){
      if(key == 'card') this.Card=""
      if(key == 'cvv') this.cvvIvr=""
      if(key == 'expDate') this.date="" , this.year = ""
     
    }
    if(key == "all"){
      await this.cancelCardCollection()
      this.startCardCollectionSession()
      return
    }
   

    const captureData = captureOptions[key];
    if (!captureData) {
        console.error('Invalid key provided for card details collection');
        return;
    }

    if (this.staredCardCollectionSession) {
        api.put(this.$app, api.COLLECT_CARD_DETAILS, {
            capture: captureData.capture,
            conference_name: this.notification.twilioDetails?.conference_name,
            callSid: this.notification.twilioDetails?.call,
            paymentSid: this.paymentSessionData.Sid || this.paymentSessionData.sid
        })
        .then(() => {
          console.log(`${captureData.captureText} Collection Started`)
            // this.showMessageNotification({ type: 'success', description: `${captureData.captureText} Collection Started` });
        })
        .catch(err => {
            this.showMessageNotification({ type: 'error', description: err });
            this.cancelCardCollection();
            console.log(err);
        });
    }
},

    async cancelCardCollection({borderColor}={}){
      if(borderColor){
        this.borderColor = borderColor
        this.CardHeaderColor = "#F8E5E1"
      }else{
        this.borderColor = "1px solid #DFE3E8"
        this.CardHeaderColor = "#F9FAFB"
      }
      await api.put(this.$app, api.UPDATE_CARD_COLLECTION, {
        callSid: this.notification.twilioDetails?.call,
        status: "cancel",
        paymentSid: this.paymentSessionData.Sid || this.paymentSessionData.sid
      })
        .then(() => {
          // this.showMessageNotification({ type: 'success', description: "Payment Session Cancelled"});
          
        }).catch(err=>{
          console.log(err,"error")
        })
        .finally(()=>{
             this.resetData()
        })

    },

  generateObjectWithValues(source, target) {
      Object.keys(source).forEach((key) => {
        const value = source[key];
        if (value !== undefined && value !== "" && value !== null) {
          target[key] = value;
          if(key === 'save_to_account') target[key] = true;
        }
      });
      return target;
    },
    async handleTransferData(data){
              data.paymentInfo.url = 'TRANSFERS'
              await this.startCardCollection(data.paymentInfo);
            },
  async startCardCollectionSession() {
    console.log(this.paymentSource, "this.paymentSource",this.saveCard);
    this.startCardCollectionLoading = true;

    if (this.staredCardCollectionSession || this.ivrSessionButton !== "Start Card Collection") {
        await this.cancelCardCollection();
        return;
    }

    let paymentInfo = {};
    
    if (this.saveCard) {
        this.payment.type = 'saveCard';
        paymentInfo = {
            payment: this.generateObjectWithValues(this.payment, {}),
            property_id: this.property_id,
            contact_id: this.contact_id,
            payment_method: this.generateObjectWithValues(this.payment_method, {}) 
        };
        let error = await this.checkSaveCardErrors(paymentInfo);
        paymentInfo.url = "saveCard"
        paymentInfo.errors = error?.errors;
    } else if (this.paymentSource === 'MOVE_IN') {
        paymentInfo = await this.handleMoveInPayment();
    }else if (this.paymentSource === 'TRANSFERS') {
         EventBus.$emit('get_data_for_transfer_charm_ivr') 
         return        
    } else {
        paymentInfo = await this.getTransformedPaymentObject({
            id: this.$options.name,
            type: "ivr"
        });
        paymentInfo.url = "bulk";
    }



    await this.startCardCollection(paymentInfo);
},

async handleMoveInPayment() {
    if (!this.invoices[0]?.id) {
        try {
            await this.generateInvoice();
            EventBus.$emit('updatePaymentApplication');
        } catch (err) {
            let response = { error: err };
            EventBus.$emit('enablePayment', response);
            throw err;
        }
    } else {
        EventBus.$emit('updatePaymentApplication');
    }

    let paymentInfo = await this.getTransformedPaymentObject({
        id: this.$options.name,
        formErrors: this.formErrors,
        type: "ivr"
    });

    if (paymentInfo.errors && paymentInfo.errors.length) {
        this.showMessageNotification({ list: paymentInfo.errors });
        this.startCardCollectionLoading = false;
        throw new Error("Form errors");
    }

    const { payment, paymentMethod, contact_id, property_id, Invoices, use_credits, auto_pay_leases } = paymentInfo;
    return {
        payment,
        paymentMethod,
        contact_id,
        property_id,
        use_credits,
        auto_pay_leases,
        Invoices: Invoices.map(i => ({
            id: i.id,
            amount: i.amount,
            credits_amount_used: i.credits_amount_used
        })),
        paymentSource: 'MOVE_IN',
        url: "bulk"
    };
},

async startCardCollection(paymentInfo) {

  if (paymentInfo.errors && paymentInfo.errors.length) {
        this.showMessageNotification({ list: paymentInfo.errors });
        this.startCardCollectionLoading = false;
        return;
    }
    paymentInfo.payment.type = "card";
    paymentInfo.companyId = this.companyId;
    paymentInfo.authorization = this.getToken;
    paymentInfo.origin = window.location.hostname;

    try {
        const data = await api.post(this.$app, api.START_CARD_COLLECTION, {
            callSid: this.notification.twilioDetails?.call,
            chargeAmount: paymentInfo.payment.amount,
            paymentInfo: paymentInfo,
            postalCode: false,
        });
        this.ivrSessionButton = "Cancel Card Collection";
        this.staredCardCollectionSession = true;
        this.ivrPaymentSessionStarted(true)
        this.paymentSessionData = data;
        this.collectCardDetails("card");
        this.borderColor = '1px solid #3D8FFF';
        this.CardHeaderColor =  '#E7F1FF';
        this.cardInfoError = false;
    } catch (err) {
        console.error("API Error:", err);
        this.showMessageNotification({ type: 'error', description: err ? err : "something went wrong" });
    } finally {
        this.startCardCollectionLoading = false;
    }
}
,





  
  cardInfo(info) {
    
    if( this.paymentSessionData.sid   != info?.Sid ) return
    // console.log("payment_ifo",info)
    if (info?.PaymentError || info?.ErrorType) {
        this.cancelCardCollection({borderColor:"1px solid #FB4C4C"});     
        if(info?.PayErrorCode == 504){ 
          this.showMessageNotification({ type: 'error', description:  'The server took too long to respond. Please try again later.'});
        }else{
          let error = [`Invalid Card Information was provided.` ,
        ` Card Information entry is cancelled.`,
         `Click Start Card Collection to re-start collection of Card Information.`]
         this.showMessageNotification({ type: 'error', list:  error});
        }
       
        this.cardInfoError = true
        // this.showMessageNotification({ type: 'error', description: `${info?.PaymentError || info?.ErrorType || "Something went wrong"}` });
        return;
    }
    if( info.Result == 'transaction-cancelled'){
      this.showMessageNotification({ type: 'error', description:info.Result });
      this.cancelCardCollection();
      return
    }

    if (info && !info?.PaymentError && info?.Result !== "transaction-cancelled") {
        this.updateCardDetails(info);
    }

    this.handleCardCaptureSteps(info);
    if(this.cardCollectionComplete && info.PayConnector_paymentInfo){
      this.receivePayConnectorResponse = true
      let Response = JSON.parse(info.PayConnector_paymentInfo) 
      if(Response.status == 200){
        this.paymentConnectorResponse(Response)
      }else{
        this.cancelCardCollection();
        this.paymentConnectorResponse(Response)
      }
      EventBus.$emit('enable_reader_dropdown');

    }
},

updateCardDetails(info) {
    if (info?.PaymentCardNumber) {
        let card_number =  info.PaymentCardNumber.replace(/\x/g, "•").replace(/(.{4})/g, "$1 ").trim();
        this.Card = card_number;
    }
    if (info?.SecurityCode) {
      let cvv = info.SecurityCode.replace(/\x/g, (match) => '•'.repeat(match.length)).trim();
      this.cvvIvr = cvv;
    }
    if(info?.ExpirationDate){
      if(info.ExpirationDate.length == 2 || info.ExpirationDate.length == 4){
        this.updateExpirationDate(info.ExpirationDate);
      }
      
    }

},

handleCardCapture(info, nextDetail, message) {
    if (!info?.PartialResult) {
        if(!this.recollect){
          this.collectCardDetails(nextDetail);
        }else{
          this.showMessageNotification({ type: 'success', description: message });
        }
        console.log(message)
        
    }
},

handleCardCaptureSteps(info) {
    switch (info?.Capture) {
        case 'payment-card-number':
            this.handleCardCapture(info, 'cvv', 'Card Number Captured Successfully.');
            break;
        case 'security-code':
            this.handleCardCapture(info, 'expDate', 'CVV Captured Successfully.');
            break;
        case 'expiration-date':
            if (!info?.PartialResult) {
                this.updateExpirationDate(info.ExpirationDate);
                if(this.recollect) return
                this.cardCollectionComplete = true;
                this.borderColor = '1px solid #02AD0F';
                this.CardHeaderColor =  '#E1FAE3';
                // this.showMessageNotification({ type: 'success', description: 'Expiry Date Captured Successfully.' });
                this.showMessageNotification({ type: 'success', description: 'Tenant Completes Card Info Collection.' });
                this.enableIvrProcessPayment(true)
                this.startTimer()
            }
            break;
    }
}
,
updateExpirationDate(expirationDate) {
    const monthIndex = parseInt(expirationDate.slice(0, 2), 10) - 1;
    if (monthIndex >= 0 && monthIndex < 12) {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        this.date = months[monthIndex];
        this.year = '20' + expirationDate.slice(-2);
    }
}


  },
  beforeDestroy() {
    EventBus.$off('collect-card-info-ivr', this.cardInfo);
    EventBus.$off('complete-ivr-payment-session', this.completePaymentSession);
    EventBus.$off('receive_transfer_data_ivr',this.handleTransferData)    
    this.cancelCardCollection()  
    if(this.timer){
      clearInterval(this.timer);
    }
      
  },
watch:{
  payment_method: {
    handler: async function () {
      let paymentInfo 
      if (this.saveCard) {
        this.payment.type = 'saveCard';
        paymentInfo = {
            payment: this.generateObjectWithValues(this.payment, {}),
            property_id: this.property_id,
            contact_id: this.contact_id,
            payment_method: this.generateObjectWithValues(this.payment_method, {}) 
        };
        let error = await this.checkSaveCardErrors(paymentInfo);
        paymentInfo.errors = error?.errors;
    }else{
      paymentInfo = await this.getTransformedPaymentObject({
            id: this.$options.name,
            type: "ivr"
        });
    }
      console.log(paymentInfo,"error",paymentInfo?.errors?.length,!!paymentInfo?.errors?.length )
      this.FormErrors = !!paymentInfo?.errors?.length
    },
    deep: true
  }
}
};
</script>
<style scoped>
.charm-ivr-card-div {
  border-radius: 4px;  
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.charm-ivr-info-card{
  border-radius: 4px;  
  border: 1px solid #3D8FFF;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background-color: #E7F1FF;
  margin: 8px 12px;

}

.custom-header-charm {
  padding-bottom: 8px;
}
</style>
