export default [
	{
		path: '/sign-documents/:hash',
		component: () => import('../components/signing/SignDocuments.vue'),
		meta: {
			layout: 'unauthenticated',
      		blank: true,
			requiresAuth: false,
			hasAccess: ['sign']
		}
	}
]
