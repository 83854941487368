<template>

    <div class="pa-0 slide-out-section">
        <!-- <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status> -->
 

        <div v-show="!show_retire">

            <hb-form label="Reservation Time" v-if="type.toLowerCase() === 'reservation'">
                {{convertToLocalTime(cachedLead.Reservation.time) | changeDateTimeFormat}}
            </hb-form>

            <hb-form label="Reservation Expires" v-if="type.toLowerCase() === 'reservation'">
                {{convertToLocalTime(cachedLead.Reservation.expires) | changeDateTimeFormat}}
            </hb-form>


            <hb-form label="Lead Type">
                {{ cachedLead.lead_type || 'Lead'}}
            </hb-form>

            <!-- <hb-form  v-if="type.toLowerCase() === 'lead'" label="Property Reference"  @cancel="clearAllEditing" @save="save" :active.sync="active_edit_field" :editable="!readonly" :empty="!cachedLead.property_id">
                <template v-slot:display> {{ cachedLead.Property.name }} </template>
                <template v-slot:edit> 
                    <v-autocomplete
                        hide-details
                        label="Choose One"
                        single-line
                        id="property_id"
                        name="property_id"
                        :items="properties"
                        item-text="name"
                        item-value="id"
                        v-model="cachedLead.property_id"
                    ></v-autocomplete>
                </template>
            </hb-form> -->

             <hb-form label="Property Reference">
                {{ cachedLead.Property.name }}
            </hb-form>

            <!-- <hb-form label="Source/ Channel /Medium"  @cancel="clearAllEditing" @save="save" :active.sync="active_edit_field" :editable="!readonly" >
                <template v-slot:display> 
                    {{ (cachedLead.Touchpoint.referrer_source || cachedLead.Touchpoint.referrer_channel || cachedLead.Touchpoint.referrer_medium) 
                    ? `${cachedLead.Touchpoint.referrer_source? cachedLead.Touchpoint.referrer_source: '-'} / ${cachedLead.Touchpoint.referrer_channel? cachedLead.Touchpoint.referrer_channel: '-'} / ${cachedLead.Touchpoint.referrer_medium? cachedLead.Touchpoint.referrer_medium: '-'}` 
                    : '-' }}
                </template>
                <template v-slot:edit>
                    <v-text-field
                        flat
                        label="Enter Source"
                        single-line
                        hide-details
                        autofocus
                        v-validate="'max:45'"
                        name="message"
                    ></v-text-field>
                </template>
            </hb-form> -->

            <hb-form label="Source/ Channel /Medium">
                {{ (cachedLead.Touchpoint.referrer_source || cachedLead.Touchpoint.referrer_channel || cachedLead.Touchpoint.referrer_medium) 
                    ? `${cachedLead.Touchpoint.referrer_source? cachedLead.Touchpoint.referrer_source: '-'} / ${cachedLead.Touchpoint.referrer_channel? cachedLead.Touchpoint.referrer_channel: '-'} / ${cachedLead.Touchpoint.referrer_medium? cachedLead.Touchpoint.referrer_medium: '-'}` 
                    : '-' }}
            </hb-form>
            
            <!-- <hb-form v-if="type.toLowerCase() === 'lead'" label="Space Reference"  @cancel="clearAllEditing" @save="save" :active.sync="active_edit_field" :editable="!readonly" :empty="lead && !lead.Unit.number">
                <template v-slot:display>{{ lead.Unit.number }}</template>
                <template v-slot:edit>
                    <v-progress-circular v-if="loadingUnits" :indeterminate="loadingUnits" width="2" size="24" class="ma-0 pa-0 mb-n1" color="primary"></v-progress-circular>
                    <v-autocomplete
                        v-else
                        hide-details
                        label="Choose One"
                        single-line
                        id="unit_id"
                        name="unit_id"
                        :items="units"
                        item-text="number"
                        item-value="id"
                        v-model="cachedLead.unit_id"
                    >
                        <template v-slot:append-item>
                            <div class="intersect-design pl-4" v-intersect="loadMoreUnits">
                              Loading...
                            </div>
                        </template>
                    </v-autocomplete>
                </template>
            </hb-form> -->

            <hb-form label="Space Reference">
                {{ (cachedLead?.Lease?.Unit?.number? cachedLead?.Lease?.Unit?.number : cachedLead.Unit?.number) || '-'}}
            </hb-form>

            <hb-form label="Space Details">
                <template v-slot:tooltipBody>
                  <div>
                    Type | Size | Price | Floor
                  </div>
              </template>
                <span v-html="formattedSpaceDetails"></span>
            </hb-form>


            <hb-form label="Features and Amenities">
                {{
                    cachedLead?.lead_questions?.features_and_amenities?.map(
                    (data) => `${data.key} (${data.value})`).join(', ') || '-'
                }}
            </hb-form>

            
            <!-- <hb-form label="Probable Move-In" 
                    @cancel="clearAllEditing"
                    @save="save" 
                    :active.sync="active_edit_field" 
                    :editable="!readonly" 
                    :empty="!cachedLead.move_in_date">
            <template v-slot:display> 
                {{ cachedLead.move_in_date | formatDate }}
            </template>
            <template v-slot:edit>
                <hb-date-picker
                :adjust-for-contact-details="true"
                id="move_in_date"
                name="move_in_date"
                label="Move In Date"
                v-model="cachedLead.move_in_date"
                ></hb-date-picker>
            </template>
            </hb-form> -->

            <hb-form label="Probable Move-In">
                {{ cachedLead.move_in_date || '-' }}
            </hb-form>

            <hb-form label="Storage Duration"  
                    @cancel="clearAllEditing" 
                    @save="save" 
                    :active.sync="active_edit_field" 
                    :editable="!readonly" 
                    :empty="!cachedLead.length_of_stay">
            <template v-slot:display> 
                {{ cachedLead.length_of_stay ? cachedLead.length_of_stay : '-' }}
            </template>
            <template v-slot:edit>
                <HbSelect
                v-model="cachedLead.length_of_stay"
                :items="length_of_stays"
                placeholder="Select storage duration"
              />
            </template>
            </hb-form>

            <hb-form label="Additional Questions">
                <!-- Check if there is at least one answer that is not null -->
                <div v-if="!cachedLead.lead_questions?.custom_questions?.some(question => question.answer !== null)">
                    <p>-</p>
                </div>

                <div v-else>
                    <div v-for="(item, index) in cachedLead.lead_questions.custom_questions" :key="index">
                        <p>{{ index + 1 }}. {{ item.question }}</p>
                        <p>{{ item.answer?.option || item.answer || '-' }}</p>
                    </div>
                </div>
            </hb-form>


            <hb-form label="External Notes"  
                    @cancel="clearAllEditing" 
                    @save="save" 
                    :active.sync="active_edit_field" 
                    :editable="!readonly" 
                    :empty="!cachedLead.content">
            <template v-slot:display> 
                {{ cachedLead.content }}
            </template>
            <template v-slot:edit>
                <v-textarea
                hide-details
                auto-grow
                flat
                rows="2"
                label="Enter Message"
                single-line
                v-model="cachedLead.content"
                v-validate="'max:1000'"
                name="message"
                autofocus
                class="custom-v-textarea-wrapper"
                ></v-textarea>
            </template>
            </hb-form>

            <hb-form label="Created By" >
                {{cachedLead.CreatedBy.name }}
            </hb-form>

            <hb-form label="Retired Reason" v-if="type.toLowerCase() === 'retired'">
                {{cachedLead.retire_reason }}
            </hb-form>
            <hb-bottom-action-bar
                cancel-off
                :top-border="false"
                v-if="!leadTypes.includes(type) && !hideActionButtons"
            >
                <template v-slot:right-actions>
                    <hb-btn @click="goToPending" v-if="type.toLowerCase() === 'pending'">Finish Move-in</hb-btn>
                    <hb-btn 
                    @click="goToLeadIntake" 
                    v-if="type.toLowerCase() === 'reservation'" 
                    >
                    {{ lead.is_reservation_lead == 1 ? 'Manage Reservation' : 'Follow Up Lead' }}
                    </hb-btn>                    
                    <hb-btn @click="goToLeadIntake" v-if="type.toLowerCase() === 'lead'">Follow Up Lead</hb-btn>
                    <hb-btn v-if="hasPermission('retire_lead')" color="destructive" @click="show_retire = true;">  {{ lead.is_reservation_lead == 1 &&  type?.toLowerCase() == 'reservation' ? retireCancelLeadReservation[1] : retireCancelLeadReservation[0] }}</hb-btn>
                </template>
            </hb-bottom-action-bar>

        </div>

        <div v-if="show_retire">
        
            <hb-notification
            v-if="retireReservationsInfo && lead.is_reservation_lead == 1 &&  type?.toLowerCase() == 'reservation' " 
                type="guidance"
                @close="retireReservationsInfo = false"
            >
            Cancelling the reservation will remove this lead interaction.
            </hb-notification>

        
            <hb-form label="Reason" required>
               <v-select
                    hide-details
                    label="Choose Reason"
                    single-line
                    :items="reasons"
                    v-model="retire.reason"
                    lable="Choose Reason"
                    v-validate="'required'"
                    required
                    id="lead_reason"
                    name="lead_reason"
                    data-vv-as="reason"
                    @blur="handleBlur"
                > </v-select>

                <!-- <v-text-field
                    v-show="retire.reason == 'Other'"
                    required
                    v-model="customRetireReason"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-as="Retire Reason"
                    placeholder="Retire Reason"
                    :error="errors.has('retireReason')"
                /> -->
            </hb-form>
           

            <hb-form label="Opt-Out">
               <v-checkbox
                    label="Remove tenant from all future communication."
                    solo
                    flat
                    hide-details
                    single-line
                    v-model="retire.opt_out"
                    id="lead_OptOut"
                    name="lead_OptOut"
                    data-vv-as="opt"
                    class="pa-0 mt-0"
                ></v-checkbox>
            </hb-form>
            
            <hb-form :label="(lead.is_reservation_lead == 1 &&  type?.toLowerCase() == 'reservation') ? 'Notes for Cancelling Reservation' : 'Notes for Retiring Lead'" required>
                <v-textarea
                    hide-details
                    auto-grow
                    flat
                    rows="6"
                    :label="(lead.is_reservation_lead == 1 &&  type?.toLowerCase() == 'reservation' ) ? 'Why are you cancelling this reservation?' : 'Why are you retiring this lead?'"
                    single-line
                    v-model="retire.notes"
                    v-validate="'required'"
                    name="retire_reason"
                    data-vv-as="Notes"
                    class="pa-0 mt-0"
                    :error="errors.has('lead_notes')"
                    @blur="handleBlur"
                ></v-textarea>
            </hb-form>

            <hb-bottom-action-bar
                @close="show_retire = false"
                :top-border="false"
            >
                <template v-slot:right-actions>
                    <hb-btn
                        v-if="hasPermission('retire_lead')"
                        color="destructive"
                        @click="retireLead"
                    >
                    {{ lead.is_reservation_lead == 1 &&  type?.toLowerCase() == 'reservation' ? retireCancelLeadReservation[1] : retireCancelLeadReservation[0] }}
                    </hb-btn>
                </template>
            </hb-bottom-action-bar>

        </div>
</div>
</template>

<script type="text/babel">

    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    import api from '../../assets/api.js';
    import Dropdown from '../assets/Dropdown.vue';
    import DropdownMenu from '../assets/DropdownMenu.vue';
    import HbDatePicker from '../assets/HummingbirdDatepicker';

    import { EventBus } from '../../EventBus.js';
    import Autocomplete from '../assets/Autocomplete.vue';
    import { mapGetters, mapActions } from 'vuex';
    import { notificationMixin } from  '../../mixins/notificationMixin.js';
    import LEASE from '@/constants/lease.js'; 
    
    export default {
        name: "ViewLead",
        mixins: [notificationMixin],
        data() {
            return {
                active_edit_field: null,
                editing: false,
                edit: '',
                loaded:false,
                show_retire: false,
                retire: {
                    reason: "No longer required",
                    notes: '',
                    opt_out: false
                }, 
                cachedLead: {},
                convertSelector: '',
                id: null,
                message: null,
                status: null,
                reminder: null,
                ampm: 'AM',
                
                units: [],
                paging: {},
                reasons: LEASE.RETIRE_LEAD_REASONS,
                radios: 'radio-1',
                hovering: {
                    content: false,
                    source: false,
                    category: false,
                    facility: false,
                    space: false
                },
                loadingUnits: false,
                customRetireReason:"",
                retireReasons:[],
                propertyOffSet:'',
                defaultLimit: 50,
                retireCancelLeadReservation:['Retire This Lead', 'Cancel Reservation'],
                leadTypes: ["Lead Retired on", "Reservation Cancelled on"],
                retireReservationsInfo: true,
                length_of_stays: ['1-3 Months', '3-6 Months', '6-12 Months', '1+ Year'],
            }
        },
        computed:{
            isEditing() { return !this.lead.id || this.editing},
             ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin',
                properties: 'propertiesStore/filtered',
                categories: 'filterStore/unit_category',
                spaceMix: "settingsStore/unitMixes",
                hasPermission: 'authenticationStore/rolePermission',
            }),
            isLeadsView() {
                return this.type.toLowerCase() === 'lead';
            },
            showSpaceMix() {
                if (!this.cachedLead.spaceMixId) {
                    return 'Please select a space mix'
                }
                let spaces = this.spaceMixOptions?.find(
                        val => val.value === this.cachedLead?.spaceMixId
                    )
                return spaces['name']
            },
            spaceMixOptions() {
                let spaceMixArr = this.spaceMix?.map(c => {
                    return {
                        ...c,
                        value: c.id
                    }
                });
                return spaceMixArr
            },
            formattedSpaceDetails() {
                const spaceInterest = this.cachedLead?.lead_questions?.space_interest;

                if (spaceInterest) {
                    const type = spaceInterest.type || '-';
                    const size = spaceInterest.size || '-';
                    const price = spaceInterest.price || '-';
                    const floor = spaceInterest.floor || '-';

                    // Check if all values are default '-'
                    if (type === '-' && size === '-' && price === '-' && floor === '-') {
                        return '-';
                    }

                    return `${type} <strong>|</strong> ${size} <strong>|</strong> ${price} <strong>|</strong> ${floor}`;
                }

                return '-';
            }
        },
        async created(){
            this.contact_id = this.contact.id;
            // EventBus.$on('lead_saved', this.fetchData);
            EventBus.$on('interaction_saved', this.fetchData);
            this.cachedLead = JSON.parse(JSON.stringify(this.lead));
           // await this.fetchData();
           await this.fetchSpaceMix();
           this.getUnits();
           this.getRetireReasons()


        },
        beforeDestroy(){
            EventBus.$off('interaction_saved', this.fetchData);
            // EventBus.$off('lead_saved', this.fetchData);
        },   
        components:{
            Loader,
            Status,
            Dropdown,
            DropdownMenu,
            Autocomplete,
            HbDatePicker
        },
        filters:{

            unitNumber(lead){
                return lead.lease_id ? lead.Lease.Unit.number : lead.Unit.number;
            }
        },

        methods: {

            async goToPending(){
                await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === this.lead.property_id));
                await this.$store.dispatch('onBoardingStore/getPending', { unit_id: this.lead.unit_id });
                var data = {
                    id: this.lead.id,
                    contact_id: this.lead.contact_id,
                    lead_first: this.lead.Contact.first,
                    lead_last: this.lead.Contact.last,
                    expires: this.lead.Reservation.expires,
                    lease_id: this.lead.lease_id,
                    reservation_id: this.lead.Reservation.id,
                    reservation_time: this.lead.reservation_time,
                    unit_id: this.lead.lease_id ? this.lead.Lease.unit_id : this.lead.unit_id,
                    selectedLeadId: this.lead.id
                }
                this.actionFollowUpStatus(true) // following pending leads
                EventBus.$emit('HB-Navigation:LeadFollowUp', data);
                // EventBus.$emit('new_lead');
                this.$emit('close');
            },
            convertToLocalTime(dateString) {
                if (dateString) {
                    const localTime = moment.utc(dateString).local();
                    return localTime.format('YYYY-MM-DD HH:mm:ss');
                }
                return '';
            },
            async goToLeadIntake(){
                
                //await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === r.Lease.Unit.property_id));

                var contact = this.contact;
                var data = {
                    id: this.lead.id,
                    contact_id: this.lead.contact_id,
                    lead_first: this.lead.Contact.first,
                    lead_last: this.lead.Contact.last,
                    expires: this.lead.Reservation.expires,
                    lease_id: this.lead.lease_id,
                    reservation_id: this.lead.Reservation.id,
                    reservation_time: this.lead.reservation_time,
                    unit_id: this.lead.lease_id ? this.lead.Lease.unit_id : this.lead.unit_id,
                    selectedLeadId: this.lead.id,
                }
                // setting follow up flag in store to handle refresh scenario
                this.actionFollowUpStatus(true)

                EventBus.$emit('HB-Navigation:LeadFollowUp', data);
                //await this.$store.dispatch('onBoardingStore/getReservation', data);
                //EventBus.$emit('new_lead');
                this.$emit('close');
            },


            //TODO This should be a global function
            slugify(text){
                return text.toString().toLowerCase()
                    .replace(/\s+/g, '-')           // Replace spaces with -
                    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                    .replace(/^-+/, '')             // Trim - from start of text
                    .replace(/-+$/, '');            // Trim - from end of text
            },


            //TODO refactor to you one function and pass status
            //TODO update to use patch
            
            async retireLead(){
                // const specialRegex = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/; 
                let status = await this.validate(this);
                if(!status){
					this.showMessageNotification({ description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                    return;
                }
                // if(this.retire.reason == "Other" && this.customRetireReason==""){
                //     return;
                // }
                // else {
                //     this.retire.reason = this.customRetireReason
                // }
                if(!this.retire.notes){
                    // this.showMessageNotification({ description: 'Please enter a reason for retiring this lead' });
                    return;
                }
                try {
                    await api.put(this, api.LEADS + this.lead.id + '/retire', this.retire); 
                    EventBus.$emit('lead_saved');
                    this.$emit('lead_saved');
                    this.show_retire = false;
                    this.retire = {
                        reason: "No longer required",
                        notes: '',
                        opt_out: false
                    }
                    let message = this.lead.is_reservation_lead == 1 &&  this.type?.toLowerCase() == 'reservation'  ? 'Successfully cancelled reservation' : 'Lead retired successfully'
                    this.showMessageNotification({ type:'success',description: message});
                    
                    
                } catch (error) {
                    this.showMessageNotification({ type:'error', description: error});
                    
                }
            },
            
            // fetchData(){
                
            //     api.get(this, api.CONTACTS + this.contact_id + '/leads').then(r => {
            //         this.leads = JSON.parse(JSON.stringify(r.leads));         
            //     })

            // },
           
            editTask: function(lead) {

                if(!this.units.length){
                  this.getUnits();
                }
                if(!this.properties.length) {
                    this.getProperties();
                }
                if(!this.categories.length) {
                  this.getCategories();
                }
                this._beforeEditingCache = Object.assign({}, lead);
                // this.editing = true;
            },
            cancelEdit: function(lead) {
                Object.assign(lead, this._beforeEditingCache);
                this.editing = false;
            },
            deleteMessage(){
                api.delete(this,  api.LEADS, this.$route.params.lead_id).then(r => {
                    this.$router.replace('/leads');
                });

            },
            
            getProperties(initial){
                api.get(this, api.PROPERTIES + 'list').then(r => {
                    this.properties = r.properties;
                });
            },
            async loadMoreUnits(entries, observer, loadUnits) {
                if (loadUnits) {
                    let urlParams = new URLSearchParams(this.paging?.next);
                    let offset = urlParams.has('offset') ? urlParams.get('offset') : this.defaultLimit;
                    if(offset < this.paging?.total)
                        await this.getUnits(false, offset);
                }
            },
            async getUnits(initial, offset = 0){
                let { property_id } = this.lead;
                if(!offset){
                    this.loadingUnits = true;
                    this.units = [];
                    if(!property_id || property_id.length === 0) {
                        return;
                    }
                }
                var params = {
                    limit: this.defaultLimit,
                    offset: offset
                }
                await api.get(this, api.PROPERTIES + property_id + '/units', params, 'unitFinder').then(r => {
                    this.units = [...this.units, ...r.units];
                    this.paging = r.paging;
                    if(initial){
                        this.$forceUpdate();
                    }
                }) .catch((error) => {
                    this.showMessageNotification({ description: error });
                })
                .finally(() => {
                    this.loadingUnits = false;
                });

            },        
            async save(){
                 
                try {
                    // let status = await this.validate(this); 
                    // if(!status) return;
                    var save = {
                        source: this.cachedLead.source,
                        property_id: this.cachedLead.property_id,
                        unit_id: this.cachedLead.unit_id,
                        category_id: this.cachedLead.category_id,
                        content: this.cachedLead.content,
                        subject: this.cachedLead.subject,
                        move_in_date: this.cachedLead.move_in_date,
                        note: this.cachedLead.note,
                        spaceMixId: this.cachedLead?.spaceMixId,
                        length_of_stay: this.cachedLead?.length_of_stay,
                    }
                    console.log(this.lead)
                    let r = await api.put(this, api.CONTACTS + this.contact_id + '/lead/' + this.lead.id,  save);
                    //  this.cachedLead = JSON.parse(JSON.stringify(this.lead));
                    
                    this.$emit('lead_saved');
                    EventBus.$emit('lead_saved');
                     this.clearAllEditing();
                } catch(err){
                    console.log(err); 
                }
        


            },
                        
            clearAllEditing(){
               // this.lead = JSON.parse(JSON.stringify(this.cachedLead)); 
               // this.lead.Category = this.categories.find(c => c.id = this.lead.category_id); 
                console.log("this.lead.Category", this.lead.Category); 
                this.cachedLead = JSON.parse(JSON.stringify(this.lead));
                this.active_edit_field = null;
            },    
        ...mapActions({
                fetchSpaceMix: "settingsStore/fetchCategories",
                actionFollowUpStatus:"onBoardingStore/actionFollowUpStatus",
            }),
            getRetireReasons(){
                api.get(this, api.LEADS + 'retire-reasons').then(r => {
                    this.reasons = r;
                });
            },
            handleBlur(event) {
                this.$validator.reset();
            },
        },
        props: {
            contact: {
                type:Object
            },
            lead: {
                type:Object
            },
            readonly: {
                type:Boolean
            }, 
            hideActionButtons: {
                type:Boolean
            },
            type: {
                type: String
            }
        },
        watch:{
            contact(contact){
                this.contact_id = contact.id;
            },

            'lead.property_id'(){
                if(this.lead.property_id){
                    this.getUnits();
                }
                this.lead.unit_id = null;
            },
            is_editable: {
                handler : function(newValue, old){
                    if (newValue) {
                        this.editTask(this.lead);
                    } else {
                        this.cancelEdit(this.lead);
                    }
                },
                deep : true
            },
            lead: {
                handler : function(newValue, old){
                    this.cachedLead = JSON.parse(JSON.stringify(this.lead));
                },
                deep : true
            }
        }
    }

</script>

<style scoped>

    .lead-label{
        color: #798f9a;
        font-size: 13px;
    }

    .lead-property-image {
        max-width: 100%;
        vertical-align: middle;
        float: left;
        width: 150px;
        padding: 5px;
        border: 1px solid #e2e2e2;
        margin-right:10px;
    }


    .contact-detail.interaction.progress {
        padding-bottom: 10px;
        border: 0;
    }
    .custom-edit {
        color: #00848E;
        cursor: pointer;
    }
    .intersect-design{
        font-size: 14px;
        font-family: inherit;
    }


</style>
