<template>
  <div class="payment-data" :disabled="isDropdownDisabled" :class="{'no-border-all' : moveout_settings }" style="background: white">
    <v-container  class="payment-data-container" >
      <v-row class="payment-data-header" justify="space-between">
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="mt-0">
             <v-row no-gutters class="pa-0 mt-0">
              <v-col class="pt-0 px-3" >
                <payment-property-selector v-if="showP2PSelector" />
              </v-col>
             </v-row>
           <v-radio-group :disabled="isDisabled" v-model="payment_type" :mandatory="false" @change="handlePaymentType">
            <v-expansion-panels  accordion focusable class="expansion-panel">
              <v-expansion-panel v-if="connectionDevices && connectionDevices.length" class="expansion-panel-header">
                <v-expansion-panel-header expand-icon="">
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center hb-font-body-medium"><v-radio value="reader"></v-radio>Card Reader</div>
                    <div><hb-icon class="mr-3" color="#101318">mdi-credit-card-outline</hb-icon></div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>            
                <tsys-reader :devices="connectionDevices" :auto_charge="auto_charge" v-if="payment_type === 'reader'" ref="cardReader"></tsys-reader>             
               </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel  v-if="cardProcessor">
                <v-expansion-panel-header expand-icon="">
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center hb-font-body-medium"><v-radio value="card"></v-radio>Credit/Debit</div>
                      <div class="d-flex gap-2px">
                      <v-img src="/img/payment_method_logos/visa_card_new.svg" class="mr-1"  max-height="16"
                      max-width="26.47"></v-img>
                      <v-img src="/img/payment_method_logos/master_card_new.svg"  class="mr-1" max-height="16"
                      max-width="26.47"></v-img>
                      <v-img src="/img/payment_method_logos/american_express_new.svg" class="mr-1 rounded-sm"  max-height="16"
                      max-width="26.47"></v-img>
                      <v-img src="/img/payment_method_logos/discover_card_new.svg"  max-height="16"
                      max-width="26.47"></v-img>
                      </div>
                  </div>                 
                </v-expansion-panel-header>
                <v-expansion-panel-content>            
                  <authnet-card :ten_address="tenantAddress" :auto_charge="auto_charge" v-if="payment_type === 'card' && cardProcessor === 'authorizenet'" ref="authnetCard" :paymentSource="paymentSource"></authnet-card>
                  <tsys-card :ten_address="tenantAddress" :auto_charge="auto_charge" v-if="payment_type === 'card' && cardProcessor === 'tsys'" ref="tsysCard"></tsys-card>
                  <tenant-payments-card :ten_address="tenantAddress" :auto_charge="auto_charge" v-if="payment_type === 'card' && cardProcessor === 'tenant_payments'" ref="tenantPaymentsCard" :paymentSource="paymentSource"></tenant-payments-card>
                  <fat-zebra-card :ten_address="tenantAddress" :auto_charge="auto_charge" v-if="payment_type === 'card' && cardProcessor === 'fatzebra'" ref="fatzebraCard" :paymentSource="paymentSource"></fat-zebra-card >
               </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel  v-if="eftposProcessor">
                <v-expansion-panel-header expand-icon="">
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center hb-font-body-medium"><v-radio value="Eftpos" :disabled="auto_charge"></v-radio>EFTPOS</div>
                    <div><hb-icon class="mr-3" color="#101318">mdi-credit-card-outline</hb-icon></div>
                  </div>                 
                </v-expansion-panel-header>
                <v-expansion-panel-content>            
                  <eftpos :payment_type="payment_type" v-if="payment_type === 'Eftpos'" ref="Eftpos" ></eftpos>
               </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="directDebitProcessor">
                <v-expansion-panel-header expand-icon="">
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center hb-font-body-medium"><v-radio value="directdebit"></v-radio>Direct Debit</div>
                    <div><hb-icon class="mr-3" color="#101318">mdi-checkbook</hb-icon></div>
                  </div>                 
                </v-expansion-panel-header>
                <v-expansion-panel-content>            
                  <fat-zebra-direct-debit :ten_address="tenantAddress" :property_id="property_id" :auto_charge="auto_charge"  v-if="payment_type === 'directdebit' && directDebitProcessor === 'fatzebra'" ref="fatzebraDirectDebit"></fat-zebra-direct-debit>
               </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="directDepositProcessor">
                <v-expansion-panel-header expand-icon="">
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center hb-font-body-medium"><v-radio value="directdeposit"></v-radio>Direct Deposit</div>
                    <div><hb-icon class="mr-3" color="#101318">mdi-checkbook</hb-icon></div>
                  </div>                 
                </v-expansion-panel-header>
                <v-expansion-panel-content>            
                  <direct-deposit :payment_type="payment_type" :ten_address="tenantAddress" :property_id="property_id" v-if="payment_type === 'directdeposit' && directDepositProcessor === 'directdeposit'" ref="directDeposit"></direct-deposit>
               </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="achProcessor">
                <v-expansion-panel-header expand-icon="">
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center hb-font-body-medium"><v-radio value="ach"></v-radio>ACH/E-Check</div>
                    <div><hb-icon class="mr-3" color="#101318">mdi-checkbook</hb-icon></div>
                  </div>                 
                </v-expansion-panel-header>
                <v-expansion-panel-content>            
                  <forte-ach :auto_charge="auto_charge" v-if="payment_type === 'ach' && achProcessor === 'forte'" ref="forteAch"></forte-ach>
                  <tsys-ach :ten_address="tenantAddress" :auto_charge="auto_charge"  v-if="payment_type === 'ach' && achProcessor === 'tsys'" ref="tsysAch"></tsys-ach>
                  <tenant-payments-ach :ten_address="tenantAddress" :auto_charge="auto_charge"  v-if="payment_type === 'ach' && achProcessor === 'tenant_payments'" ref="tenantPaymentsAch"></tenant-payments-ach>
               </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header expand-icon="">
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center hb-font-body-medium"><v-radio value="cash" :disabled="auto_charge"></v-radio>Cash</div>
                    <div><hb-icon class="mr-3" color="#101318">mdi-cash-multiple</hb-icon></div>
                  </div>                 
                </v-expansion-panel-header>
                <v-expansion-panel-content>            
                  <cash :payment_type="payment_type" v-if="payment_type === 'check' || payment_type === 'cash'" ref="cashCheck"></cash>
               </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header expand-icon="">
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center hb-font-body-medium"><v-radio value="check" :disabled="auto_charge"></v-radio>Check</div>
                    <div><hb-icon class="mr-3" color="#101318">mdi-checkbook</hb-icon></div>
                  </div>                 
                </v-expansion-panel-header>
                <v-expansion-panel-content>            
                  <cash :payment_type="payment_type" v-if="payment_type === 'check' || payment_type === 'cash'" ref="cashCheck"></cash>
               </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="shouldShowGiftCard">
                <v-expansion-panel-header expand-icon="">
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center hb-font-body-medium"><v-radio :value=PAYMENTS.PAYMENT_METHODS.GIFTCARD.VALUE :disabled="auto_charge"></v-radio>{{PAYMENTS.PAYMENT_METHODS.GIFTCARD.TEXT}}</div>
                    <div><hb-icon class="mr-3" color="#101318">mdi-custom-collection-calls</hb-icon></div>
                  </div>                 
                </v-expansion-panel-header>
                <v-expansion-panel-content>            
                  <gift-card :payment_type="payment_type" v-if="payment_type === 'giftcard'" ref="giftCard"></gift-card>
               </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-radio-group>
          <v-row v-if="auto_charge">
            <v-col cols="12" class="hb-text-light pt-0">
              You can only enroll a tenant in AutoPay with a credit or debit card.
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import FatZebraCard from "./PaymentMethods/FatzebraCard";
    import FatZebraDirectDebit from "./PaymentMethods/FatzebraDirectDebit";
    import DirectDeposit from "./PaymentMethods/DirectDeposit";
    import AuthnetCard from "./PaymentMethods/AuthnetCard";
    import AccountCredit from "./PaymentMethods/AccountCredit";
    import Cash from "./PaymentMethods/Cash";
    import Eftpos from "./PaymentMethods/Eftpos";
    import GiftCard from "./PaymentMethods/GiftCard";
    import TsysCard from "./PaymentMethods/TsysCard";
    import TsysReader from "./PaymentMethods/TsysReader";
    import TsysAch from "./PaymentMethods/TsysAch";
    import ForteAch from "./PaymentMethods/ForteAch";
    import PaymentPropertySelector from "./PaymentMethods/PaymentPropertySelector";

    import TenantPaymentsCard from "./PaymentMethods/TenantPaymentsCard";
    import TenantPaymentsAch from "./PaymentMethods/TenantPaymentsAch";
    //import TenantPaymentsReader from "./PaymentMethods/TenantPaymentsReader";
    import { EventBus } from '../../EventBus.js';

    import {PAYMENTS} from '@/constants/payments.js';
    import { mapGetters, mapActions, mapMutations } from 'vuex';
    import { notificationMixin } from  '../../mixins/notificationMixin.js';

    export default {
        name: 'CollectPayment',
        mixins: [ notificationMixin ],
        components: {
            Eftpos,
            AuthnetCard,
            Status,
            Cash,
            GiftCard,
            TsysCard,
            TsysReader,
            TsysAch,
            ForteAch,
            TenantPaymentsCard,
            TenantPaymentsAch,
            FatZebraCard,
            FatZebraDirectDebit,
            DirectDeposit,
            //TenantPaymentsReader,
            AccountCredit,
            PaymentPropertySelector,
        },
        data: () => ({
            payment_type: '',
            step: 1,
            close_confirm: false,
            panel: [0, 1],
            valid: false,
            unit_id: '',
            drawer: false,
            model: null,
            search: null,
            isDropdownDisabled: false,
            credit: {},
            summary_panel: 'facility',
            payment_amount: 0,
            partial_payment: false,
            allowed_active_drawer_components: ['showSellMerchandise', 'showMakePayment','showTransfer'],
        }),
        props: ["contact_id", "property_id", "amount", 'moveout_settings', 'tenant_address', 'default_payment_type', 'openInvoicesMoveOut', 'getSourceProperty', 'paymentSource'],
        async created(){
                    this.PAYMENTS = PAYMENTS;
          // if(this.connections && this.connections.length){
          //   this.payment_type = this.connectionDevices && this.connectionDevices.length ? 'reader' : 'card';
          // }else{
          //   this.payment_type = 'cash'
          // }
          this.handlePaymentType();
          EventBus.$on('disable_reader_dropdown', ()=>{
              this.isDropdownDisabled = true;
        });
        EventBus.$on('enable_reader_dropdown', ()=>{
              this.isDropdownDisabled = false;
        });
          EventBus.$on('HB-Merchandise-Sell:handle-contact-change', () => {
            this.setDefaultRefName();
            this.handlePaymentType();
        });
          const billingProfile = this.currentBillingProfile(this.contact_id, this.property_id);
          const paymentObj = Array.isArray(billingProfile.PaymentMethod) && billingProfile.PaymentMethod.length > 0 ? billingProfile.PaymentMethod[0] : null; 
          if(this.paymentSource === "MOVE_IN" && paymentObj) {
            await this.setPaymentObject({payment: {...this.payment, ...paymentObj, payment_method_id: paymentObj.id}});
            this.payment_type = paymentObj.type;
          }
        },
        mounted(){
        },
        destroyed(){
          this.isDropdownDisabled = false;
          EventBus.$off('HB-Merchandise-Sell:handle-contact-change', () => {
            this.setDefaultRefName();
            this.handlePaymentType();
          });
          EventBus.$off('enable_reader_dropdown',  this.isDropdownDisabled);
          EventBus.$off('disable_reader_dropdown', this.isDropdownDisabled);

          EventBus.$off('HB-Merchandise-Sell:handle-contact-change', () => {
            this.setDefaultRefName();
            this.handlePaymentType();
          });
        },
        filters:{
        },
        computed:{
          ...mapGetters({
            contact: 'paymentsStore/getContact',
            connections: 'paymentsStore/getPropertyConnections',
            activeDrawerComponent: 'navigationStore/activeDrawerComponent',
            showP2PSelector: 'paymentsStore/showP2PSelector',
            getPaymentSource:'paymentsStore/getPaymentSource',
            shouldShowGiftCard:'paymentsStore/shouldShowGiftCard',
            paymentData: 'paymentsStore/getPaymentInformation',
            payment_methods: 'paymentsStore/getContactPaymentMethods',
            payment_method: 'paymentsStore/getPaymentMethod',
            payment: 'paymentsStore/getPayment',
            leases: 'paymentsStore/getLeases',
            currentBillingProfile: 'billingProfileStore/currentBillingProfile',
          }),
            tenantAddress(){
              if(!this.tenant_address){
                return this.contact && this.contact.Addresses;
              }
              return this.tenant_address;
            },
            cardReaderProcessor(){
                let processor = this.connections.find(c => c.type === 'reader' );
                return processor ? processor.name : null;
            },
            eftposProcessor(){
                let processor = this.connections && this.connections.find(c => c.name === 'fatzebra');
                return processor ? processor.name : null;
            },
            cardProcessor(){
                let processor = this.connections && this.connections.find(c => c.type === 'card' );
                return processor ? processor.name : null;
            },
            achProcessor(){
                let processor = this.connections && this.connections.find(c => c.type === 'ach' );
                return processor ? processor.name : null;
            },
            directDebitProcessor(){
                let processor = this.connections && this.connections.find(c => c.type === 'directdebit' );
                return processor ? processor.name : null;
            },
            directDepositProcessor(){
                let processor = this.connections && this.connections.find(c => c.type === 'directdeposit' );
                return processor ? processor.name : null;
            },
            paymentMethods(){
                let pm = [];
                this.leases.filter(l => l.pay).map(l => {
                    if(!l.PaymentMethods) return;
                    return l.PaymentMethods.map(p => {
                        pm.push(p);
                    })
                });
                return pm;
            },
            connectionDevices() {
              let processor = this.connections && this.connections.find(c => c.type === 'card' );
                return processor ? processor.Devices : null;
            },
            auto_charge() {
              return this.leases && this.leases.length && this.leases.filter(l => l.auto_charge).length ? true : false;
            }
        },
        methods:{
          ...mapActions({
              fetchPropertyConnections: 'paymentsStore/fetchPropertyConnections',
              setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent'
            }),
            ...mapMutations({
              setLeaseProperty: 'paymentsStore/setLeaseProperty',
              setPaymentInformation: 'paymentsStore/setPaymentInformation',
              setPayment: 'paymentsStore/setPayment',
              setPaymentMethod: 'paymentsStore/setPaymentMethod',
              setPaymentObject: 'paymentsStore/setPaymentObject',
              setPaymentMethodObject: 'paymentsStore/setPaymentMethodObject',
              setPaymentInformationObject: 'paymentsStore/setPaymentInformationObject',
              setDisablePaymentButton: 'paymentsStore/setDisablePaymentButton',
              ivrPaymentType:'charmCallStore/ivrPaymentType'
            }),
            resetPaymentInfo(){
                this.payment_type = null;
            },
            async getPaymentMethodOptions(property_id) {
              this.setDisablePaymentButton({value: true});
              await this.fetchPropertyConnections({
                propertyID: property_id || this.property_id
              });

              if(!this.connections.length){
                this.showMessageNotification({type: 'error', description: "This account is not set up to accept online payments."});
              }
              this.setDisablePaymentButton({value: false});
            },
            close(){
                this.$emit('close');
            },
            async processPayment(data){

                let payment_info = data.payment;
                payment_info.contact_id = this.contact_id;
                payment_info.property_id = this.property_id;

                if(data.PaymentMethod){
                    payment_info.PaymentMethod = data.PaymentMethod;
                }

                this.$emit('payment', payment_info);

            },
            setDefaultRefName() {
              let tenant_name = this.contact ? this.contact.first + ' ' + this.contact.last : ''
              this.payment.ref_name = tenant_name || ''
            },
            
            handlePaymentType(){
              let type = this.payment_type;
              this.setPayment({property : 'source', propertyValue: 'In-store'});
              this.setPayment({property : 'type', propertyValue: type === 'reader' ? 'card' : type });
              this.setPaymentMethod({property : 'type', propertyValue: type === 'reader' ? 'card' : type});

              if(type === 'cash' || type === 'check' || type === 'directdeposit'){
                this.setPayment({property : 'payment_method_id', propertyValue: null});
                if(!this.payment.ref_name) this.setPayment({property : 'ref_name', propertyValue: this.contact ? (this.contact.first + ' ' + this.contact.last) : ''});
              }
              if(type === 'card' || type === 'ach' || type === 'directdebit'){
                this.setPaymentMethod({property: 'save_to_account', propertyValue: this.auto_charge});
              }
              if(type === 'giftcard'){
                this.setPayment({property : 'payment_method_id', propertyValue: null});
                this.setPayment({property : 'source', propertyValue: PAYMENTS.PAYMENTS.SOURCE});
                if(!this.payment.ref_name) this.setPayment({property : 'ref_name', propertyValue: this.contact ? (this.contact.first + ' ' + this.contact.last) : ''});
              }
              if(type !== 'credit'){
                  this.setPayment({property : 'id', propertyValue: null});
              }
              if(type != 'reader') {
                this.setPaymentMethod({property : 'device_id', propertyValue: ''});
                this.setPaymentMethod({property : 'connection_id', propertyValue: ''});
              }
            },
            
            validateDataFields(){
              if(this.payment_type === 'reader' || this.payment_type === 'credit') return true;
              if(this.payment_type === 'check' || this.payment_type === 'cash') return this.$refs.cashCheck.validateFields();
              if(this.payment_type === 'giftcard') return this.$refs.giftCard.validateFields();
              if(this.payment_type === 'card' && this.cardProcessor === 'authorizenet') return this.$refs.authnetCard.validateFields();
              if(this.payment_type === 'ach' && this.achProcessor === 'forte') return this.$refs.forteAch.validateFields();
              if(this.payment_type === 'card' && this.cardProcessor === 'tsys') return this.$refs.tsysCard.validateFields();
              if(this.payment_type === 'ach' && this.achProcessor === 'tsys') return this.$refs.tsysAch.validateFields();
              if(this.payment_type === 'card' && this.cardProcessor === 'tenant_payments') return this.$refs.tenantPaymentsCard.validateFields();
              if(this.payment_type === 'ach' && this.achProcessor === 'tenant_payments') return this.$refs.tenantPaymentsAch.validateFields();
              if(this.payment_type === 'card' && this.cardProcessor === 'fatzebra') return this.$refs.fatzebraCard.validateFields();
              if(this.payment_type === 'directdebit' && this.directDebitProcessor === 'fatzebra') return this.$refs.fatzebraDirectDebit.validateFields();
              if(this.payment_type === 'Eftpos') return this.$refs.Eftpos.validateFields();
              if(this.payment_type === 'directdeposit' && this.directDepositProcessor === 'directdeposit') return this.$refs.directDeposit.validateFields();
            }

        },
        watch:{          
          payment_type(newValue, oldValue) {             
              this.ivrPaymentType(newValue);
             },
            connectionDevices() {
              if(this.connectionDevices && this.connectionDevices.length) {
                this.payment_type = 'reader';
              } else {
                this.payment_type = this.connections && this.connections.length ? 'card' : 'cash';
              }
              this.handlePaymentType();
            },
            auto_charge(val) {
              if(!val && this.connectionDevices && this.connectionDevices.length ) {
                this.payment_type = 'reader';
              } else {
                this.payment_type = 'card';
              }
              this.handlePaymentType();
            },
        }
    }

</script>



<style scoped>
 .expansion-panel .v-expansion-panel-header.v-expansion-panel-header--active,
 .expansion-panel .v-expansion-panel-header.v-expansion-panel-header--active::before {
    background-color: #e0f5f5;  
 }

 ::v-deep.v-input--radio-group .v-messages {
  display: none;
 }
 ::v-deep.v-input--radio-group .v-input__slot { 
  margin-bottom: 0px !important;
 }
 .expansion-panel .v-expansion-panel-content{
  background-color: #F9FAFB;
 }

  .new-tenant-walkthrough-container{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .v-input--selection-controls{
    padding-top: 0px !important;
  }

  .new-tenant-walkthrough{
    background: #F9FAFB;
    width: 100%;
  }

  .new-tenant-walkthrough .v-expansion-panel{
    margin-top: 0;
  }

  .new-tenant-info-panel{
    flex: 0 0 320px;
    height: 100%;
    padding: 30px 50px;
    background-color: #F9FAFB;

    border-left: 1px solid #E1E6EA;
  }
  .walkthrough-body{
    flex: 1;
  }
  .section-header{
    background: white;
  }

  .user-info{
    padding-left: 50px;
    padding-right: 5px;
    background-color: white;
    border-bottom: 1px solid #E1E6EA;
  }


  .v-expansion-panel-content .form-label{
    background: #F4F6F8;
    /* padding: 20px; */
    font-weight: bold;
  }

  .invoice_display{
    padding:  10px 0px;
    font-size: 14px;
  }

  .invoice-line-row{
    margin-bottom: 0;
    padding: 0px 20px;
  }
  .invoice-line-row .col {
    padding: 0;
  }

  .invoice-total-row >.col{
    padding: 10px;
  }

  .invoice-row .caption{
    color: #637381
  }

  .invoice-row > .col{
    padding-bottom: 0;
  }

  .invoice-line-row.total{
    border-top: 1px solid #C4CDD5;
    margin-top: 20px;
  }
  .invoice-line-row.total > .col{
    padding-top: 15px;
  }

  .payment-data{
    /* border: 1px solid #DFE3E8; */
  }

  .payment-data-header .v-input--checkbox,
  .form-label .v-input--checkbox{
    margin-top: 0;
    padding-top: 0;
  }

  .payment-data-header{
    /* border-bottom: 1px solid #DFE3E8;
    padding: 15px 20px;
    margin-bottom: 0; */
  }

  .container.payment-data-container {
    padding: 0px;
  }

  .card-options{
    /* border-right: 1px solid #C4CDD5;
    padding: 10px;
    font-size: 14px; */
  }

  .payment-method-holder{
    padding-bottom: 10px;
  }
  .no-border-all{
    border: none;
  }
  .expansion-panel >>> .v-expansion-panel:not(:first-child):after {
   border: 0.5px solid rgba(0, 0, 0, 0.12);
 }
 .expansion-panel .v-expansion-panel::before{
   box-shadow : 0px 4px 10px 3px rgba(71, 84, 116, 0.1) !important;
   -webkit-box-shadow: none !important;
 }

 .expansion-panel >>> .v-expansion-panel-content__wrap{
   padding-left: 16px !important;
   padding-right: 16px !important;
   padding-top: 0px !important;
   padding-bottom: 4px !important;
 }


</style>
