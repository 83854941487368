<template>
  <div id="file-uploads" class="">
    <v-sheet class="chat-window-nav">
      <hb-tabs background-color="#FFFFFF">
        <v-tab
          v-for="menuOption in mainViewOptions"
          :value="menuOption"
          @click="mainViewChanged(menuOption)"
        >
          {{ menuOption.Unit.number }}
        </v-tab>
      </hb-tabs>
    </v-sheet>
    <div v-if="true">
      <div class="doc-list" :style="$vuetify.breakpoint.width > 800 ? `height: 65vh` : ''" @scroll.self="fetchOnScroll">
        <v-card class="mb-4" :class="{ 'mr-6': showAside }" elevation="0">
          <v-data-table
            :mobile-breakpoint="320"
            :headers="headers"
            :items="uploads"
            :loading="loadingDocuments"
            loading-text="Loading Signed Documents..."
            class="hb-data-table"
            disable-pagination
            hide-default-footer
            :custom-sort="customSort"
            @click:row="onRowClick"
          >
            <template v-slot:item.type="{ item }">
              <span>{{ item.type }}</span>
            </template>
            <template v-slot:item.name="{ item }">
              <span> {{ item.name }}</span>
            </template>
            <template v-slot:item.checkbox="{ item }" style="display: flex">
              <v-checkbox
                v-model="selectedoption"
                :value="item"
                hide-details
                class="square-checkbox"
              ></v-checkbox>
            </template>
          </v-data-table>
          <v-row class="mx-0" v-if="loadingDocuments">
            <v-col md="12" class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <hb-global-notification
  v-model="showNotification"
  :type="'default'"
  :timeout="30000"
  :loading="true"
  :description="'Your document is being generated, Please wait...'"
  @close="this.showNotification= false"
>
</hb-global-notification>
  </div>
</template>
<script type="text/babel">
import api from "../../assets/api.js";
import Status from "../includes/Messages.vue";
import Loader from "../assets/CircleSpinner.vue";
import moment from "moment";
import Modal from "../assets/Modal.vue";
import { EventBus } from "../../EventBus.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
//    import PublicDocuments from '../leases/PublicDocuments.vue';
import { notificationMixin } from "@/mixins/notificationMixin.js";
export default {
  name: "uploadSignedDocuments",
  data() {
    return {
      showNotification: false,
      previouslySelected: "",
      selectedoption: "",
      documents: [], 
      document: {},
      hasSubmitted: false,
      uploads: [],
      lease: {},
      mainView: {},
      dateRange: "",
      customStartDate: "",
      customEndDate: "",
      todaysDate: "",
      loadingDocuments: false,
      allowScrollRequest: true,
      loadingDocumentTemplates: false,
      documentMethod: "un-signed",
      documentType: { value: "all" },
      isDocumentReset: false,
      document_generate_type: "",
      fetchUnSignedDocumentUsingScroll: false,
      documentText: "Loading Signed Documents...",
      isAllRecordsLoaded: false,
    };
  },
  mixins: [notificationMixin],
  async created() {
    this.fetchData();
    EventBus.$on("document_signed", this.fetchData);
    this.todaysDate = moment().startOf("day").format("YYYY-MM-DD");
  },

  components: {
    Modal,
    Loader,
  
    Status,
  },
  computed: {
    ...mapGetters({
      isAdmin: "authenticationStore/isAdmin",
      getUserData: "authenticationStore/getUserData",
      getDocuments: "documentsStore/getDocuments",
      getDocumentTypes: "documentsStore/getDocumentTypes",
      getUnSignedDocumentTypes: "documentsStore/getUnSignedDocumentTypes",
    }),
    headers(){
      return [
        {
          text: "",
          value: "checkbox",
          align: "left",
          width: "50px",
          sortable: false,
        },
        {
          text: "Document Type",
          value: "type",
          width: this.$vuetify.breakpoint.xs ? "185px" : "130px",
          groupable: false,
        },
        {
          text: "Document Name",
          value: "name",
          width: "200px",
          groupable: false,
        },
      ]
    },
    getDocumentType() {
      return this.documentType.value != "all" ? this.documentType : "";
    },
    mainViewOptions() {
      var options = [];
      // options.push({
      //   id: null,
      //   Unit: {
      //     number: "Tenant",
      //     type: "tenant",
      //   },
      // });

      this.contactLeases.forEach((lease) => {
        if (this.propertyView) {
          if (lease.Unit.property_id == this.propertyView) {
            options.push({
              id: lease.id,
              end_date: lease.end_date,
              Unit: {
                number: lease.Unit.number,
                type: lease.Unit.type,
              },
            property_id: lease.Unit.property_id,
            });
          }
        } else {
          options.push({
            id: lease.id,
            end_date: lease.end_date,
            Unit: {
              number: lease.Unit.number,
              type: lease.Unit.type,
            },
            property_id: lease.Unit.property_id,
          });
        }
      });
      this.mainView = options[0];
      return options;
    },
  },
  methods: {
    async generateSignedDoc(row) {
      this.showNotification = true;
      let r = await api
        .get(
          this,
          api.LEASES +
            this.mainView.id +
            "/documents/" +
            row.id +
            "/generate?type=" +
            row.type,
          null,
          "generate"
        )
        .catch((err) => {
          this.showMessageNotification({ description: err });
          this.$emit("docLoadingemit", false);
        });
      // this.showMessageNotification({
      //   type: "caution",
      //   description: "Your document is being generated, Please wait",
      // });

      let signedDocsInitial = [];
      if (!this.mainView || this.mainView.id === null || !this.mainView.id) {
        let r = await api.get(
          this,
          api.CONTACTS + this.contactid + "/uploads",
          { property_id: this.mainView.property_id }
        );

        signedDocsInitial = r.uploads;
      } else {
        let r = await api.get(
          this,
          api.CONTACTS + this.contactid + "/uploads",
          { property_id: this.mainView.property_id, lease_id: this.mainView.id }
        );
        signedDocsInitial = r.uploads;
      }
      let requiredUpload = [];
      await this.sleep(30000);
      let signedDocs = [];
      if (!this.mainView || this.mainView.id === null || !this.mainView.id) {
        let r = await api.get(
          this,
          api.CONTACTS + this.contactid + "/uploads",
          { property_id: this.mainView.property_id }
        );
        signedDocs = r.uploads;
      } else {
        let r = await api.get(
          this,
          api.CONTACTS + this.contactid + "/uploads",
          { property_id: this.mainView.property_id, lease_id: this.mainView.id }
        );
        signedDocs = r.uploads;
      }
      requiredUpload = signedDocs.filter(
        ({ id: id1 }) => !signedDocsInitial.some(({ id: id2 }) => id2 === id1)
      );
      if (requiredUpload.length == 0) {
        this.showMessageNotification({
          description:
            "Your document couldn't be generated at this moment, Please check in Tenant Documents to view the document",
        });
      }
      let resp = await this.getSignLink(requiredUpload[0]);
      console.log(resp, "resp");
      return resp;
    },
    async getSignLink(item) {
      var data = {
        docs: [{ upload_id: item.id }],
        contact_id: item.signers.find((i) => !i.signed).Contact.id,
        property_id: this.mainView.property_id,
      };
      let response = await api.post(this, api.GET_SIGN_LINK, data);
      let link = response.link.shortUrl;
      return link;
    },
    async sleep(milliseconds) {
      await new Promise((resolve) => {
        return setTimeout(resolve, milliseconds);
      });
    },
    async onRowClick(item) {
      if (this.previouslySelected == item) {
        this.selectedoption = "";
        this.previouslySelected = "";
        this.$emit("docSelectedemit", "");
      } else {
        // this.$emit("docSelectedemit", "");
        this.$emit("docLoadingemit", true);
        let res = await this.generateSignedDoc(item);
        this.selectedoption = item;
        this.previouslySelected = item;
        this.$emit("docSelectedemit", res);
        this.$emit("docLoadingemit", false);
      }
    },
    ...mapActions({
      fetchDocumentsData: "documentsStore/fetchDocuments",
    }),
    ...mapMutations({
      setDocuments: "documentsStore/setDocuments",
    }),

    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === "name") {
          var fa = "";
          if (a.id && a.name) {
            fa = a.name
          } else {
            ("");
          }

          var fb = "";
          if (b.id && b.name) {
            fb = b.name;
          } else {
            ("");
          }
          if (!isDesc[0]) {
            if (fa > fb) {
              return -1;
            }
            if (fa < fb) {
              return 1;
            }
            return 0;
          } else {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          }
        }
        if (index[0] === "type") {
          var fa = "";
          if (a.id && a.type) {
            fa = a.type
          } else {
            ("");
          }

          var fb = "";
          if (b.id && b.type) {
            fb = b.type;
          } else {
            ("");
          }
          if (!isDesc[0]) {
            if (fa > fb) {
              return -1;
            }
            if (fa < fb) {
              return 1;
            }
            return 0;
          } else {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          }
        }
      });

      return items;
    },
    interactionsCount() {
      return this.uploads.length ? this.uploads.length : 0;
    },
    fetchOnScroll(elem) {
      let {
        target: { scrollTop, clientHeight, scrollHeight },
      } = elem;
      let scrolledValue = Math.round(scrollTop + clientHeight);
      if (scrolledValue >= scrollHeight) {
        const length = this.interactionsCount();
        this.isAllRecordsLoaded = length % 30 == 0 ? false : true;
        if (
          length % 30 === 0 &&
          !this.loadingDocuments &&
          !this.isAllRecordsLoaded && this.allowScrollRequest
        ) {
          this.fetchData();
        }
      }
    },
    async fetchData(lease, dateRange, customStartDate, customEndDate) {
      this.loadingDocuments = true;

      let limit = 30;
      let offset =
        this.uploads.length == 0
          ? 1
          : Math.ceil(this.uploads.length / limit) + 1;
      // let params = `?limit=${limit}&offset=${offset}&fetch_details=true`;
      let params = `?limit=${limit}&offset=${offset}&fetch_details=true`;
      let path = "";

      api
        .get(this, api.DOCUMENT + params + path)
        .then((r) => {
          if (r.documents.length == 0) {
            this.allowScrollRequest = false;
            this.documentText = "No data found.";
          } 
          else if(r.documents.length < 30){
            this.allowScrollRequest = false;
          }
          else {
            this.allowScrollRequest = true;
          }
          this.tempDocs = [...this.uploads, ...r.documents];
          this.uploads = this.tempDocs;
          this.loadingDocuments = false;
        })
        .catch((err) => {
          this.loadingDocuments = false;
        });
    },

    async resetFilters() {
      this.dateRange = "";
      this.customStartDate = "";
      this.customEndDate = "";
      await this.setDocuments([]);
      this.documentText = "Loading Signed Documents...";
      this.documents = [];
      await this.fetchData(this.mainView);
    },
    async mainViewChanged(x) {
      console.log(x);
      this.mainView = x;
      this.mainView = x;
      this.uploads = [];
      await this.resetFilters();
    },
  },
  props: ["contact", "showAside", "contactLeases", "propertyView", "contactid"],
  watch: {
    document() {
      if (this.document.id) {
        this.hasSubmitted = false;
      }
    },

    customStartDate() {
      if (this.customStartDate === "") {
        this.customEndDate = "";
      }
    },
  },
};
</script>

<style lang="scss">
.select-filter {
  border: 1px solid #dfe3e8;
  border-radius: 4px;
  width: 175px;
  height: 40px;
  background: white;
}

.select-filter:hover {
  border: 1px solid #c4cdd5;
  cursor: pointer;
}

.select-filter.download-center-filters-active {
  border: 2px solid #00848e;
}

.document-filters-footer {
  border: 1px solid #dfe3e8;
  border-top: none;
}

.v-input--selection-controls {
  margin-top: 0px !important;
}

.doc-list {
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
<style scoped>
    .doc-list {
      overflow-y: auto;
    }
    .hb-data-table >>> .v-data-table__wrapper {
      border-left: none !important;
    }
</style>
