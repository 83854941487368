<!-- New file created by BCT for space-management -->
<template>
  <div class="main-container">
    <div
      class="empty-state-container mt-5 ml-5 mr-5 d-flex"
      v-if="!amenityList || Object.keys(amenityList).length === 0"
    >
      <span class="hb-text-night-light">
        <div>There are no features and amenities.</div>
        <div class="mt-4">First, add features and amenities from the settings page.</div>
      </span>
    </div>
    <template v-else-if="showHelpText">
      <div class="px-4 py-4 text-grey">
        First, select which features and amenities you want to apply to the
        selected spaces. Then, select the value you want to add or override for
        all the selected spaces.
      </div>
      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
    </template>
    <div>
      <div v-for="(amenities, category, index) in amenityList" :key="index">
        <hb-form :label="category" :full="true">
          <v-row :key="aIndex" v-for="(amenity, aIndex) in amenities">
            <hb-tooltip v-if="amenity.parent_amenity_id">
                        <template v-slot:header>
                         You cannot Edit the Derived Amenity value directly.
                        </template>
            </hb-tooltip>
            <v-col :class="['pt-1', aIndex === amenities.length - 1 ? 'pb-1' : 'pb-2']">
              <hb-checkbox
                dense
                hide-details
                v-if="showCheckbox"
                :value="amenity.selected"
                :label="amenity.feature"
                v-model="amenity.selected"
                :disabled="amenity.parent_amenity_id ? true : false"
                @change="$emit('updateAmenitySelection', {category, id: amenity.property_amenity_mapping_id, value: $event})"
              />
              <span v-else class="hb-text-night">
                {{ amenity.feature }}
              </span>
            </v-col>
            <v-col v-if="amenity.selected || !showCheckbox" :class="['pt-0', aIndex === amenities.length - 1 ? 'pb-0' : 'pb-1']">
              <v-select
                v-if="amenity.options.type === 'select'"
                :items="amenity.options.options"
                :value="amenity.property_default_value"
                :disabled="amenity.parent_amenity_id ? true: false"
                item-value="id"
                item-text="name"
                placeholder="Select a value"
                v-validate="'required'"
                :error-messages="errors.collect('Default Value')"
                hide-details
                id="Default Value"
                name="Default Value"
                @change="$emit('update', {category, id: amenity.property_amenity_mapping_id, value: $event})"
              >
              </v-select>
              <v-radio-group
                v-else-if="amenity.options.type === 'checkbox'"
                row
                :value="amenity.property_default_value"
                :disabled="amenity.parent_amenity_id ? true: false"
                v-validate="'required'"
                hide-details
                :error-messages="errors.collect('Default Value')"
                id="Default Value"
                name="Default Value"
                @change="$emit('update', {category, id: amenity.property_amenity_mapping_id, value: $event})"
              >
                <hb-radio value="Yes" class="pb-1">
                  <template v-slot:label>
                    <span>Yes</span>
                  </template>
                </hb-radio>
                <hb-radio value="No" class="pb-1">
                  <template v-slot:label>
                    <span>No</span>
                  </template>
                </hb-radio>
              </v-radio-group>
              <v-text-field
                v-else
                type="text"
                :value="amenity.property_default_value"
                :disabled="amenity.parent_amenity_id ? true: false"
                v-validate="'required'"
                :label="amenity.name"
                hide-details
                dense
                class="mt-0"
                @change="$emit('update', {category, id: amenity.property_amenity_mapping_id, value: $event})"
              ></v-text-field>
            </v-col>
          </v-row>
        </hb-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AmenitySelector",
  props: {
    amenityList: {
      type: Object
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    showHelpText: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style scoped>
.add-border {
  border-style: dashed;
  border-color: lightgrey;
}
.text-grey {
  color: grey;
}

.main-container {
  min-height: 50vh;
  min-height: 50svh;
}

.empty-state-container{
  text-align: center;
  border: 1px dashed #c4cdd5;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 120px;
}

</style>
