export default [
    {
      name:'TenantLeaseDetails',
      path: '/contacts/:contact_id',
      component:  () => import('../components/contacts/View.vue'),
      meta: {
        requiresAuth: true,
        hasAccess: ['admin'],
        layout: 'master',
        re_fetch:true
      },
      children:[
        {
          path: '',
          name: 'ContactOverview',
          component: () => import('../components/contacts/Overview.vue'),
          meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
          }
        },
        {
          path: 'transaction-history',
          name: 'ContactPayment',
          component: () => import('../components/contacts/TransactionHistory.vue'),
          meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
          }
        },
        {
          path: 'files',
          name: 'ContactFiles',
          component: () => import('../components/contacts/Documents.vue'),
          meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
          }
        },
        {
          path: 'payment-methods',
          name: 'ContactPaymentMethods',
          component: () => import('../components/contacts/PaymentMethods.vue'),
          meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
          }
        },
        {
          path: 'gate-access',
          name: 'ContactAccessControl',
          component: () => import('../components/contacts/Access.vue'),
          meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
          }
        },
        {
          path: 'payment-cycles',
          name: 'ContactPaymentCycles',
          component: () => import('../components/contacts/PaymentCyclesList.vue'),
          meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
          }
        },
        {
          path: 'billing-cycles',
          name: 'ContactBillingCycles',
          component: () => import('../components/contacts/BillingCyclesList.vue'),
          meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
          }
        }
      ]
    },
  ]