<template>

  <v-card>
    <v-card-title class="title">Send E-Sign Link</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pb-0">
      <v-container class="py-0">
        <v-row class="py-2">
          <v-col align-self="center" class="row-checkbox-col">

            <v-checkbox class="mt-0" name="send_sms" id="send_sms" color="primary" hide-details dense v-model="signer.send_sms"></v-checkbox>
          </v-col>
          <v-col align-self="center" style="flex: 0 0 100px;" >
            <label for="send_sms">SMS</label>
          </v-col>

          <v-col align-self="center" class="flex: 1" v-show="signer.send_sms">
            <v-text-field hide-details outlined placeholder="(xxx) xxx-xxxx" dense v-model="signer.sms_number"></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="py-2">
          <v-col align-self="center" class="row-checkbox-col">
            <v-checkbox class="mt-0" name="send_email" id="send_email" color="primary" hide-details dense  v-model="signer.send_email"></v-checkbox>
          </v-col>
          <v-col align-self="center" style="flex: 0 0 100px;"><label for="send_email">Email</label></v-col>
          <v-col align-self="center" v-show="signer.send_email">
            <v-text-field hide-details outlined dense placeholder="Email Address" v-model="signer.email_address"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container>
        <v-row class="justify-end">
          <v-btn color="secondary" @click="$emit('close')" class="mr-3">
            Cancel
          </v-btn>
          <v-btn color="primary" :disabled="sendSignLinkInProgress" :loading="sendSignLinkInProgress" @click="sendSignLink(signer)">Send Documents</v-btn>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>


</template>


<script type="text/babel">


    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import { notificationMixin } from  '@/mixins/notificationMixin.js';

    export default {
        name: "SendForSignature",
        mixins: [notificationMixin],
        data() {
            return {
              sendSignLinkInProgress: false
            }
        },
        components:{
            Status,
            Loader
        },
        props: ['signer', 'lease_id', 'property_id'],
        methods:{
            async sendSignLink(signer){

                this.sendSignLinkInProgress = true;
                var data = {
                    signers: [signer],
                    upload_id: signer.upload_id,
                    lease_id: this.lease_id,
                    property_id: this.property_id
                };

                try {
                  await api.post(this, api.SEND_SIGN_LINK, data);
                  this.showMessageNotification({ type: 'success', description: 'An email with instructions has been sent to this user.' });
                } catch (error) {
                  this.showMessageNotification({ description: error });
                } finally {
                  this.sendSignLinkInProgress = false;
                }

            },
        }
    }

</script>
