<template>
    <div class="session-timeout-error">
        <div class="timeout-message">
            <h1>Thank You For Your Payment</h1>
            <p>An email with your receipt has been sent to the email address on file.</p>
            <!-- <v-btn type="submit" @click="downloadReciept(paymentId, headers)" class="mx-auto" height="55" min-width="299" color="#0096FF"> -->
                <!-- <v-btn type="submit" class="mx-auto" height="55" min-width="299" color="#0096FF">
                <div style="color: #FFFFFF; line-height: 22px; font-style: sans-serif; font-size: 18px;">View / Print</div>
            </v-btn> -->
        </div>
    </div>
</template>
  
<script>

// import axios from 'axios'
export default {
    data() {
        return {

        };
    },
    methods: {
        // downloadReciept(paymentId, headers1){
        //     const apiUrl = `http://api.hummingbird.local/v1/companies/kQoBXpBpnx/reports/download-pdf`;
        //     const headers = headers1;
        //     console.log("HEADER__++++",headers1)
        //     console.log("PaymentId",paymentId)
        //     const currentDate = new Date();
        //     const formattedDate = `${currentDate.toDateString()} @ ${currentDate.toLocaleTimeString()}`;
        //     const payload = {
        //             "type": "receipt",
        //             "format": "pdf",
        //             "request_id": paymentId,
        //             "browser_time": formattedDate
        //         }
        //     axios
        //         .post(apiUrl, payload, { headers })
        //         .then((response) => {
        //         console.log('Payment success', response.data);
        //         const arr = new Uint8Array(response.data.data.data);
        //         var blob = new Blob([arr], { type: "application/pdf" });
        //         var link = document.createElement("a");
        //         link.href = window.URL.createObjectURL(blob);
        //         window.open(link.href, "_blank");
        //         })   
        // }
    }
};
</script>
  
<style scoped>
.session-timeout-error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    height: 100svh;
    background-color: #f1f1f1;
}

.timeout-message {
    text-align: center;
}

h1 {
    font-size: 36px;
    color: #333;
}
</style>
