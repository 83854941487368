<template>
    <div>
        <hb-modal
            :size="(form.payment_method_id !== billingProfile.payment_method_id) && form.payment_method_id ? 'large' : 'medium'"
            confirmation
            title="Confirm Change"
            v-model="dialog"
            @close="$emit('close')"
            :showHelpLink="false"
            >
            <template v-slot:content>
                <v-row
                    v-if="contactLeasesLoading || documentsLoading"
                    style="height: 200px;"
                    align="center"
                    justify="center"
                    no-gutters
                >
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
                </v-row>
                <span v-else>
                <v-row class="px-7 py-3 hb-font-body ma-0">
                    <div v-if="form.payment_method_id !== billingProfile.payment_method_id && !form.payment_method_id">You are about to remove the <span class="hb-font-body-medium hb-text-night">{{ field_name }}</span> {{  }} {{ activeLeaseExist ? 'for the following spaces:' : ''}}</div>
                    <div v-else-if="form.payment_method_id !== billingProfile.payment_method_id">You are about to add the <span class="hb-font-body-medium hb-text-night">{{ field_name }}</span> {{ activeLeaseExist ? 'for the following spaces:' : ''}}</div>
                    <div v-else>You are about to change the <span class="hb-font-body-medium hb-text-night">{{ field_name }}</span> from <span class="hb-font-body-medium hb-text-night">{{ old_value }}</span> to <span class="hb-font-body-medium hb-text-night">{{ new_value }}</span> {{ activeLeaseExist ? 'for the following spaces:' : ''}}</div>
                </v-row>

                <v-row 
                    class="pb-3 px-7 ma-0"
                >
                    <!-- <span class="hb-font-body-medium hb-text-night">Space {{ lease.Unit.number }}</span> -->
                    <ul>
                    <li  v-for="(lease, i) in contactLeases" :key="'contactLeases_' + i" class="hb-font-body-medium hb-text-night">Space {{ lease.Unit.number }}</li>
                    </ul>
                </v-row>

                <v-row class="px-7 py-3 hb-font-body ma-0" v-if="new_value !== PAYMENT_CYCLES.MONTHLY && form.payment_cycle !== billingProfile.payment_cycle">
                    <div>For any space type where the {{ new_value }} Payment cycle is not applicable, the payment cycle continues to be Monthly</div>
                </v-row>

                <v-row class="px-7 py-3 hb-font-body ma-0" v-if="checkDenyPayments && denyPaymentLeases?.length > 0">
                    <div>For the {{ denyPaymentLeases?.length > 1 ? 'spaces' : 'space' }} <span class="hb-font-body-medium hb-text-night">{{ denyPaymentLeases?.map( dl => dl.Unit.number).join(', ') }}</span> the deny payments is enabled as a result <span class="hb-font-body-medium hb-text-night">{{ field_name }}</span> will not be updated.</div>
                </v-row>

                <v-row class="px-7 hb-font-body ma-0" v-if="(form.payment_method_id !== billingProfile.payment_method_id) && form.payment_method_id && leasesGroupedByUnitType.length > 0">
                    <v-col cols="12" class="px-0 pt-0 pb-0">
                        <div>
                            If you would like to make these changes, please sign the documents below and select continue to save.
                        </div>
                    </v-col>
                    <v-col cols="12" class="px-0 pt-3 pb-3">
                        <div>
                            <hb-notification 
                                    type="caution"
                                    not-dismissable
                                    title-off
                                >
                                By signing the documents below, your lease will convert to a Multi Space Lease agreement. Please proceed with this action to continue.
                            </hb-notification>
                        </div>
                    </v-col>                    
                    <v-col cols="12" class="services-section px-0 py-0" v-for="(unitTypeWithLease, i) in leasesGroupedByUnitType" :key="'unitTypeWithLease_' + i">
                        <DocumentsSigner :lease-id="unitTypeWithLease.lease_id" :title="documentSignerTitle(unitTypeWithLease.unit_type)" :current-contact="contact" :is-multi-space-lease="false" :processType="LEASE_CONFIGURATION.AUTOPAY_ENROLLMENT_SLUG" @documentsSigned="checkStatus"></DocumentsSigner>
                        <br v-if="i < leasesGroupedByUnitType.length - 1"/>
                    </v-col>
                </v-row>

                <v-row class="px-7 py-3 hb-font-body ma-0">
                    <div>Do you want to continue with these changes?</div>
                </v-row>
            </span>
            </template>
            <template v-slot:right-actions>
                <hb-btn @click="save(false)" :loading="btnLoading || loading" :disabled="!documentSigned || documentsLoading || contactLeasesLoading || btnLoading || loading">Continue</hb-btn>
            </template>
        </hb-modal>
    </div>
  </template>
  
  <script type="text/babel">
  import { notificationMixin } from  '@/mixins/notificationMixin.js';
  import DocumentsSigner from '../../includes/DocumentsSigner.vue';
  import { settingsMixin } from "@/mixins/settingsMixin.js";
  import SETTINGS from "@/constants/settings.js"; 
  import LEASE_CONFIGURATION from '@/constants/lease_configuration.js';
  import LEASE from "@/constants/lease.js";
  import api from '../../../assets/api';
  import { EventBus } from '../../../EventBus';
  import { capitalizeFirstLetter } from '../../../utils/common';

  export default {
    name: "AutoPayConfirmationModal",
    mixins: [ notificationMixin, settingsMixin ],
    data() {
      return {
        PAYMENT_CYCLES: LEASE.PAYMENT_CYCLES,
        unitTypesWithLeaseReady: false,
        documentsLoading: false,
        is_save: false,
        form_payment_id: '',
        documentSigned: true
      };
    },
    components:{
        DocumentsSigner
    },
    props:{
        value: {
            type: Boolean,
            default:false
        },
        contactLeasesLoading:{
            type: Boolean,
            default: false
        },
        form:{
            type:Object,
            default: () => ({})
        },
        contact:{
            type:Object,
            default: () => ({})
        },
        contactLeases:{
            type:Array,
            default: () => ([])
        },
        billingProfile:{
            type:Object,
            default: () => ({})
        },
        field_name:{
            type: String,
            default: ''
        },
        new_value:{
            type: String,
            default: ''
        },
        old_value:{
            type: String,
            default: ''
        },
        denyPaymentLeases:{
            type:Array,
            default: () => ([])
        },
        loading:{
            type: Boolean,
            default: false
        },
        btnLoading:{
            type: Boolean,
            default:false
        },
        activeLeaseExist:{
            type: Boolean,
            default:false
        },
        checkDenyPayments:{
            type: Boolean,
            default:false
        }
    },
    created() {
        this.LEASE_CONFIGURATION = LEASE_CONFIGURATION;
        this.SETTINGS = SETTINGS;
        this.LEASE = LEASE;
        this.form_payment_id = this.form.payment_method_id;
        EventBus.$on('error_updating_billing_profile', () => this.setBillingProfileError());
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        leasesGroupedByUnitType() {
            if (!this.getFeatureSettingValueByName(SETTINGS.ALLOW_MULTI_SPACE_RENTAL) || !this.contactLeases.length) return [];

            const leasesGroupedByUnitType = this.contactLeases.reduce((leasesGroupedByUnitTypeData, lease) => {
                const { Unit, id: leaseId, start_date, unit_id, is_multi_space } = lease;
                const unitType = this.getUnitTypeTitle(Unit);
                const templateId = Unit?.Property?.LeaseTemplates?.Template?.id;
                const unitTypeId = Unit?.unit_type_id;
                const leaseTemplateMSR = Unit?.Property?.LeaseTemplates?.Template?.multi_space_rental;
                const leaseTemplateMSRTypes = Unit?.Property?.LeaseTemplates?.Template?.multi_space_rental_types;
                const isMSRAutoPayTriggerEnabled = leaseTemplateMSRTypes.length && leaseTemplateMSRTypes.some(item => item.slug === LEASE_CONFIGURATION.AUTOPAY_ENROLLMENT_SLUG && item.active === 1);
                const propertyId = Unit?.Property?.id;

                if (parseInt(leaseTemplateMSR) === 1 && isMSRAutoPayTriggerEnabled && parseInt(is_multi_space) === 0) {
                    if (!leasesGroupedByUnitTypeData[unitTypeId]) {
                        leasesGroupedByUnitTypeData[unitTypeId] = [];
                    }

                    // Add lease to the leasesGroupedByUnitTypeData object
                    leasesGroupedByUnitTypeData[unitTypeId].push({
                        unitType,
                        templateId,
                        leaseId,
                        unitTypeId,
                        UnitId: unit_id,
                        startDate: start_date,
                        leaseTemplateMSR,
                        leaseTemplateMSRTypes,
                        isMultiSpace: is_multi_space,
                        propertyId
                    });

                    // Sort the leasesGroupedByUnitTypeData immediately after adding the new lease
                    leasesGroupedByUnitTypeData[unitTypeId].sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
                }
                return leasesGroupedByUnitTypeData;
            }, {});

            // Convert leasesGroupedByUnitType object to an array and get the most recent lease for each unitType
            const leasesGroupedByUnitTypeFinalData = Object.values(leasesGroupedByUnitType).map(unitTypeGroup => unitTypeGroup[0]).map(lease => ({
                unit_type: lease.unitType,
                template_id: lease.templateId,
                lease_id: lease.leaseId,
                start_date: lease.startDate,
                unit_type_id: lease.unitTypeId,
                unit_id: lease.UnitId,
                multi_space_rental: lease.leaseTemplateMSR,
                multi_space_rental_types: lease.leaseTemplateMSRTypes,
                is_multi_space_lease: lease.isMultiSpace,
                property_id: lease.propertyId
            }));

            this.unitTypesWithLeaseReady = true;
            return leasesGroupedByUnitTypeFinalData;
        }
    },
    methods: {
        setBillingProfileError(){
           this.is_save = false;
        },
        save(){
            this.is_save = true;
            let data = {...this.form, is_msr:this.form.payment_method_id ? true : false, leases_data: this.leasesGroupedByUnitType};
            this.$emit('update',data);
        },
        documentSignerTitle(title){
            return `Document Signing: ${capitalizeFirstLetter(title)}`;
        },
        async creatChecklistLeases(computedLeases){
            try { 
                let data = {
                    leases_data: computedLeases
                }
                await api.post(this, api.LEASES + '/create-checklist-leases', data);
                this.documentsLoading = false;
            } catch (error) {
                this.showMessageNotification({ type: 'error', description: error });
            }
        },
        checkStatus(status){
            this.documentSigned = status; 
        }
    },
    async destroyed(){
        if(this.leasesGroupedByUnitType.length && this.form_payment_id && !this.is_save){
            const lease_ids = this.leasesGroupedByUnitType.length && this.leasesGroupedByUnitType.map(lease => lease.lease_id);
            await api.put(this, api.LEASES + 'delete-checklist-leases-uploads', {lease_ids});
            this.form_payment_id = '';
        }
        EventBus.$off('error_updating_billing_profile', () => this.setBillingProfileError());
    },
    watch: {
        unitTypesWithLeaseReady(val){
            if(val && this.leasesGroupedByUnitType.length && this.form.payment_method_id){
                this.documentsLoading = true;
                this.creatChecklistLeases(this.leasesGroupedByUnitType);
            }
        }
    }
  }
  </script>