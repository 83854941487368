import { mapGetters } from "vuex";
export const settingsMixin = {
    data() {
        return {}
    }, 
    async created() { 
        await this.$store.dispatch('settingsStore/fetchFeatureSettings');
    },
    computed: {
        ...mapGetters({
            settings: 'settingsStore/featureSettings'
        })
    },
    methods: {
        getFeatureSettingValueByName(name) {
            const multiSpaceRentalSetting = this.settings?.find(setting => setting.name === name);
            return multiSpaceRentalSetting?.value ? parseInt(multiSpaceRentalSetting.value, 10) === 1 : false;
        }
    }
};