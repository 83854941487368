export default [
    {
      name: "DirectRefunds",
      path: "/direct-refunds",
      component: () => import("../components/directrefund/index.vue"),
      meta: {
        requiresAuth: true,
        hasAccess: ["admin"],
        layout: "master",
        re_fetch: true,
      },
    },
    {
      name: "FlaggedAccounts",
      path: '/flagged-accounts',
      component: () => import('../components/directrefund/index.vue'),
      meta: {
        requiresAuth: true,
        hasAccess: ['admin'],
        layout: 'master',
        re_fetch:true
      }
    }
  ];
  