<template>
  <hb-modal
    v-model="dialog"
    size="large"
    title="Change Insurance / Protection Plan Coverage"
    @close="$emit('close')"
    show-help-link
    :size="$vuetify.breakpoint.xs ? 'fullscreen' : ''"
  >
    <template v-slot:subheader>
      <span>
        Send your insurance/protection plan enrollment form to the tenant. Your
        tenant can also sign on this device or a printed copy.
      </span>
    </template>
    <template v-slot:content>
      <hb-form label="Current Coverage">
        <div v-if="isInsuranceDeclined">
          Declined
        </div>
        <div v-else>
          <v-row class="pa-0 ma-0 hb-text-night hb-font-body">
            <v-col cols="12" class="ma-0 pa-0">{{ currentInsurance.name }} 
              <span v-if="currentInsurance.insurance?.coverage" :class="durationPeriodColor" class="hb-font-caption pb-2">({{ currentInsurance.insurance?.coverage }} coverage)</span>
            </v-col>
            <v-col cols="12" class="ma-o pa-0" v-if="currentInsurance.insurance.type == 'library'">
              {{ currentInsurance.insurance.deductible | formatMoney }} Deductible, 
              <span>{{ currentInsurance.insurance.premium_type === '$' ? currentInsurance.insurance.premium_type : '' }}</span>
              <span>{{ currentInsurance.insurance.premium_value | RoundNumber }}</span> 
              Premium
              <span>{{ currentInsurance.insurance.premium_type === '%' ? currentInsurance.insurance.premium_type : '' }}</span>
            </v-col>
          </v-row>
        </div>
      </hb-form>
      <hb-form label="Free Insurance" class="start-insurance" v-if="shouldOfferFreeInsurance">
        <hb-checkbox
          v-model="isFreeInsuranceSelected"
          label="Waive off insurance amount"
        >
        </hb-checkbox>
      </hb-form>
      <hb-form label="Coverage" description="Select the tenant’s new coverage level." class="start-insurance">
        <hb-radio-group v-model="selectedInsurance">
          <hb-radio v-if="!table_rate_insurance" :disabled="isSameInsurance(insurance)" :value="insurance" v-for="insurance in insurances" :key="insurance.id" @change = "checkInsuranceStatus(insurance)">
            <template v-slot:label>
              {{ insurance.name }}
            </template>
            <template v-slot:description v-if="insurance.coverage">
              {{ insurance.deductible | formatMoney }} Deductible, 
              <span>{{ insurance.premium_type === '$' ? insurance.premium_type : '' }}</span>
              <span>{{ insurance.premium | RoundNumber }}</span> 
              Premium
              <span>{{ insurance.premium_type === '%' ? insurance.premium_type : '' }}</span>
            </template>
          </hb-radio>
          <table-rate-insurance
            v-if="table_rate_insurance"
            :insurances="insurances"
            :isSelected="!!selectedInsurance"
            :fullWidth="true"
            @updateCoverageValue="updateCoverageValue"
          />
        </hb-radio-group>
        <span v-if="!insurances || !insurances.length">
          No insurances found 
        </span>
      </hb-form>

      <hb-form label="Decline Coverage" v-if="currentInsurance" class="start-insurance">
        <hb-checkbox
          v-model="declineInsurance"
          label="Tenant has own coverage"
        >
        </hb-checkbox>

        <div v-if="declineInsurance">
          <div class="mt-1">
            <span>Please select the expiration date of the coverage policy</span>
          </div>
          <v-row class="pa-0 ma-0 mt-5">
            <v-col cols="5" class="pa-0 ma-0">
              <v-select
                :items="CALENDAR.MONTHS_LIST"
                item-text="name"
                hide-details
                v-model="insurance_exp_month"
                clearable
                data-vv-name="insurance_exp_month"
                data-vv-as="Month"
                label="Select Month"
              ></v-select>
            </v-col>

            <v-col cols="5" class="pa-0 ma-0 ml-4">
              <v-select
                :items="CALENDAR.YEARS_LIST"
                item-text="name"
                hide-details
                v-model="insurance_exp_year"
                clearable
                data-vv-name="insurance_exp_year"
                data-vv-as="Year"
                label="Select Year"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </hb-form>

      <hb-form
        v-if="selectedInsurance"
        class="start-insurance"
        multiple
        label="Start"
        required
      >
        <hb-radio-group v-model="insuranceStart">
          <hb-radio
            :label="`Current Billing Period: ${currentBillingPeriodLabel}`"
            value="current"
          ></hb-radio>
          <hb-radio
            :disabled="isFreeInsuranceSelected"
            :label="`Next Billing Period: ${
              billingPeriods.future &&
              $options.filters.formatDateServices(
                billingPeriods.future[0].start
              )
            } - ${
              billingPeriods.future &&
              $options.filters.formatDateServices(billingPeriods.future[0].end)
            }`"
            value="next"
          ></hb-radio>
        </hb-radio-group>
        <div class="mt-3 mb-1">
          <hb-notification
            title-off
            v-model="currentInsuranceCaution"
            v-if="!shouldTakePaymentForCurrentPeriod && isCurrentPeriodSelected && isFreeInsuranceSelected"
            type="success"
            not-dismissable
          >
            The selected insurance is fully paid by the Free Coverage Credit.          
          </hb-notification>
          <hb-notification
            title-off
            v-model="currentInsuranceCaution"
            v-else-if="shouldTakePaymentForCurrentPeriod && isCurrentPeriodSelected && isDowngrade === true && !isNaionalAccount"
            type="caution"
            not-dismissable
          >
          The remaining balance will be applied to any open or future invoices.
          </hb-notification>
          <hb-notification
            title-off
            v-model="currentInsuranceCaution"
            v-else-if="shouldTakePaymentForCurrentPeriod && isCurrentPeriodSelected && isDowngrade === false && !isNaionalAccount"
            type="caution"
            not-dismissable
          >
            Payment is required to apply coverage for the current billing period.
          </hb-notification>
          <hb-notification
            title-off
            v-model="currentInsuranceCaution"
            v-if="displayAdjustInvoiceWarning"
            type="caution"
            not-dismissable
          >
            The invoice for next billing period already exists with an insurance. 
            The change will be in effect for any new invoices that would be generated.
            To update insurance in any existing invoices please adjust the invoice.
          </hb-notification>
        </div>
      </hb-form>

      <hb-form label="Cancel Form" v-if="currentInsurance && declineInsurance" required>
        <hb-radio-group v-model="cancelForm"
        v-validate.disable="{ required : true }"
        data-vv-name="billing_period"
        data-vv-as="Billing Period"
         data-vv-scope="cancel_form"
           :error-messages="errors.collect('cancel_form')"
        >
        <hb-radio
          :label="`Current Billing Period: ${currentBillingPeriodLabel}`"
            value="current"
          > 
          </hb-radio>
          <hb-radio
          :label="`Next Billing Period: ${
            billingPeriods.future &&
            $options.filters.formatDateServices(
              billingPeriods.future[0].start
            )
          } - ${
            billingPeriods.future &&
            $options.filters.formatDateServices(billingPeriods.future[0].end)
          }`"
            value="next"
          >
          </hb-radio>
        </hb-radio-group>
      </hb-form>

      <v-row no-gutters v-if="isMSRActive">
        <v-col class="services-section">

          <div class="mb-3" v-if="!documentLoading">
            <hb-notification
              type="caution"
              not-dismissable
              title-off
            >
              {{ LEASE.MULTI_SPACE_RENTAL_BANNERS.AUTOPAY_AND_INSURANCE_CONVERSION_BANNER }}
            </hb-notification>
          </div>
          
          <DocumentsSigner 
            v-if="!documentLoading" 
            :lease-id="lease.id" 
            :current-contact="contact" 
            :is-multi-space-lease="is_multi_space_lease" 
            :processType="LEASE_CONFIGURATION.INSURANCE_COVERAGE_SLUG" 
            @documentsSigned="checkStatus"
          />
          <v-row justify="center" align="center" v-if="documentLoading">
            <v-col class="text-center">
              <v-progress-circular
                width="2"
                size="32"
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <hb-form label="Cancel Form" v-if="currentInsurance && declineInsurance" required>
        <hb-radio-group v-model="cancelForm"
        v-validate.disable="{ required : true }"
        data-vv-name="billing_period"
        data-vv-as="Billing Period"
         data-vv-scope="cancel_form"
           :error-messages="errors.collect('cancel_form')"
        >
        <hb-radio
          :label="`Current Billing Period: ${currentBillingPeriodLabel}`"
            value="current"
          > 
          </hb-radio>
          <hb-radio
          :label="`Next Billing Period: ${
            billingPeriods.future &&
            $options.filters.formatDateServices(
              billingPeriods.future[0].start
            )
          } - ${
            billingPeriods.future &&
            $options.filters.formatDateServices(billingPeriods.future[0].end)
          }`"
            value="next"
          >
          </hb-radio>
        </hb-radio-group>
      </hb-form>

      <v-row v-if="displayPaymentModule && isDowngrade === false && !isNaionalAccount && isDowngrade === false && !isNaionalAccount" class="ma-0 pa-5">
        <v-col cols="12" class="ma-0 pa-0">
          <payment-process
            :contactID="contact.id"
            :propertyID="lease.Unit.property_id"
            paymentSource="INSURANCE"
          >
          </payment-process>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <hb-btn class="mr-2" v-if="displayPaymentModule && isDowngrade === false && !isNaionalAccount && hasPermission('tenant_profile_add_service_skip_payment')" color="secondary" :disabled="(disableInsurance || disablePayment)" :loading="(disableInsurance || disablePayment)" @click="skipPayment">Skip Payment</hb-btn>
      <hb-tooltip v-else-if="!hasPermission('tenant_profile_add_service_skip_payment') && displayPaymentModule">
        <template v-slot:item>
          <hb-btn color="secondary" disabled>Skip Payment</hb-btn>
        </template>
        <template v-slot:body>
          {{ !hasPermission('tenant_profile_add_service_skip_payment') ? 'You do not have the permission to skip payment' : 'You do not have the permission for the payment' }}
        </template>
      </hb-tooltip>

      <hb-btn
        v-if="displayPaymentModule && isDowngrade === false && !isNaionalAccount"
        color="primary"
        :disabled="disablePayment"
        :loading="disablePayment"
        @click="checkPaymentErrors"
        >Process Payment</hb-btn
      >
      <hb-btn v-else-if="cancelForm && check === 'current'" color="primary" @click="removeInsurance" :disabled="disableInsurance" :loading="disableInsurance"
        >Remove Insurance</hb-btn
      >
      <hb-btn v-else color="primary" @click="saveInsurance" :disabled="disableInsurance || !documentSigned" :loading="disableInsurance"
        >Save Insurance</hb-btn
      >
    </template>
  </hb-modal>
</template>

<script type="text/babel">
import moment from 'moment';
import CALENDAR from '@/constants/calendar.js';
import LEASE_CONFIGURATION from '@/constants/lease_configuration.js';
import LEASE from '@/constants/lease.js';
import api from "../../../assets/api.js";
import PaymentProcess from "../../includes/PaymentProcess/Index.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "../../../EventBus.js";
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
import TableRateInsurance from '../../table_rate_insurance/Index.vue';
import DocumentsSigner from '../../includes/DocumentsSigner.vue';
import { settingsMixin } from "@/mixins/settingsMixin.js";
import SETTINGS from "@/constants/settings.js"; 
import insurance from '../../../assets/api/insurance.js';

// Updated component for adding insurance
export default {
  name: "AddNewInsurance",
  mixins: [ notificationMixin, settingsMixin ],
  data() {
    return {
      insurances: [],
      selectedInsurance: null,
      insuranceStart: "current",
      currentInsuranceCaution: true,
      disablePayment: false,
      declineInsurance: false,
      isFreeInsuranceSelected: false,
      newInsuranceApplicationDate: null,
      insurance_exp_year: "",
      insurance_exp_month: "",
      disableInsurance: false,
      invoicesToPay: [],
      shouldDisableCurrentPeriod: false,
      shouldOfferFreeInsurance: false,
      shouldTakePaymentForCurrentPeriod: false,
      table_rate_insurance: false,
      coverage_value: "",
	    isUpgrade: false,
      isDowngrade: false,
      loader: false,
      documentSigned: true,
      documentLoading: true,
      is_save: false,
      cancelForm: '',
      billingPeriods: {},
      check: '',
      isNaionalAccount: false,
      updatedBillingLabel: ""
    };
  },

  props: {
    lease: {
      type: Object,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
    showAddInsuranceModal: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    currentInsurance: {
      type: Object,
      default: null
    },
    declinedInsurance: {
      type: Object,
      default: null
    },
    durationPeriodColor: {
      type: String,
      required: false,
      default: "hb-text-light",
    }
  },

  components: {
    PaymentProcess,
    TableRateInsurance,
    DocumentsSigner,
  },

  async created() {
    this.CALENDAR = CALENDAR;
    this.LEASE_CONFIGURATION = LEASE_CONFIGURATION;
    this.LEASE = LEASE;
    this.SETTINGS = SETTINGS;
    await this.fetchInsurances();
    await this.fetchNextBillingPeriods();
    this.setDefaultInsuranceStart();
    this.table_rate_insurance = this.insurances.length && this.insurances[0]?.insurance_type === "table_rate" ? true : false;
    this.isNaionalAccount = this.contact?.NationalAccount?.id ? true : false;

    EventBus.$on("paymentReader", this.enablePayment);
    if(this.lease?.Unit?.id)
      await this.$store.dispatch('onBoardingStore/getTemplate',{configuration_unit:this.lease?.Unit, refetchTemplate:true});
    if(this.contact?.id && this.lease?.Unit?.property_id && this.lease?.Unit?.unit_type_id){
        const params = {
          contact_id: this.contact?.id,
          property_id: this.lease.Unit.property_id,
          unit_type_id: this.lease.Unit.unit_type_id,
        }
        await this.$store.dispatch("onBoardingStore/updateSingleSpaceRental",params);
    }
    await this.addMSRCheckListLeaseItem();
  },

  destroyed() {
    EventBus.$off("paymentReader", this.enablePayment);
    if(!this.is_save && this.isMSRActive){
      api.put(this, api.LEASES + 'delete-checklist-leases-uploads',{ lease_ids: [this.lease?.id] });
    }
  },

  computed: {
    ...mapGetters({
      getLeases: "paymentsStore/getLeases",
      getErrors: "paymentsStore/getErrors",
      template: 'onBoardingStore/template',
      items: 'onBoardingStore/items',
      singleSpaceRentalExists: "onBoardingStore/getSingleSpaceRental"
    }),
    isInsuranceDeclined() {
      return !this.currentInsurance || !this.currentInsurance.id || this.declinedInsurance.active == 1;
    },
    getInvoice() {
      return {
        selectedInsurance: this.selectedInsurance,
        insuranceStart: this.insuranceStart,
        isFreeInsuranceSelected: this.isFreeInsuranceSelected,
        coverage_value: this.coverage_value,
      };
    },
    displayPaymentModule() {
      return (
        this.shouldTakePaymentForCurrentPeriod &&
        this.selectedInsurance &&
        this.selectedInsurance.id &&
        this.isCurrentPeriodSelected
      );
    },
    shouldFetchInvoice(){
      return (
        ((this.table_rate_insurance && this.coverage_value) || !this.table_rate_insurance) &&
        this.selectedInsurance &&
        this.selectedInsurance.id &&
        this.isCurrentPeriodSelected 
      );
    },
    isCurrentPeriodSelected() {
      return this.insuranceStart === "current";
    },
    displayAdjustInvoiceWarning() {
      if(this.newInsuranceApplicationDate && !this.isCurrentPeriodSelected) {
        return moment(this.newInsuranceApplicationDate) > moment(this.billingPeriods.future[0].start);
      }
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    currentBillingPeriodLabel() {
      const period = this.isFreeInsuranceSelected ? this.billingPeriods?.free_insurance_period : this.billingPeriods?.current;

      const startDate = period && this.$options.filters.formatDateServices(period.start);
      const endDate = period && this.$options.filters.formatDateServices(period.end);
      let adjustedStartDate = startDate;
      if ((this.isDowngrade === true && startDate) || this.declineInsurance) {
            adjustedStartDate = moment(startDate).add(1, 'days').format("MMM DD, YYYY");
      }
        return `${adjustedStartDate} - ${endDate}`;
    },
    is_multi_space_lease() { 
      if(!Object.keys(this.currentTemplate).length) return false;
      if (!this.currentTemplate?.multi_space_rental || this.singleSpaceRentalExists?.flag) return false;  
      this.documentSigned = true;
      return true;
    },
    currentTemplate(){
      return this.template[this.lease?.unit_id] || {}; 
    },
    isMSRInsuranceTriggerEnabled(){
      return this.currentTemplate?.multi_space_rental_types.length && this.currentTemplate?.multi_space_rental_types.some(item => item.slug === LEASE_CONFIGURATION.INSURANCE_COVERAGE_SLUG && item.active === 1) || false;
    },
    isMSRActive(){
      return this.getFeatureSettingValueByName(SETTINGS.ALLOW_MULTI_SPACE_RENTAL) && this.currentTemplate?.multi_space_rental && this.isMSRInsuranceTriggerEnabled && this.singleSpaceRentalExists?.flag && this.lease?.id;
    }
  },

  methods: {
    ...mapMutations({
      createLeaseStructure: "paymentsStore/createLeaseStructure",
      setLeaseBilledMonths: "paymentsStore/setLeaseBilledMonths",
      setCheckErrors: "paymentsStore/setCheckErrors",
    }),
    ...mapActions({
      getTransformedPaymentObject: "paymentsStore/getTransformedPaymentObject",
    }),
    isSameInsurance(insurance) {
    return (
      this.currentInsurance &&
      insurance.name === this.currentInsurance.name
    );
    },
    checkInsuranceStatus(insurance) {
      this.isUpgrade = false;
      this.isDowngrade = false;

      if (insurance && this.currentInsurance) {
        if (insurance.premium > this.currentInsurance.price) {
          this.isUpgrade = true; 
        } else if (insurance.premium < this.currentInsurance.price) {
          this.isDowngrade = true; 
        }
      }
      if (this.isDowngrade && this.insuranceStart === 'current') {
        this.updatedBillingLabel = this.currentBillingPeriodLabel;
      }   
    },
    setDefaultInsuranceStart() {
      if(this.shouldDisableCurrentPeriod) {
        this.insuranceStart = "next";
      } 
    },
    checkPaymentErrors() {
      this.setCheckErrors({ value: true });
    },
    async fetchInsurances() {
      try {
        const { id: leaseId } = this.lease;
        const res = await api.get(this, `${api.LEASES}/${leaseId}/insurance`);
        this.insurances = res.data.options;
      } catch (err) {
        console.log("err ", err);
      }
    },
    emitServiceAddedEvents() {
      if(!this.cancelForm) {
        this.showMessageNotification({ type:'success', description: 'Coverage has been changed. View the changes on the tenant profile.' });
      } else {
        this.showMessageNotification({ type:'success', description: 'Coverage has been removed. View the changes on the tenant profile.' });
      }
      EventBus.$emit("closeAddInsuranceModal");
      EventBus.$emit("fetchInsurances");
    },
    async fetchNextBillingPeriods() {
      try {
        const res = await api.get(
          this,
          `${api.LEASES}/${this.lease.id}/insurance-billing-periods?future_months=1`
        );
        this.billingPeriods = res.billing_months;
        // this.shouldDisableCurrentPeriod = false;
        this.shouldDisableCurrentPeriod = !res.should_enable_current_period || (this.currentInsurance && this.currentInsurance.id);
        this.shouldOfferFreeInsurance = res.enable_free_insurance;
      } catch (err) {
        console.log("err ", err);
      }
    },
    async saveInsurance(dryRun = false) {
      if(!this.declineInsurance && !this.selectedInsurance?.id) {
        this.showMessageNotification({ type: 'error', description: 'Please select an insurance or decline coverage.' }); 
        return;
      }
      let status = true;      
      if(this.declineInsurance) {
        status = await this.$validator.validateAll(
              "cancel_form"
            );
      }
      console.log('status: ', status);
      
      if(status) {
        this.disableInsurance = true;
        const insuranceData = {
          decline: false,
          dryrun: dryRun,
          contact_id: this.contact.id,
          property_id: this.lease.Unit.property_id,
          is_free_insurance: this.isFreeInsuranceSelected
        };
        if(this.table_rate_insurance && this.coverage_value) {
          insuranceData.coverage_amount = this.coverage_value;
        }

        if(this.declineInsurance) {
          insuranceData.decline = true;
          insuranceData.insurance_exp_month = this.insurance_exp_month; 
          insuranceData.insurance_exp_year = this.insurance_exp_year;
          return await this.removeInsurance();
        } else {
          insuranceData.insurance_id = this.selectedInsurance.id;
          insuranceData.starting_date = this.insuranceStart === "current" ? moment().format('YYYY-MM-DD')  : this.billingPeriods.future[0].start;
          insuranceData.serviceEnd =this.insuranceStart;
          // insuranceData.starting_date = !this.isCurrentPeriodSelected ? this.billingPeriods.future[0].start : '';
        }
  
        insuranceData.is_msr = this.getFeatureSettingValueByName(SETTINGS.ALLOW_MULTI_SPACE_RENTAL) && this.currentTemplate?.multi_space_rental && this.singleSpaceRentalExists?.flag;
        const response = await this.getOrSaveInsuranceDetails(insuranceData, !dryRun);
        this.is_save = true;
        if(insuranceData.is_msr) EventBus.$emit('communication::refresh', {contact_id: this.contact?.id});
        return response; 
      }
    },
    async removeInsurance(dryRun = false) {
      let status = true;
      if(this.declineInsurance) {
        status = await this.$validator.validateAll(
              "cancel_form"
            );
      }
      console.log('status: ', status);
      if(status) {
        this.disableInsurance = true;
        const insuranceData = {
          decline: false,
          dryrun: dryRun,
          contact_id: this.contact.id,
          property_id: this.lease.Unit.property_id,
          is_free_insurance: this.isFreeInsuranceSelected
        };
        if(this.declineInsurance) {
        console.log('current --> ', this.currentInsurance);
        
          insuranceData.decline = true;
          insuranceData.insurance_exp_month = this.insurance_exp_month; 
          insuranceData.insurance_exp_year = this.insurance_exp_year;
          insuranceData.currentInsuranceId = this.currentInsurance.id;
          console.log(this.check, 'check::');
          
          if(this.check && this.check === 'current') {
            
            insuranceData.startDate = this.billingPeriods?.current.start;
            insuranceData.endDate = this.billingPeriods?.current.end;
          } else if(this.check && this.check === 'next') {
            insuranceData.startDate = this.billingPeriods?.future[0].start;
            insuranceData.endDate = this.billingPeriods?.future[0].end;
          }
          console.log('insurancedata --> ', insuranceData);
          this.disableInsurance = false;
          const response = await api.delete(
        this,
        `${api.LEASES}${this.lease.id}/insurance?startDate=${insuranceData.startDate}&period=${this.check}&exp_month=${insuranceData.insurance_exp_month}&exp_year=${insuranceData.insurance_exp_year}`);
        console.log('response --> ', response);
        
          this.emitServiceAddedEvents();        
          return response;
        }
      }
      if(!dryRun) {
        this.emitServiceAddedEvents();
      }
      this.disableInsurance = false;
      return response; 
    },

    async removeInsurance(dryRun = false) {
      let status = true;
      if(this.declineInsurance) {
        status = await this.$validator.validateAll(
              "cancel_form"
            );
      }
      console.log('status: ', status);
      if(status) {
        this.disableInsurance = true;
        const insuranceData = {
          decline: false,
          dryrun: dryRun,
          contact_id: this.contact.id,
          property_id: this.lease.Unit.property_id,
          is_free_insurance: this.isFreeInsuranceSelected
        };
        if(this.declineInsurance) {
        console.log('current --> ', this.currentInsurance);
        
          insuranceData.decline = true;
          insuranceData.insurance_exp_month = this.insurance_exp_month; 
          insuranceData.insurance_exp_year = this.insurance_exp_year;
          insuranceData.currentInsuranceId = this.currentInsurance.id;
          console.log(this.check, 'check::');
          
          if(this.check && this.check === 'current') {
            
            insuranceData.startDate = this.billingPeriods?.current.start;
            insuranceData.endDate = this.billingPeriods?.current.end;
          } else if(this.check && this.check === 'next') {
            insuranceData.startDate = this.billingPeriods?.future[0].start;
            insuranceData.endDate = this.billingPeriods?.future[0].end;
          }
          console.log('insurancedata --> ', insuranceData);
          this.disableInsurance = false;
          const response = await api.delete(
        this,
        `${api.LEASES}${this.lease.id}/insurance?startDate=${insuranceData.startDate}&period=${this.check}&exp_month=${insuranceData.insurance_exp_month}&exp_year=${insuranceData.insurance_exp_year}`);
        console.log('response --> ', response);
        
          this.emitServiceAddedEvents();        
          return response;
        }
      }
      if(!dryRun) {
        this.emitServiceAddedEvents();
      }
      this.disableInsurance = false;
      return response; 
    },

    async skipPayment() {
      if (this.hasPermission('tenant_profile_add_service_skip_payment')) {
        try {
          this.disablePayment = true;
          const insuranceData = {
            decline: false,
            dryrun: false,
            contact_id: this.contact.id,
            property_id: this.lease.Unit.property_id,
            is_free_insurance: this.isFreeInsuranceSelected,
            skip_payment: true,
            serviceEnd: this.insuranceStart,
            insurance_id: this.selectedInsurance.id,
          };
          if(this.table_rate_insurance && this.coverage_value) {
            insuranceData.coverage_amount = this.coverage_value;
          }
      
          const response = await this.getOrSaveInsuranceDetails(insuranceData);
          if (response.error) {
            this.showMessageNotification({ description: response.error });
            this.disablePayment = false;
            return;
          }
          this.emitServiceAddedEvents();        
          return response;

        } catch (err) {
          this.showMessageNotification({ description: err })
        } finally {
          this.disablePayment = false;
        }
      } else {
        this.showMessageNotification({ type: 'error', description: 'You do not have the permission to skip payment' });
      }
    },   
    async getOrSaveInsuranceDetails(insuranceData, flag = false) {
      insuranceData.serviceEnd =this.insuranceStart;
      let response = {}; 
      try{
        response = await api.put(
          this,
          `${api.LEASES}/${this.lease.id}/insurance`,
          insuranceData
        );

        const invoice = response.Invoice;
        this.shouldTakePaymentForCurrentPeriod = !(this.isFreeInsuranceSelected && invoice?.total_payments === (invoice?.sub_total + invoice?.total_tax - invoice?.total_discounts));

        if(flag) {
          this.emitServiceAddedEvents();
        }

        if (!this.isNationalAccount && ((!this.isUpgrade && !this.isDowngrade) || this.isUpgrade)){
          EventBus.$emit('handleSourcePropertyUpdate');
        }
        return response;
      } catch (error) {
        this.showMessageNotification({ type: 'error', description: error });
        response = {
          error,
          ...response
        };
      } finally{
        this.disableInsurance = false;
        return response;
      }
    },
    async payInvoice(data) {
      const response = await this.getOrSaveInsuranceDetails(data);
      return response;
    },
    async fetchInvoice() {
      const response = await this.saveInsurance(true);
      this.invoicesToPay = [response.Invoice];
      this.createLeaseStructure({
        invoices: this.invoicesToPay,
        lease: this.lease,
        unit: this.lease.Unit,
        updateInvoice: this.getLeases && this.getLeases.length,
      });
      this.setLeaseBilledMonths({
        leaseIndex: 0,
        billedMonths: 0,
      });
    },
    async fetchNewInsuranceApplicationDate() {
      try {
        const res = await api.get(this, `${api.LEASES}/${this.lease.id}/insurance-start-date`);
        this.newInsuranceApplicationDate = res;
      } catch (err) {
        console.log("err ", err);
      }
    },
    enablePayment(response) {
      this.disablePayment = false;
      if(response && response.error) {
        this.showMessageNotification({ description: response.error });
      } else if(response && response.data && response.data.msg) {
        this.showMessageNotification({ type: 'success', description: response.data.msg.text });
      }
    },
    checkPaymentErrors() {
      this.setCheckErrors({ value: true });
    },
    async processPayment() {
      this.disablePayment = true;
      const paymentInfo = await this.getTransformedPaymentObject({
        id: this.$options.name,
        formErrors: this.formErrors,
      });

      if (paymentInfo.errors && paymentInfo.errors.length) {
        this.showMessageNotification({ list: paymentInfo.errors });
        this.disablePayment = false;
        return;
      }

      const insuranceData = {
        ...paymentInfo,
        decline: false,
        dryrun: false,
        insurance_id: this.selectedInsurance.id,
        is_free_insurance: this.isFreeInsuranceSelected
      };
      if(this.table_rate_insurance && this.coverage_value) {
        insuranceData.coverage_amount = this.coverage_value;
      }
      insuranceData.serviceEnd =this.insuranceStart;

      if (paymentInfo && paymentInfo.payment) {
        try {
          const responseData = await this.getOrSaveInsuranceDetails(insuranceData);      
          if (responseData.error) {
            this.showMessageNotification({ description: responseData.error });
            this.disablePayment = false;
            return;
          }
          EventBus.$emit('lease_edited');
          this.emitServiceAddedEvents();
          if(responseData && responseData.payment_id) {
            EventBus.$emit('showPaymentDone', responseData.payment_id);
          }
        } catch(err){
          this.showMessageNotification({ description: err });
        } finally {
          this.disablePayment = false;
        }
      }
    },
    updateCoverageValue(coverageValue){
      this.coverage_value = coverageValue;
      this.isUpgrade = false;
      this.isDowngrade = false;
      
      const existingInsuranceCoverage = this.currentInsurance.insurance?.coverage 
      console.log("new coverage",  this.coverage_value); 
      console.log("existing Coverage", existingInsuranceCoverage);
      if (this.coverage_value > existingInsuranceCoverage) {
        this.isUpgrade = true;
      } else if (this.coverage_value < existingInsuranceCoverage) {
        this.isDowngrade = true;
      }
      if (this.isDowngrade && this.insuranceStart === 'current') {
      }        this.updatedBillingLabel = this.currentBillingPeriodLabel;
    },
    async addMSRCheckListLeaseItem(){
      if(this.isMSRActive){
          try {
            let data = {
              leases_data: [{lease_id: this.lease?.id, unit_id: this.lease?.unit_id, template_id: this.currentTemplate?.id}]
            }
            await api.post(this, api.LEASES + '/create-checklist-leases', data); 
            this.documentLoading = false;
          } catch (error) {
            this.showMessageNotification({ type: 'error', description: error });
          }
      }
    },
    checkStatus(status){
       this.documentSigned = status;
    }
  },

  watch: {
    async getInvoice() {
      if (this.shouldFetchInvoice) {
        await this.fetchInvoice();
        EventBus.$emit('updatePaymentApplication');
      }
    },
    selectedInsurance(insurance) {
      if(insurance && insurance.id) {
        this.declineInsurance = false;
        this.cancelForm = '';
      }
    },
    declineInsurance(check) {
      if(check) {
        this.selectedInsurance = null;
      }
    },
    cancelForm(period) {      
      if(['current', 'next'].includes(period)) {
        this.selectedInsurance = null;
        this.check = period == 'current' ? 'current': 'next';
      }
    },
    async insuranceStart(period) {
      if(!this.newInsuranceApplicationDate && period === 'next') {
        await this.fetchNewInsuranceApplicationDate();
      }
    },
    getErrors(value) {
      if(value === false) {
        this.processPayment();
        this.setCheckErrors({ value: null });
      }
    }
  },
};
</script>

<style scoped>
.start-insurance .v-messages {
  min-height: 0px;
}
.services-section{
  padding: 20px 24px 20px 24px;
}
</style>