<template>
    <div>
        <div v-if="!documentsLoaded">
          <v-row justify="center" align="center">
            <v-col class="text-center ma-12">
              <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
            </v-col>
          </v-row>
        </div>
        <div class="scrollable-content" v-else-if="signature_items.length && documentsLoaded">
          
            <v-expansion-panels v-model="panels" multiple>
              <hb-expansion-panel content-padding :success="completed_documents ? true : false" :topMargin="false">
                <template v-slot:title>
                  {{ title }}
                </template>

                <template v-slot:content>
                  <v-row no-gutters>
                    <v-col class="pa-0 ma-0">
                      <div class="mb-4">Please have the Tenant sign their agreements to finalize the rental.</div>
                      <v-container class="px-0 py-0">
                        <v-row no-gutters v-for="item in signature_items" :key="item.id">
                          <v-col class="py-0 px-1 col mb-6" v-if="status_rank(item) === 1 || status_rank(item) === 2">
                            <v-progress-circular
                              indeterminate
                              class="pa-0 ma-0 mr-2"
                              size="16"
                              width="2"
                              color="primary"
                            ></v-progress-circular>
                            {{ item.name }}
                          </v-col >

                          <v-col class="ma-0 pa-0 mb-1 selection-controls-fix" v-else>
                            <v-checkbox class="ma-0 pa-0" v-if="status_rank(item) <= 5 && !item.completed" :label="item.name" :value="item.id" v-model="docs_to_sign" dense hide-details="auto"></v-checkbox>
                            <v-checkbox class="ma-0 pa-0" v-else disabled :label="item.name" v-model="item.completed" dense hide-details="auto"></v-checkbox>
                            <span v-if="(item.status === 'complete' || item.status === 'finished')" :class="{'hb-text-accept': item.status === 'complete' || item.status === 'finished'}">
                            <hb-icon v-if="item.status === 'complete' || item.status === 'finished'" color="#02AD0F" style="position:relative;top:-2px;">mdi-check</hb-icon>
                                {{status_message(item)}}
                            </span>
                          </v-col>

                          <v-col class="text-right ma-0 pa-0" v-if="!(item.status === 'complete' || item.status === 'finished')" >
                            <span class="ml-1 mr-2">{{status_message(item)}}</span>
                          </v-col>

                          <v-col class="text-right flex-shrink ma-0 pa-0 mt-n2 mb-0" style="flex: 0 1" >
                            <hb-menu options>
                              <v-list>
                                <v-list-item v-if="(item.completed  && item.Upload.src) || status_rank(item) > 4 || (status_rank(item) >= 3 && item.Upload.src)" :ripple="false">
                                  <v-list-item-title>
                                    <a :href="item.Upload.src" target="_blank" style="text-decoration: none;">Download/Print</a>
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-show="status_rank(item) < 5 && !item.completed" @click="upload(item)" :ripple="false">
                                  <v-list-item-title>Upload Document</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="remove(item)" :ripple="false">
                                  <v-list-item-title>Discard and Start Over</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="fetchData" :ripple="false">
                                  <v-list-item-title>Refresh</v-list-item-title>
                                </v-list-item>

                              </v-list>
                            </hb-menu>

                          </v-col>
                        </v-row>
                      </v-container>
                      <v-container class="px-0 py-0 mt-2">
                        <hb-btn :disabled="!ready_to_sign" :loading="openSendDocumentInProgress" color="primary" @click="openSendDocument" class="mr-3">Send a Link for Signing</hb-btn>
                        <hb-btn :disabled="!ready_to_sign" @click="sign_documents" color="secondary"  class="mr-3">Sign on This Device</hb-btn>
                        <hb-btn :disabled="!ready_to_sign" :loading="signLinkInProgress" color="secondary" @click="getSignLink" class="mr-3">Copy Link</hb-btn>
                      </v-container>

                    </v-col>
                  </v-row>
                </template>
              </hb-expansion-panel>
            </v-expansion-panels>
        </div>
        <div v-else-if="!isAutopayOrInsuranceProcessType && isMultiSpaceLease" class="scrollable-content"> 
            <v-expansion-panels v-model="successPanels" multiple>
                <hb-expansion-panel :success="true" :topMargin="false" :expand-icon-off="true" content-padding>
                    <template v-slot:title>
                        All Documents Signed
                    </template>

                    <template v-slot:content>
                    <v-row no-gutters>
                        <v-col class="pa-0 ma-0">
                        <div>
                            This tenant is on a 'Multi Space Lease'. No additional document signing is necessary.
                        </div> 
                        </v-col>
                    </v-row>
                    </template>
                </hb-expansion-panel>
            </v-expansion-panels>
        </div>

        <v-expansion-panels v-if="allowMoveInChecklist" v-model="checklistPanels" multiple>
              <hb-expansion-panel v-show="standard_items.length" content-padding>
                <template v-slot:title>
                  Move-In Checklist
                </template>

                <template v-slot:content>
                  <HbCheckbox
                    v-for="(item, i) in std_items"
                    :key="item.id"
                    @change="updateChecklist(item)"
                    v-model="item.completed"
                    :label="item.name"
                    :class="{'mt-3' : i > 0}"
                  />
                </template>
              </hb-expansion-panel>
        </v-expansion-panels>

        

        <SignWrapper
            @close="sign_process = false"
            @completed="closeSignProcess"
            @updateChecklistItem="updateChecklistItem"
            :property_id="property.id"
            v-if="uploads_to_sign.length && sign_process"
            :uploads="uploads_to_sign"
            :signer_id="signer_id"
        ></SignWrapper>

        <v-dialog v-model="send_docs" width="500">
            <send-for-signature
                :signer="to_send"
                :lease_id="lease_id"
                @close="send_docs = false">
            </send-for-signature>
        </v-dialog>


        <send-document
            v-model="email_doc_copy"
            v-if="email_doc_copy"
            :upload_id="file.upload_id"
            :lease_id="lease_id"
            @close="email_doc_copy = false"
        ></send-document>

        <upload-file
            v-model="upload_document"
            v-if="upload_document"
            type="file"
            model="leases"
            :foreign_id="lease_id"
            size="lg"
            slot="body"
            @close="upload_document=false"
            @save="completeUpload">
        </upload-file>

        <hb-modal v-model="sendDocument" title="Send for Signing" show-help-link>
            <template v-slot:content>
                <hb-form label="Number of Documents">
                {{send_doc_data.docs.length}}
                </hb-form>

                <hb-form label="Message">
                    <HbTextarea v-model="send_doc_data.message" />
                </hb-form>

                <hb-form label="Method" :divider="false" required>
                    <div class="pb-12">
                        <hb-radio-group v-model="send_doc_data.method" v-validate="'required'" data-vv-scope="send" data-vv-as="Method">
                            <hb-radio
                                v-if="send_doc_data.contact.email"
                                key="Email"
                                :label="'Email ' + send_doc_data.contact.email"
                                value="email"
                            ></hb-radio>
                            <hb-radio
                                v-for="(phone, i) in send_doc_data.contact.Phones"
                                :key="i"
                                :label="'Text ' + $options.filters.formatPhone(phone.phone)"
                                :value="phone.id"
                            ></hb-radio>
                        </hb-radio-group>
                    </div>
                </hb-form>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" :disabled="sendSignLinkInProgress" :loading="sendSignLinkInProgress" @click="sendSignLink">Send Link</hb-btn>
            </template>
        </hb-modal>
    </div>

</template>

<script type="text/babel">
    import Vue from "vue";
    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    import SignWrapper from '../signing/SignDocumentsWrapper.vue';
    import SendForSignature from '../includes/SendForEsign.vue';
    import SendDocument from '../includes/SendDocument.vue';
    import { mapGetters, mapActions } from 'vuex';
    import { EventBus } from '../../EventBus.js';
    import UploadFile from '../assets/UploadFile.vue';
    import { notificationMixin } from  '@/mixins/notificationMixin.js';
    import LEASE_CONFIGURATION from '@/constants/lease_configuration.js';
    export default {
        name: "DocumentsSigner",
        mixins: [notificationMixin],
        data() {
            return { 
                upload_document : false,
                send_docs : false,
                email_doc_copy : false,
                file: {},
                all_documents: 'all',
                to_send_sms: false,
                documentsLoaded: false,
                interval: '',
                docs_to_sign: [],
                sign_method: '',
                to_send: {},
                printLoading: false,
                documents_to_sign: [],
                sign_process: false,
                uploads_to_sign:[],
                signer_id: null,
                active_item: {},
                link:'',
                saved: false, 
                panels: [0,1],
                successPanels: [],
                checklistPanels: [],
                selected_upload: {},
                statuses: [
                    'error',
                    'not_processed',
                    'loading',
                    'ready',
                    'loaded',
                    'initiated',
                    'complete',
                    'finished',
                ],
                sendDocument: false,
                send_doc_data: {
                    message: '',
                    method: '',
                    contact: {},
                    docs: []
                },
                currentDate: '',
                sendSignLinkInProgress: false,
                signLinkInProgress: false,
                openSendDocumentInProgress: false,
                items: [],
                std_items: [],
            }
        },
        components:{
            Status,
            Loader,
            SignWrapper,
            SendForSignature,
            SendDocument,
            UploadFile
        }, 
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            isMultiSpaceLease: {
                type: Boolean,
                default: false
            },
            leaseId:{
              type:String,
              default:null
            },
            currentContact:{
              type:Object,
              default: () => ({})
            },
            processType:{
              type:String,
              default:null
            },
            title:{
              type:String,
              default: 'Document Signing'
            },
            allowMoveInChecklist:{
                type: Boolean,
                default: false
            }
        },
        async created (){ 
            this.LEASE_CONFIGURATION = LEASE_CONFIGURATION; 
            this.$emit('update:loading', false);
            await this.fetchData(false);
            EventBus.$on('pandadoc_generation_update', this.updateSignature);

            this.docs_to_sign = this.signature_items.map(si => si.id); 

            this.std_items = JSON.parse(JSON.stringify(this.standard_items));

            if(this.items.length) {
              this.documentsLoaded = true;
              this.interval = setInterval(() => this.checkDocumentsUploaded(), 3000);
            }
            this.currentDate = moment().format('YYYY-MM-DD');
            if(this.invoices.length && this.invoices[0].id){
                this.panels= [0,1,2];
                this.successPanels=[0];
                this.checklistPanels= [0];
            }
        },
        beforeDestroy(){
            EventBus.$off('pandadoc_generation_update', this.updateSignature); 
            clearInterval(this.interval);
        },
        destroyed(){
          this.lease.id = '';
          this.items = [];
        },
        computed: {
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                property: 'onBoardingStore/property',
                lease: 'onBoardingStore/lease', 
                contact: 'onBoardingStore/contact',
                invoices: 'onBoardingStore/invoices'
            }),
            standard_items(){
                return this.items.filter(c => !c.document_id);
            },
            signature_items(){
                return this.items.filter(c => c.document_id);
            },
            completed_documents(){
              return this.signature_items.every(c => c?.completed);
            },
            ready_to_sign(){
                return !this.signature_items.filter(item => !item?.completed && this.status_rank(item) < 3).length && this.signature_items.filter(item => !item?.completed).length && this.docs_to_sign.filter(id => {
                        return !this.items.find(item => item.id === id)?.completed;
                }).length;
            },
            isAutopayOrInsuranceProcessType() {
             return [this.LEASE_CONFIGURATION.AUTOPAY_ENROLLMENT_SLUG, this.LEASE_CONFIGURATION.INSURANCE_COVERAGE_SLUG].includes(this.processType);
            },
            lease_id(){
                return this.leaseId || this.lease?.id;
            }
        },
        methods: {
            async checkDocumentsUploaded() {
                await this.fetchData(true);
                if(this.items.length) {
                    this.documentsLoaded = true;
                    let document_loaded = this.items.filter(si => (si.status != 'finished' && si.status != 'complete'));
                    if(!document_loaded.length) {
                        clearInterval(this.interval);
                    }
                }
            },
            openSendDocument(item) {
                this.openSendDocumentInProgress = true;
                let docs = [];
                if (!this.docs_to_sign || !this.docs_to_sign.length) { 
                    this.openSendDocumentInProgress = false;
                    this.showMessageNotification({ type: 'error', description: 'Please choose documents to sign.'}); 
                    return
                }
                if (!Array.isArray(this.docs_to_sign)) {
                    this.docs_to_sign = [this.docs_to_sign];
                }
                for (let i = 0; i < this.docs_to_sign.length; i++) {
                    let doc = this.signature_items.find(si => si.id === this.docs_to_sign[i]);
                    if (!doc.completed) {
                        docs.push(doc);
                    }
                }

                if (!docs.length || !docs[0].Upload || !docs[0].Upload.signers || !docs[0].Upload.signers.length || docs[0].Upload.signers.Contact) {
                    this.openSendDocumentInProgress = false;
                    this.showMessageNotification({ type: 'error', description: 'Document or signer information is not available. Please refresh the page and try again.' });
                    return;
                }
                this.send_doc_data.docs = docs;
                this.send_doc_data.contact = this.contact.first ? this.contact : this.currentContact;

                let contact = this.contact.first ? this.contact : this.currentContact;

                if (contact.Phones && contact.Phones.length) {
                    this.send_doc_data.method = contact.Phones[0].id;
                } else {
                    this.send_doc_data.method = 'email';
                }
                this.send_doc_data.message = "Please click the link to sign your documents";
                this.sendDocument = true;
                this.openSendDocumentInProgress = false;
            },

            async sendSignLink() {
                this.sendSignLinkInProgress = true;
                let send_status = await this.$validator.validateAll('send');

                if (!send_status) {
                    this.sendSignLinkInProgress = false;
                    this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                    return;
                }

                var data = {
                    docs: this.send_doc_data.docs,
                    contact_id: this.contact.id || this.currentContact?.id,
                    property_id: this.property.id,
                    method: this.send_doc_data.method,
                    message: this.send_doc_data.message
                };

                try {
                    let response = await api.post(this, api.SEND_SIGN_LINK, data, "sendSignLink");

                    if (response) {
                        this.sendDocument = false;

                        if (this.send_doc_data.method === 'email') {
                            this.showMessageNotification({ type: 'success', description: 'An email with instructions has been sent to this customer.' });
                        } else {
                            this.showMessageNotification({ type: 'success', description: 'A text with instructions has been sent to this customer.' });
                        }
                    }
                } catch (err) {
                    this.showMessageNotification({ description: err });
                } finally {
                    this.sendSignLinkInProgress = false;
                };
            },

            status_message(item) {
                const statusMessages = {
                    'error': "An error occurred, please try again",
                    'not_processed': "loading",
                    'loading': "loading",
                    'ready': "Ready to sign",
                    'loaded': "Ready to sign",
                    'initiated': "Signing Started",
                    'complete': this.getSignerMessage(item),
                    'finished': this.getSignerMessage(item)
                };
            
                return statusMessages[item.status] || "";
            },
            
            getSignerMessage(item) {
                const signer = item.Upload?.signers && item.Upload.signers[0];
                if (signer && signer.signed) {
                    return `Signed By: ${signer.Contact.first} ${signer.Contact.last} | ${this.$options.filters.formatLocalDateTime(signer.signed)}`;
                } else {
                    return "Signing complete. Finalizing document";
                }
            },

            async getSignLink(item) {

                this.signLinkInProgress = true;
                let docs = [];
                if (!this.docs_to_sign || !this.docs_to_sign.length) { 
                    this.this.signLinkInProgress = false;
                    this.showMessageNotification({ type: 'error', description: 'Please choose documents to sign.' }); 
                    return;
                }

                if (!Array.isArray(this.docs_to_sign)) {
                    this.docs_to_sign = [this.docs_to_sign];

                }
                for (let i = 0; i < this.docs_to_sign.length; i++) {
                    let doc = this.signature_items.find(si => si.id === this.docs_to_sign[i]);
                    if (!doc.completed) {
                        docs.push(doc);
                    }
                }

                if (!docs.length || !docs[0].Upload || !docs[0].Upload.signers || !docs[0].Upload.signers.length || docs[0].Upload.signers.Contact) {
                    this.this.signLinkInProgress = false;
                    this.showMessageNotification({ type: 'error', description: 'Document or signer information is not available. Please refresh the page and try again.' });
                    return;
                }
                var data = {
                    docs: docs,
                    contact_id: docs[0].Upload.signers[0].Contact.id,
                    property_id: this.property.id
                };
                try {
                    let response = await api.post(this, api.GET_SIGN_LINK, data);
                    this.link = response.link?.shortUrl;
                    if(this.link){
                        await navigator.clipboard.writeText(this.link); 
                        this.showMessageNotification({ type: 'success', description: 'Link copied successfully.' });
                    }
                    else{
                      this.showMessageNotification({ type: 'error', description: 'Link is not available to copy' });
                    }
                } catch (error) {
                    this.showMessageNotification({ type: 'error', description: error });
                } finally {
                    this.signLinkInProgress = false;
                }

            },

            updateSignature(payload) {
                this.updateChecklistItem(payload);
            },

            updateChecklistItem(payload){
                let index = this.items.findIndex(item => item.id === payload?.checklist_id);

                if(index < 0) {
                    index = this.items.findIndex(item => item.Upload && item.Upload.id === payload?.Upload?.id);
                }

                if(index < 0) return;

                Vue.set(this.items[index], 'completed', payload?.completed);
                Vue.set(this.items[index], 'status', payload?.status);
                Vue.set(this.items[index], 'Upload', payload?.Upload);
                if(payload?.Upload){
                    Vue.set(this.items[index], 'upload_id', payload?.Upload?.id);
                }
            },

            status_rank(item) {
                return this.statuses.indexOf(item.status);
            },

            item_url(item) {
                let slug = this.$options.filters.slugify(item.Upload.name);
                return process.env.VUE_APP_API_PROTOCOL + '://' + process.env.VUE_APP_API_SUBDOMAIN + '.' + process.env.VUE_APP_DOMAIN + ':' + process.env.VUE_APP_API_PORT + '/v1/uploads/panda-docs/' + item.Upload.id + '/' + slug + '.pdf'
            },

            async fetchData(isRepeat = false) {
                const statusParam = this.isAutopayOrInsuranceProcessType ? { status: 'pending' } : {};      
                 await this.loadChecklistItems(statusParam);
                if (!isRepeat) await this.loadDocuments(); 
            },

            async loadChecklistItems(params){
                let { status = null } = params;
                let r = await api.get(this, `${api.LEASES + this.lease_id}/checklist${status ? '?status=pending' : ''}`);   
                this.setChecklistItems(r.items || []);  
            },

            setChecklistItems(items){
                // This is a temporary fix until the socket issue is resolved
                let documents_initiated = this.items.length && this.items.filter(si => si.status == 'initiated');
                if(documents_initiated && documents_initiated.length){
                    for(let i = 0; i < documents_initiated.length; i++){
                        let index = items.findIndex(p => p.id === documents_initiated[i].id);
                        if(index >= 0 && items[index].status == 'ready'){
                            items[index].status = 'initiated';
                        }
                    }
                }
                this.items = items;
            },

            async loadDocuments(){
                for(let i = 0; i < this.items.length; i++){
                    if(!this.items[i].document_id) continue;
                    if(this.items[i].upload_id) continue;
                    await api.get(this, api.LEASES + this.lease_id + '/documents/' + this.items[i].document_id + '/generate', { checklist_id: this.items[i].id });
                }
            },

            sign_documents() {
                let docs = [];
                if (!this.docs_to_sign || !this.docs_to_sign.length) this.showMessageNotification({ type: 'error', description: 'Please Choose documents to sign.' });

                if (!Array.isArray(this.docs_to_sign)) {
                    this.docs_to_sign = [this.docs_to_sign];

                }
                for (let i = 0; i < this.docs_to_sign.length; i++) {
                    let doc = this.signature_items.find(si => si.id === this.docs_to_sign[i]);
                    if (!doc.completed) {
                        docs.push(doc.Upload);
                    }
                }

                if (!docs.length) {
                    return;
                }

                this.uploads_to_sign = docs;
                this.sign_process = true;
            },

            async updateChecklist(data) {
                let checklist_id = data?.item && data?.item?.id ? data?.item?.id : data.id;
                await api.put(this, api.LEASES + this.lease_id + '/checklist-item/' + checklist_id, {
                    completed: data.completed || 0,
                    upload_id: data?.item?.upload_id || null,
                });

                let updateData =  {
                    checklist_id: checklist_id,
                    completed: data.completed || 0,
                    upload_id: data.item?.upload_id,
                    Upload: data.item?.Upload ? data.item?.Upload : null
                };
                this.updateChecklistItem(updateData);
            },

            async completeUpload(params){
                if(params.uploads && params.uploads.length) {
                    this.selected_upload.completed = true;
                    this.selected_upload.upload_id = params.uploads[0].id;
                    this.selected_upload.Upload = params.uploads[0];

                    await this.updateChecklist({
                         item: this.selected_upload,
                         completed: true,
                         lease_id: this.lease_id
                     }); 

                  this.upload_document = false;
                  this.selected_upload = {}; 
                }
            }, 

            async closeSignProcess(upload_id) {
                this.sign_process = false;
                this.signer_id = null;
                this.uploads_to_sign = [];
                this.active_item = {};
            },

            async print() {
                await api.get(this, api.LEASES + this.lease_id + '/documents/', this.signature_items[0].document_id + '/generate', {}, 'print_loading');
            },

            closeSendWindow() {
                this.send_docs = false;
                this.to_send = {};
            },

            send_documents(item, signer){
                this.send_docs = true;
                let phone =  signer.Contact && signer.Contact.Phones && signer.Contact.Phones.length ? signer.Contact.Phones.find(p => p.sms) : null;
                this.$set(signer, 'send_sms', !!phone);
                this.$set(signer, 'sms_number', phone? phone.phone : '');
                this.$set(signer, 'send_email', !!signer.Contact.email);
                this.$set(signer, 'email_address', signer.Contact.email);
                this.$set(signer, 'upload_id', item.Upload.id);
                this.$set(signer, 'lease_id', this.lease_id );
                this.$set(signer, 'item_id', item.id );
                this.$set(signer, 'property_id', this.property.id );
                this.to_send = signer;
            },

            upload(item) {
                this.selected_upload = JSON.parse(JSON.stringify(item));
                this.upload_document = true;
            },

            async remove(item) {
                let index = this.docs_to_sign.findIndex(d => d.id === item.id);
                if (index >= 0) {
                    this.docs_to_sign.splice(index, 1);
                }
                await this.regenerateItem(item);
            },

            async regenerateItem(data){
                let index = this.items.findIndex(item => item.id === data.id);
                await api.delete(this, api.LEASES + this.items[index].lease_id + '/checklist-item/' + this.items[index].id + '/upload');
                this.resetChecklistItemUpload(index);
                await this.generateDocument(index);
            },

            resetChecklistItemUpload(index) {
                if(this && this.items.length){
                    this.items[index].Upload = {};
                    this.items[index].completed = false;
                    this.items[index].upload_id = null;
                }
            },

            async generateDocument(index) {

                if(!this.items[index].document_id) return;
                if(this.items[index].upload_id) return;

                await api.get(this, api.LEASES + this.items[index].lease_id + '/documents/' + this.items[index].document_id + '/generate', { checklist_id: this.items[index].id });

                this.setChecklistItemStatus(index, 'loading');
            },

            setChecklistItemStatus(index, status){
                this.items[index].status = status;
            },

            email_copy(item) {
                this.email_doc_copy = true;
                this.file = item;
            },

            async download_panda_doc(item) {
                this.$http.get(
                    api.UPLOADS + 'panda-docs/' + item.Upload.id + '/download',
                    null,
                    { responseType: 'arraybuffer' }
                ).then(function (response) {
                    var blob = new Blob([response.body], { type: response.headers.get('content-type') });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = item.Upload.name;
                    link.click();
                });
            }
        },
        watch: {
            items: {
                handler(items) { 
                    let isCompleted = this.signature_items.every(c => c?.completed && c.document_id && (this.status_rank(c) !== 1 || this.status_rank(c) !== 2));
                    if(this.allowMoveInChecklist){ 
                        isCompleted = this.items.every(c => (c.document_id ? (c?.completed && this.status_rank(c) !== 1 && this.status_rank(c) !== 2) : c?.completed));
                    }
                    this.$emit('documentsSigned', isCompleted); 
                },
                deep: true
            }
        }
    }
</script>

<style scoped>

    .insurance-container{
        border: 1px solid #C4CDD5;
        background: white;
        padding: 30px;
        border-radius: 4px;
        margin-bottom: 30px;
    }
    .policy-container{
        padding-top: 0;
        padding-bottom: 0;
    }
    .form-label{
        background: #F4F6F8;
        border-radius: 4px;
    }
    .insurance-option-row{
        border-bottom: 1px solid #C4CDD5;
        padding: 10px 20px;
    }
    .insurance-section-label{
        padding: 30px;
        font-weight: bold;
    }
    .insurance-container{
        padding: 30px 50px;
    }

    .insurance-container .v-input--radio-group--column{
        margin: 0;
    }
    .insurance-container .insurance-row {
        margin-bottom: 10px;

    }
    .insurance-option-row.no-border{
        border-bottom: none;
    }

    .new-tenant-walkthrough-footer{
        /* padding: 3px 0 3px 0; */
        background: #FFFFFF;
        border-top: 1px solid #e2e2e2;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
        left: 0;
        right: 0;
    }
    .services-section{
        padding: 20px 24px 20px 24px
    }

    .policy-container  .v-input.v-input--selection-controls.v-input--checkbox {
        margin-top: 0;
    }

    .signatures .v-input--selection-controls .v-input__control{
        flex-grow: 1;
    }

    .send-block{
        margin-top: 10px;
    }
</style>